import React from "react";
import InputAdornment from '@mui/material/InputAdornment';
import { themeColor, textColor, lightGreen, buttonTextColor } from "../../Themes/Colors";
import TextField from '@mui/material/TextField';
import 'react-toastify/dist/ReactToastify.css';
import loginImg from "../../Assets/Images/loginImg.png";
import aptlogo from "../../Assets/Images/aptlogo.png"
import student from '../../Assets/Images/student.png'
import MarkunreadIcon from '@mui/icons-material/Markunread';
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import macbook from '../../Assets/Images/macbook.png'
import {
  IconButton, Typography,

} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { post } from "../../Services/api-services";
import { useRecoilState } from "recoil";
import { adminAuthState, currentRole } from "../../Services/RecoilService";
import { useNavigate } from "react-router-dom";
import { emailRegex, otpRegex, passwordRegex } from "../../ValidationsRegex";
import { showToast } from '../../store/Actions/Action';
import { useDispatch } from 'react-redux';

export default function AdminLogin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [adminLogin, setAdminLogin] = React.useState({ email: '', password: '' })
  const [authState, setAuthState] = useRecoilState(adminAuthState)
  const [Role, setRole] = useRecoilState(currentRole)
  const [otp, setOtp] = React.useState(false)



  const submitLogin = async () => {
    try {

      const data = {
        email: adminLogin.email,
        password: adminLogin.password
      }
      const res = await post('Admins/login', data)

      if (res?.statusCode === 200) {

        setAuthState(res?.data)
        setRole('admin');
        const _snackbar = {
          // message: res?.data?.message,
          message: 'Login Succesfull',
          open: true,
          duration: 3000,
          type: 'success'
        };

        dispatch(showToast(_snackbar))
        const adminId = res?.data?.userId
        navigate('/admin/home/dashboard', { state: { adminId } })

      }
      else {
        console.log(res);
        const _snackbar = {
          message: res?.message,
          open: true,
          duration: 3000,
          type: 'error'
        };

        dispatch(showToast(_snackbar))

      }
    } catch (e) {
      console.log(`ERROR WHILE LOGIN  --- ${e}`)
    }
  }

  React.useEffect(() => {
    if (authState?.id) {
      navigate('/admin/home')
    }
  }, [])

  // FORGOT PASSWORD 

  const forgotPassword = async () => {
    try {

      const res = await post('Admins/otpSEND', { email: adminLogin?.email })
      if (res?.data?.status === "otp sent") {
        setOtp(true)
        const _snackbar = {
          // message: res?.data?.message,
          message: 'OTP sent ',
          open: true,
          duration: 3000,
          type: 'success'
        };
        dispatch(showToast(_snackbar))
        const data = {
          email: adminLogin?.email,
          // otp: formOtp
        }
        navigate('/admin/forgot-password', { state: data });
      } else {
        const _snackbar = {
          message: 'Please Enter Valid Email',
          open: true,
          duration: 3000,
          type: 'error'
        };
        dispatch(showToast(_snackbar))
      }
    } catch (e) {
      console.log(e);
    }
  }


  return (
    <div className="flex  gap-10">

      <div className="h-screen hidden w-full flex-1 xl:flex">
        <div className="hidden h-full xl:flex " >
          <img src={loginImg} className="w-[70%] mt-8" />
          <div className="absolute mt-8 left-20">
            <img src={aptlogo} alt="aptlogo" width={126.93} height={95.2} />
            <p className="text-[#686464] font-normal">Student doubt panel</p>
          </div>
          <img src={macbook} alt="macbook" className="w-[764.51px] absolute top-1/3 left-24" />
        </div>

      </div>


      <div className="flex-1 h-screen items-center justify-center flex flex-col left-0 right-0 xl:w-full">
        {/* <div className="hidden xl:flex flex-col absolute left-6 top-6 "> */}
        <div className="hidden ">
          <img src={aptlogo} alt="aptlogo" className="w-32" />
          <p className="text-[#686464] font-normal">Student doubt panel</p>
        </div>

        {/* <div className="items-center justify-center flex flex-col gap-8 xl:my-[40%]"> */}
        <div className="items-center justify-center flex flex-col gap-8">
          <img src={student} alt="student" className="w-[4rem]" />

          <Typography className="text-[#555555] font-extrabold" variant="h6" sx={{ fontWeight: 800 }}>WELCOME</Typography>

          <div className="flex flex-col w-full">
            <TextField
              // id="input-with-icon-textfield"

              label="Email Id"
              onChange={(e) => { setAdminLogin({ ...adminLogin, email: e.target.value }) }}
              error={
                adminLogin?.email?.length > 0 && !emailRegex?.test(adminLogin.email)
              }
              helperText={
                adminLogin?.email?.length > 0 && !emailRegex?.test(adminLogin.email)
                  ? "enter valid email"
                  : ""
              }
              InputLabelProps={{
                sx: {
                  // set the color of the label when not shrinked
                  color: "#9D9D9D",
                  fontSize: 20,
                  fontWeight: 400,
                }
              }}
              InputProps={{

                startAdornment: (
                  <InputAdornment position="start">
                    <MarkunreadIcon sx={{ color: '#D2D2d2' }} />
                  </InputAdornment>
                ),
              }}
              sx={{
                '& label.Mui-focused': {
                  color: "#9D9D9D",
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#A9CEC6',

                },
                '& .MuiInput-underline:before': {
                  borderBottomColor: '#A9CEC6',

                },


              }}

              variant="standard"


            />
          </div>
          <div className="flex flex-col gap-4 w-full">
            <TextField

              label="Password"
              onChange={(e) => { setAdminLogin({ ...adminLogin, password: e.target.value }) }}
              onKeyDown={e => {
                if (e.key === "Enter") {

                  e.stopPropagation();
                  submitLogin()
                }
              }}
              error={
                adminLogin?.password?.length > 0 && !passwordRegex?.test(adminLogin.password)
              }
              helperText={
                adminLogin?.password?.length > 0 && !passwordRegex?.test(adminLogin.password)
                  ? "enter valid password"
                  : ""
              }
              type={showPassword ? "text" : "password"}
              InputLabelProps={{
                sx: {
                  // set the color of the label when not shrinked
                  color: "#9D9D9D",
                  fontSize: 20,
                  fontWeight: 400,
                  paddingBottom: 20,


                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon sx={{ color: '#D2D2d2' }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">

                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <Visibility sx={{ color: '#D2D2d2' }} />
                      ) : (
                        <VisibilityOff sx={{ color: '#D2D2d2' }} />
                      )}
                    </IconButton>

                  </InputAdornment>

                ),
              }}
              sx={{
                '& label.Mui-focused': {
                  color: "#9D9D9D",
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#A9CEC6',
                },
                '& .MuiInput-underline:before': {
                  borderBottomColor: '#A9CEC6',
                },


              }}

              variant="standard"

            />
            <Button size="small"
              onClick={forgotPassword}
              sx={{
                color: lightGreen, textDecoration: 'underline', textJustify: 'end',
                textTransform: 'none', justifyContent: "flex-end",
                width: "fit-content"
              }}
            >
              Forgot Password?
            </Button>
          </div>
          <button
            disabled={
              !(adminLogin.email && adminLogin.password && emailRegex?.test(adminLogin.email) && passwordRegex?.test(adminLogin.password))
            }
            className="rounded-full text-[#FFFFFF] bg-[#98D2C5]  w-full py-2 disabled:opacity-50"
            onClick={submitLogin}

          >LOGIN</button>
        </div>

      </div>

    </div>
  );
}
