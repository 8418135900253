// TemporaryDrawer.js
import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import { CrossIcon, DateArrow, RightArrow } from './Icons';
import TextField from '@mui/material/TextField';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import IconButton from "@mui/material/IconButton";
import { get, post } from '../../Services/api-services';
import { useTheme } from '@mui/system';
// import { CalenderIcon } from '../StudentComponents/Icons'
import { CalenderIconSvg } from '../StudentComponents/Icons'
import { useRecoilState } from 'recoil';
import { adminAuthState, currentRole, facultyAuthState, studentAuthState } from '../../Services/RecoilService';



const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export default function TemporaryDrawer(props) {

    const theme = useTheme();
    const [subjectData, setSubjectData] = React.useState([]);
    const [selectedSubjectIds, setSelectedSubjectIds] = React.useState([]);
    const [selectedFacultyIds, setSelectedFacultyIds] = React.useState([]);
    const startDateInputRef = React.useRef(null);
    const endDateInputRef = React.useRef(null);
    const [startDt, setStartDt] = React.useState(true)
    const [authState, setAuthState] = useRecoilState(studentAuthState)
    const [_facultyAuthState, set_FacultyAuthState] = useRecoilState(facultyAuthState)
    const [_adminAuthState, set_AdminAuthState] = useRecoilState(adminAuthState)
    const [role, setRole] = useRecoilState(currentRole)
    const [courses, setCourses] = React.useState([])


    const handleChangeData = async (type, value) => {

        if (type === 'selectOpen' || type === 'selectClose') {
            props.setFilterData({
                ...props.filterData,
                [type]: !props.filterData[type]
            });
            return; // Skip the rest of the function for 'selectOpen'
        }

        const currentData = props?.filterData?.[type] || [];
        const updatedData = currentData.includes(value)
            ? currentData.filter(id => id !== value)
            : [...currentData, value];

        props?.setFilterData({
            ...props?.filterData,
            [type]: updatedData,
        });


        if (type === 'selectedCourses') {
            try {

                if (role === 'student') {
                    const data = {
                        studentId: authState?.userId,
                        courseId: updatedData
                    }
                    const res = await post('cases/getStudentSubject', data)
                    if (res?.statusCode === 200) {
                        setSubjectData(res?.data)

                    } else {

                    }
                } else if (role === 'faculty') {
                    const data = {
                        facultyId: _facultyAuthState?.userId,
                        courseId: updatedData
                    }
                    const res = await post('faculties/getSubjectsOfFaculty', data)
                    if (res?.statusCode === 200) {
                        setSubjectData(res?.data)

                    } else {

                    }
                } else {
                    const data = {
                        courses: updatedData
                    }
                    const res = await post('subjects/getSubjectByCourse', data)
                    if (res?.statusCode === 200) {
                        setSubjectData(res?.data?.subject)

                    } else {

                    }
                }

            } catch (e) {
                console.log(e);
            }
        }

    }

    const list = () => (
        <Box
            sx={{
                width: 320,
                padding: '1vmax',
                display: 'flex',
                flexDirection: 'column',
                height: "100%"
            }}
            role="presentation"
        // onClick={() => props.onClose(false)}
        // onKeyDown={() => props.onClose(false)}
        >

            <div className='w-full flex items-center justify-between  px-3 py-3'>
                <h6 style={{ color: theme.palette.headings.main }} className='text-sm tracking-wider'>FILTERS</h6>
                <IconButton onClick={() => { props.onClose(); props?.removeAll() }} className='cursor-pointer'>
                    <CrossIcon />
                </IconButton>
            </div>
            <div className='flex-1 overflow-y-auto  '>
                <div className='opacity-0 w-0 h-0'>
                    <TextField
                        // variant='standard'
                        type='date'
                        id="outlined-basic"
                        size='small'
                        inputRef={startDateInputRef}
                        sx={{
                            "& fieldset": { border: 'none' },
                        }}
                        inputProps={{
                            style: {
                                color: theme.palette.headings.main,
                                fontWeight: '100' // Specify the color you want
                            },
                        }}

                        onChange={(e) => {
                            props.setFilterData({
                                ...props.filterData,
                                selectStartDate: e.target.value
                            });
                        }}
                    />

                </div>
                <div className='opacity-0 w-0 h-0'>
                    <TextField
                        // variant='standard'
                        type='date'
                        id="outlined-basic"
                        sx={{
                            "& fieldset": { border: 'none' },
                        }}
                        size='small'
                        inputRef={endDateInputRef}
                        inputProps={{
                            style: {
                                color: theme.palette.headings.main,
                                fontWeight: '100' // Specify the color you want
                            },
                        }}
                        onChange={(e) => {
                            props.setFilterData({
                                ...props.filterData,
                                selectEndDate: e.target.value
                            });
                        }}
                    />
                </div>
                <div className='w-full flex flex-col items-start gap-4 py-4 pb-14'>
                    <div className='w-full flex items-center justify-between gap-3  px-4 rounded border border-[#D3D3D3]'>

                        <div
                            onClick={() => {
                                startDateInputRef.current.showPicker()
                                setStartDt(true)
                            }}
                            className='flex items-center cursor-pointer h-10 relative gap-10'
                        >
                            {props?.filterData?.selectStartDate ? (
                                <h6
                                    style={{ color: theme.palette.headings.main }}
                                >
                                    {props?.filterData?.selectStartDate}
                                </h6>
                            ) : (

                                <div className='flex items-center cursor-pointer h-10 relative gap-10'>
                                    {startDt && (
                                        <div className='w-full h-[2px] rounded bg-[#98D2C5] absolute left-0 bottom-0'></div>
                                    )}
                                    <h6
                                        style={{ color: theme.palette.headings.main }}
                                        className='text-sm capitalize lg:text-sm'
                                    >
                                        start
                                    </h6>
                                    {startDt && (
                                        <div>
                                            <CalenderIconSvg Color={theme.palette.headings.main} />
                                        </div>
                                    )}
                                </div>
                            )}


                        </div>

                        <DateArrow Color={theme.palette.headings.main} />

                        <div
                            onClick={() => {
                                endDateInputRef.current.showPicker();
                                setStartDt(false)
                            }}
                            className='flex relative h-10 cursor-pointer items-center gap-10'
                        >

                            {props?.filterData?.selectEndDate ? (
                                <h6
                                    style={{ color: theme.palette.headings.main }}
                                >
                                    {props?.filterData?.selectEndDate}
                                </h6>
                            ) : (
                                <div className='flex items-center cursor-pointer h-10 relative gap-10'>
                                    {!startDt && (
                                        <div className='w-full h-[2px]  rounded bg-[#98D2C5] absolute left-0 bottom-0'></div>
                                    )}
                                    <h6
                                        style={{ color: theme.palette.headings.main }}
                                        className='text-sm capitalize lg:text-sm'
                                    >
                                        end
                                    </h6>
                                    {!startDt && (
                                        <div>
                                            <CalenderIconSvg Color={theme.palette.headings.main} />
                                        </div>
                                    )}
                                </div>
                            )}



                        </div>

                    </div>

                    {/* COURSES */}

                    <div className='w-full'>
                        <Accordion
                            sx={{
                                border: 'none', // Remove the border
                                borderColor: 'red',
                                boxShadow: 'none'
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.headings.main }} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                sx={{
                                    backgroundColor: theme.palette.bg.secondary,
                                    color: theme.palette.headings.main
                                }}
                            >
                                <Typography>Course</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ backgroundColor: theme.palette.bg.secondary, }} className='h-36 overflow-y-auto'>
                                {props?.allCourses.map((course) => {
                                    return (

                                        <div key={course?.id} className='flex items-center gap-2 cursor-pointer'
                                            onClick={() => {
                                                handleChangeData('selectedCourses', course?.id)
                                            }}
                                        >
                                            <Checkbox
                                                checked={props?.filterData?.selectedCourses?.includes(course?.id)}
                                                style={{
                                                    color: "#98D2C5",
                                                }} />

                                            <h1 style={{ color: theme.palette.headings.main }} className='text-[#616161] text-sm font-normal tracking-wider'>
                                                {props?.role === 'student' ? (
                                                    <h1>
                                                        {course?.name}
                                                    </h1>
                                                ) : (
                                                    <h1>
                                                        {course?.name}
                                                        ({course?.country?.currencyName})
                                                    </h1>
                                                )}

                                            </h1>
                                        </div>
                                    )
                                })
                                }

                            </AccordionDetails>
                        </Accordion>
                    </div>

                    {/* SUBJECTS// */}
                    <div className='w-full'>
                        <Accordion
                            sx={{
                                boxShadow: 'none'
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.headings.main }} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                sx={{
                                    backgroundColor: theme.palette.bg.secondary,
                                    color: theme.palette.headings.main
                                }}
                            >
                                <Typography>Subjects</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ backgroundColor: theme.palette.bg.secondary, }} className='h-36 overflow-y-auto'>
                                {props?.filterData?.selectedCourses?.length === 0 && subjectData?.length === 0 && (
                                    <div className='flex w-full h-full items-center justify-center'>
                                        <h6 style={{ color: theme.palette.headings.main }} className='text-sm'>Please Select Course First</h6>
                                    </div>
                                )}

                                {subjectData.map((subject) => {
                                    return (
                                        <div className='flex items-center gap-2 cursor-pointer' onClick={() => handleChangeData('selectedSubjects', subject?.id)} >
                                            <Checkbox
                                                checked={props?.filterData?.selectedSubjects?.includes(subject?.id)}
                                                style={{
                                                    color: "#98D2C5",
                                                }} />

                                            <h1 style={{ color: theme.palette.headings.main }} className=' text-sm font-normal tracking-wider'>{subject?.name}</h1>
                                        </div>

                                    )

                                })
                                }
                            </AccordionDetails>
                        </Accordion>
                    </div>

                    {/* // shared faulty*/}
                    {props?.role === 'student' || props?.role === 'admin' && <div className='w-full'>
                        <Accordion sx={{ boxShadow: "none", '&:after': { height: '0px' } }} elevation={0}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.headings.main }} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                sx={{
                                    backgroundColor: theme.palette.bg.secondary,
                                    color: theme.palette.headings.main
                                }}
                            >
                                <Typography>Faculty</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ backgroundColor: theme.palette.bg.secondary, }} className='h-36 overflow-y-auto'>

                                {props?.allFaculties?.map((faculty) => {
                                    return (
                                        <div className='flex items-center gap-2 cursor-pointer'
                                            onClick={() => handleChangeData('selectedFaculty', faculty?.id)}
                                        >
                                            <Checkbox
                                                checked={props?.filterData?.selectedFaculty?.includes(faculty?.id)}
                                                style={{
                                                    color: "#98D2C5",
                                                }}
                                            />
                                            <h1 style={{ color: theme.palette.headings.main }} className=' text-sm font-normal tracking-wider'>{faculty?.name}</h1>
                                        </div>
                                    )
                                })
                                }

                            </AccordionDetails>
                        </Accordion>
                    </div>}
                    <div className='w-full'>
                        <Accordion sx={{ boxShadow: "none", '&:before': { height: '0px' } }} elevation={0}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.headings.main }} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                sx={{
                                    backgroundColor: theme.palette.bg.secondary,
                                    color: theme.palette.headings.main
                                }}
                            >
                                <Typography>Status</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ backgroundColor: theme.palette.bg.secondary, }}>

                                <div className='flex items-center gap-2 cursor-pointer' onClick={() => { props?.setSelectOpen(!props?.selectOpen); }}>
                                    <Checkbox
                                        checked={props.filterData?.selectOpen === true}

                                        style={{
                                            color: "#98D2C5",
                                        }}
                                        onClick={() => handleChangeData('selectOpen', !props.filterData?.selectOpen)}
                                    />
                                    <h1 style={{ color: theme.palette.headings.main }} className=' text-sm font-normal tracking-wider'
                                    >
                                        Open
                                    </h1>
                                </div>
                                <div className='flex items-center gap-2 cursor-pointer' onClick={() => { props.setSelectClose(!props.selectClose); }}>
                                    <Checkbox
                                        checked={props.filterData?.selectClose === true}
                                        style={{
                                            color: "#98D2C5",
                                        }}
                                        onClick={() => handleChangeData('selectClose', !props.filterData?.selectClose)}
                                    />
                                    <h1 style={{ color: theme.palette.headings.main }} className='text-sm font-normal tracking-wider'>
                                        Closed
                                    </h1>
                                </div>

                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: theme.palette.bg.secondary }} className='flex flex-row items-center justify-center gap-3 h-20   absolute  left-0 right-0 bottom-1'>
                <button className='  items-center  py-2 px-5 cursor-pointer  
                   text-[#98D2C5] align-bottom border rounded-sm border-[#7BB5A8] disabled:opacity-50'
                    onClick={() => {
                        props?.removeAll();
                        setSubjectData([])
                    }}
                >
                    Reset

                </button>
                <button className=' items-center  py-2 px-5  cursor-pointer  
           text-[#98D2C5] align-bottom border rounded-sm border-[#7BB5A8] disabled:opacity-50'
                    onClick={() => { props?.getAllDoubts(); props.onClose() }}

                >
                    Apply
                </button>
            </div>

        </Box>
    );

    return (
        <Drawer
            anchor="right"
            open={props.open}
            onClose={() => props.onClose(false)}
            BackdropProps={{ invisible: true }}
            sx={{
                '&.css-1160xiw-MuiPaper-root-MuiDrawer-paper': {
                    backgroundColor: theme.palette.bg.secondary
                },
                '& .MuiDrawer-paper': {
                    backgroundColor: theme.palette.bg.secondary
                    // boxShadow
                },
                // boxShadow:'none'
            }}

        >
            {list()}
        </Drawer>
    );
}
