import { createSlice } from "@reduxjs/toolkit";

// Reducer.js
const initialState = {
    currentTheme: 'light',
    snackbar: '',
}

export const Reducer = createSlice({
    name: 'home',
    initialState,
    reducers: {
        getTheme: (state, action) => {
            state.currentTheme = action.payload
        },
        getStatus: (state, action) => {
            state.snackbar = action.payload
        },
        updateSnackbar: (state, action) => {
            state.snackbar = action.payload
        },
    },
})


export const { getTheme, updateSnackbar, getStatus } = Reducer.actions

export default Reducer.reducer  