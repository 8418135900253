
import { BrowserRouter } from 'react-router-dom';
// import './App.css';

import { RecoilRoot, useRecoilState } from 'recoil';
import { ThemeProvider } from '@emotion/react';
import MainRoutes from '../../Routes/MainRoutes';
import StudentRoutes from '../../Routes/StudentRoutes';
import { currentTheme } from '../../Services/RecoilService';
import { darkTheme, lightTheme } from '../../Themes/Theme';
import { useSelector } from 'react-redux';

function ThemeComponent() {
    const [Theme, setTheme] = useRecoilState(currentTheme)

    return (
        <>
            <ThemeProvider theme={Theme === 'dark' ? darkTheme : lightTheme}>
                <BrowserRouter>
                    <div className="h-full flex flex-col ">
                        {/* <LoginRoutes /> */}
                        <MainRoutes />
                    </div>
                    <StudentRoutes />
                </BrowserRouter>
            </ThemeProvider >


        </>

    );
}

export default ThemeComponent;
