import React from 'react'
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/system';
import InputAdornment from '@mui/material/InputAdornment';
import { AddDoubtIcon, FilledFilterIcon, FilterIcon, SearchIcon } from './Icons';
import { CameraIcon, LightModeIcon, PhoneIcon } from '../StudentComponents/Icons';
import Badge from '@mui/material/Badge';
import SwitchButton from '../StudentComponents/SwitchButton';
import { useNavigate } from 'react-router-dom';
import { baseUrl, post } from '../../Services/api-services';
import { useRecoilState } from 'recoil';
import { currentRole, facultyAuthState, studentAuthState } from '../../Services/RecoilService';


const HomePageHeader = (props) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const profilePic = React.useRef(null);
    const [role, setRole] = useRecoilState(currentRole)
    const [authState, setAuthState] = useRecoilState(studentAuthState)
    const [_facultyAuthState, set_FacultyAuthState] = useRecoilState(facultyAuthState)


    return (
        <>
            <div className='w-full'>
                {props?.role === 'admin' ? (
                    <></>
                ) : (
                    <div className='w-full'>
                        <div className='hidden lg:flex  items-center gap-5 pt-8 py-2 px-3'>
                            <Badge
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                onClick={() => profilePic.current.click()} className='cursor-pointer'
                                badgeContent={
                                    <CameraIcon />
                                }
                            >
                                <input
                                    type="file"
                                    className="hidden"

                                    // onChange={(e) => {
                                    //   // setStudentProfile(e.target.files[0]);
                                    //   uploadImage(e)
                                    // }}

                                    onChange={props?.onUploadProfile}
                                    ref={profilePic}
                                />
                                <Avatar sx={{ width: 60, height: 60 }} alt={props?.userDets?.name} src={`${baseUrl}uploads/staff/download/${props?.userDets?.image}`} />
                            </Badge>
                            <div className='flex flex-col items-start '>
                                <h3 className='text-lg capitalize font-medium text-[#98D2C5] tracking-wide'>{props?.userDets?.name}</h3>
                                <div className='flex flex-col text-sm items-center '>
                                    <div className='flex text-sm items-center gap-2'>
                                        <PhoneIcon color={theme.palette.Text.main} />
                                        <h6 style={{ color: theme.palette.Text.main }} className='font-normal text-xs'>+91 {props?.userDets?.mobile}</h6>
                                    </div>
                                    <div className='flex items-center gap-2'>
                                        <LightModeIcon />
                                        <small style={{ color: theme.palette.Text.main }} className='font-normal text-xs'>Light mode</small>
                                        <SwitchButton />
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div >
                )}
                <div className='w-full flex items-center justify-between px-3 py-5 lg:py-4'>
                    <h6 style={{ color: theme.palette.headings.main }} className='flex text-xl items-center justify-between'>Doubts</h6>

                    <div className='flex items-center gap-2 ' onClick={() => navigate(`/${props?.role}/profile`)}>
                        {props?.role != 'admin' && (
                            <div className='block lg:hidden'>
                                <Avatar sx={{ width: 40, height: 40 }} alt={props?.userDets?.name} src={`${baseUrl}uploads/staff/download/${props?.userDets?.image}`} />
                            </div>
                        )}
                    </div>
                </div>
                <div className='w-full px-3 gap-2 flex items-center justify-center'>
                    <div style={{ backgroundColor: theme.palette.searchBg.main, border: `1px solid ${theme.palette.searchBorder.main}` }} className='w-full  rounded'>
                        <TextField
                            sx={{
                                '& .MuiInputBase-root': {
                                    color: theme.palette.inputText.main,
                                    fontWeight: '500',
                                    padding: '2px 10px',
                                    [theme.breakpoints.down('sm')]: {
                                        fontSize: '1.5vmax', // Font size for large screens
                                        padding: '3px 10px',
                                    },
                                    [theme.breakpoints.up('lg')]: {
                                        fontSize: '.8vmax', // Font size for large screens
                                        padding: '2px 10px',
                                    },
                                    minHeight: '5vh'
                                },
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(e) => props?.setFilterData({
                                ...props?.filterData,
                                text: e.target.value
                            })}

                            id="outlined-basic"
                            size='small'
                            placeholder="Search"
                            variant="outlined"
                            fullWidth
                        />
                    </div>
                    <div className='flex items-center gap-2 relative cursor-pointer'>
                        {
                            props?.isFilter === true ? (
                                <div className='relative' onClick={props.onFilterOpen}>
                                    {/* <div className='w-7 h-7 mr-3 rounded-full absolute top-[-35%] right-[-40%] bg-[#98D2C5] border border-[#FFFFFF]  flex items-center justify-center'>
                                        <h6 className='text-sm text-white lg:text-xs'>{props?.length}</h6>
                                    </div> */}
                                    <FilledFilterIcon icon={theme.palette.IconColor.secondary} iconbg={theme.palette.IconBg.secondary} stroke={theme.palette.IconStroke.secondary} />
                                    {/* <FilledFilterIcon icon={theme.palette.IconColor.secondary} iconbg={theme.palette.IconBg.secondary} stroke={theme.palette.IconStroke.secondary} /> */}
                                </div>
                            ) : (
                                <div onClick={props.onFilterOpen}>
                                    <FilterIcon icon={theme.palette.IconColor.secondary} iconbg={theme.palette.IconBg.secondary} stroke={theme.palette.IconStroke.secondary} />
                                </div>
                            )
                        }

                        {props?.role === 'student' && (
                            <div onClick={props.onAskDoubt}>
                                <AddDoubtIcon icon={theme.palette.IconColor.main} iconbg={theme.palette.IconBg.main} stroke={theme.palette.IconStroke.main} />
                            </div>
                        )}

                    </div>
                </div>
            </div >
        </>
    )
}

export default HomePageHeader
