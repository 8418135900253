// RECOILING
import { atom, } from 'recoil'
import { recoilPersist } from 'recoil-persist'
const { persistAtom } = recoilPersist({
    key: 'studentData',
    storage: localStorage
})

export const adminAuthState = atom({
    key: 'admin',
    default: {
        token: null,
        userId: ''
    },
    effects_UNSTABLE: [persistAtom],
})

export const studentAuthState = atom({
    key: 'student',
    default: {
        token: null,
        userId: '',
        currentDoubtId:''
    },
    effects_UNSTABLE: [persistAtom],
})

export const currentTheme = atom({
    key: 'theme',
    default: {
        theme:'light'
    },
    effects_UNSTABLE: [persistAtom],
})

export const currentRole = atom({
    key: 'role',
    default: {
        role:''
    },
    effects_UNSTABLE: [persistAtom],
})

export const currentScreen = atom({
    key: 'screen',
    default:'main',
    effects_UNSTABLE: [persistAtom],
})

export const currentDoubtId = atom({
    key: 'currentDoubt',
    default:'',
    effects_UNSTABLE: [persistAtom],
})

export const facultyAuthState = atom({
    key: 'faculty',
    default: {
        token: null,
        userId: '',
        currentDoubtId:''
    },
    effects_UNSTABLE: [persistAtom],
})

// { "id": "token",
//   "ttl": 1209600,
//   "created": "2022-12-06T09:26:43.189Z",
//   "userId": "637f5dc9506f6345649fd0a6"}
// {
//   "error": {
//     "statusCode": 401,
//     "name": "Error",
//     "message": "login failed",p0
//     "code": "LOGIN_FAILED",
    
//   }
// }
