import React from 'react'
import { Button, styled } from "@mui/material"
import { useSelector, useDispatch } from 'react-redux';
import { asyncUpdateTheme } from './store/Actions/Action';



const TrialButton = styled(Button)(({ theme }) => ({
  color: 'white',
  // backgroundColor: '#ff0000',
  // '&:hover': {
  //     backgroundColor: '#D74040',
  // },
  minWidth: '24px',
  height: '50',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 16,
  fontWeight: 'bold',
  letterSpacing: '4px',
  borderRadius: 2,
  padding: '7px 14px',
  boxShadow: 'none',
  backgroundColor: theme.palette.tealGreen.main,
  '&:hover': {
    backgroundColor: theme.palette.tealGreen.secondary,
  },
  // background: 'sell'

}))

const Trial = () => {

  const dispatch = useDispatch();
  const { currentTheme } = useSelector((state) => state.homeOption);

  const handleMode = (data) => {
    dispatch(asyncUpdateTheme(data))
  }

  return (
    <div className='w-full h-screen flex items-center gap-2 justify-center'>
      <TrialButton variant="contained" color="gray">
        Theme Trial
      </TrialButton>
      <Button variant="contained" onClick={() => handleMode('dark')} >Dark</Button>
      <Button variant="contained" onClick={() => handleMode('light')}  >Light</Button>
    </div>
  )

}

export default Trial
