import React from 'react'
import { Typography } from '@mui/material'

import Search from '../../Components/Search'
import { baseUrl, get, patch } from '../../Services/api-services'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import { Switch, Avatar, } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { debounce, filter } from "lodash"
import StudentMap from '../../Dialogs/StudentMap';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';





export default function Student() {
  const theme = useTheme()
  const [page, setPage] = React.useState(0);
  const [student, setStudent] = React.useState([])
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setLoading] = React.useState(true);
  const [searchData, setSearchData] = React.useState('')
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const dataGridRef = React.useRef();

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getStudent = async (data) => {

    try {
      // const res = await get('cases/casesList')
      const searchData = data === undefined ? null : "\"" + data + "\"";
      const res = await get(`cases/casesList?data={"text":${searchData}}`)
      console.log(res);
      setLoading(true)
      if (res?.statusCode === 200) {
        setStudent(res?.data?.data)
      }

      setLoading(false)
    } catch (e) {
      console.log(e);
    }

  }


  React.useEffect(() => {
    xfatchFilterStudent(searchData);
  }, [searchData])

  const xfatchFilterStudent = React.useCallback(debounce((text) => getStudent(text), 300), []);



  React.useEffect(() => {
    getStudent()
  }, [])


  const navigate = useNavigate()

  // STUDENTS COLUMNS 

  const columns = [

    {
      field: 'name',
      headerName: 'Students',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return <div className='flex gap-2  items-center'>

          <div >
            <Avatar src={`${baseUrl}uploads/staff/download/${params?.row?.image}`} alt={params.row.name[0]?.toUpperCase()} />
          </div>
          <div>
            <p>{params?.row?.name}</p>
          </div>
        </div>
      }

    },

    {
      field: 'subscribedCourses',
      headerName: 'Courses',
      minWidth: 150,
      flex: 1,
      // align:'center',

      renderCell: (params) => {

        const coursesArr1 = []
        const courseArr = [...params?.row?.subscribedCourses]

        courseArr?.forEach(function (value) {
          if (value) {
            coursesArr1.push(value);
          }
        });

        const count = coursesArr1.length - 1
        const show = []
        coursesArr1.slice(1).forEach(function (value) {
          show.push(value, ",")
        })


        return (
          <div className='flex items-center gap-1' >
            {show?.length > 1 ? (
              <Tooltip title={show}>
                <div className='flex items-center gap-2'>
                  <h1 >{coursesArr1[0]?.course}</h1>
                  {count ? <span className='text-[#98D2C5]'>+{count}</span> : null}
                </div>
              </Tooltip>
            ) : (
              <div className='flex items-center gap-2'>
                <h1 >{coursesArr1[0]?.course}</h1>
                {count ? <span className='text-[#98D2C5]'>+{count}</span> : null}
              </div>
            )}
          </div>

        )
      },
    },


    {
      field: ' totalDoubt',
      headerName: 'Doubts',
      minWidth: 150,
      // align:'right',
      renderCell: (params) => {

        const od = params.row.totalDoubts - params.row.closedDoubt
        return <div className='flex items-center font-bold'>
          <p className='text-[#FF3333]'>{od}|</p> <p className='text-[#00B05B]'>{params.row.closedDoubt}</p>
        </div>
      },

    },

  ];

  // DATAGRID STYLES 

  const datagridStylesCss = {

    '& .css-rtrcn9-MuiTablePagination-root': {
      color: theme.palette.headings.main
    },
    '& .css-zylse7-MuiButtonBase-root-MuiIconButton-root.Mui-disabled': {
      color: theme.palette.disabled.main
    },
    '& .css-8nphli': {
      color: theme.palette.headings.main
    },
    '& .css-1deacqj.Mui-disabled': {
      color: theme.palette.disabled.main
    },
    '& .css-1utq5rl': {
      color: theme.palette.headings.main
    },
    '& .css-1j7qk7u': {
      color: theme.palette.headings.table,
    },

    border: 0,
    backgroundColor: theme.palette.bg.adminApp,
    border: `1px solid ${theme.palette.searchBorder.main}`,


    '& .MuiDataGrid-footerContainer': {
      borderTop: `1px solid  ${theme.palette.searchBorder.main}`,
      color: theme.palette.headings.table,
    },

    '& .css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root': {
      color: theme.palette.headings.table,
    },

    '&>.MuiDataGrid-main': {
      '&>.MuiDataGrid-columnHeaders': {

        // backgroundColor: '#55555512',
        backgroundColor: theme.palette.bg.tableHeader,
        color: theme.palette.headings.table,
        fontWeight: '600',
        fontSize: "14px",
        borderBottom: `1px solid ${theme.palette.searchBorder.main}`,
        justifyContent: 'space-between',
      },
      '&>.MuiDataGrid-columnHeaderTitleContainer': {
        border: 'none',
      },

      '& div div div div >.MuiDataGrid-cell': {
        border: 'none',
        fontSize: 14,
        // flexGrow: 1,
      },

      '& .MuiDataGrid-row': {
        cursor: 'pointer',
        marginTop: 1,
        borderRadius: 2,
        color: theme.palette.headings.table
      },

    }

  }


  return (
    <div style={{ backgroundColor: theme.palette.bg.adminApp }} className='flex-1 p-3 flex flex-col gap-4' >
      <div className='flex gap-3 justify-between flex-col lg:flex-row'>
        <div>
          <h4
            style={{ color: theme.palette.headings.mode }}
            className='text-lg lg:text-lg font-semibold tracking-wider'
          >
            STUDENT
          </h4>
        </div>

        <div className='flex gap-4'>
          {/* <Search getStudent={getStudent} /> */}
          <TextField
            value={searchData}
            onChange={(e) => {
              setSearchData(e?.target?.value)
            }}
            sx={{
              '& .MuiInputBase-root': {
                color: theme.palette.headings.main,
                // style={{ backgroundColor: theme.palette.searchBg.main, border: `1px solid ${theme.palette.searchBorder.main}` }}
                fontWeight: '500',
                borderColor: 'red',
                border: `1px solid ${theme.palette.searchBorder.main}`,
                padding: '2px 10px',
                [theme.breakpoints.down('sm')]: {
                  fontSize: '1.5vmax', // Font size for large screens
                  padding: '3px 10px',
                },
                [theme.breakpoints.up('lg')]: {
                  fontSize: '.9vmax', // Font size for large screens
                  padding: '2px 10px',
                },
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: theme.palette.headings.main }} />
                </InputAdornment>
              ),
            }}
            id="outlined-basic"
            size='small'
            placeholder="Search"
            variant="outlined"
            fullWidth
          />


          <StudentMap />

        </div>
      </div>

      {/* Table container */}

      <div
        className='flex-1  max-w-[92vw] lg:max-w-full'
        ref={dataGridRef}
      >

        <DataGrid
          loading={loading}
          getRowId={(row) => row.email}
          onRowClick={(params) => {

            const data = params.row._id
            navigate("studentDetail", { state: { data } })

          }}
          rows={student}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}

          disableColumnMenu
          disableColumnSelector

          disableColumnResize='true'

          pageSizeOptions={[5, 10, 20, 30, 50, 100]}

          sx={datagridStylesCss}

        />
      </div>

    </div>
  )
}
