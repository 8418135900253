import React from 'react'
import { styled } from '@mui/material/styles';
import { Switch, } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Typography, Avatar } from '@mui/material';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import EditFaculty from '../../Dialogs/EditFaculty';
import facultyImg from '../../Assets/Images/faculty.png'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { post, get, patch, baseUrl } from '../../Services/api-services';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TableRow from '@mui/material/TableRow';
import { DataGrid } from '@mui/x-data-grid';
import { useLocation, useNavigate } from 'react-router-dom';
import { Details } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import moment from 'moment/moment';


const showIndiaTime = (time) => {
  return new Date(time).getTime() + 19800000
}

const timeFormat = (value) => {
  return moment(showIndiaTime(value)).format(' HH:mm a');
}

const dateFormat = (value) => {
  return moment(showIndiaTime(value)).format('DD/MM/YYYY ');
}



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#98D2C5'
        // backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const columns = [
  {
    field: 'createdAt',
    headerName: 'Date',
    minWidth: 100,
    flex: 1,
    renderCell: (params) => {
      return (
        <div className='w-full items-center justify-center'>
          <h6 className='text-sm font-semibold'>{dateFormat(params?.row?.createdAt)}</h6>
          <h6 className='text-sm font-normal'>{timeFormat(params?.row?.createdAt)}</h6>

        </div>
      )
    }

  },


  {
    field: 'cases',
    headerName: 'Student',
    minWidth: 100,
    flex: 1,
    renderCell: (params) => {

      const student = params?.row?.cases?.name
      return (
        <div className='flex items-center gap-1'>
          {params?.row?.cases?.image ?
            <Avatar
              src={`${baseUrl}uploads/staff/download/${params?.row?.cases?.image}`}
              alt='#'
            >A</Avatar> :
            <Avatar><h6 className='uppercase text-sm'>{student[0]}</h6></Avatar>}
          <h6 className='text-sm lg:text-xs capitalize'>{student}</h6>
        </div>

      )
    }

  },
  {
    id: 'course',
    headerName: 'Course',
    minWidth: 100,
    flex: 1,
    renderCell: (params) => {




      return <div className='flex items-center gap-1'>
        <p>{params?.row?.subjects?.courses?.name}   </p>

      </div>
    },

  },

  {
    field: 'subjects',
    headerName: 'Subject',
    minWidth: 100,
    flex: 1,
    renderCell: (params) => {


      return (
        <div
          className='flex items-center gap-10'
        >
          <p>{params?.row?.subjects?.name}   </p>
          <div>
            <ArrowForwardIcon sx={{ color: '#7BB5A8' }} />
          </div>

        </div>
      )
    },
  },
];


export default function FacultyDetail(props) {
  const theme = useTheme();
  const location = useLocation();
  const [loading, setLoading] = React.useState(true);
  const facultyId = location?.state?.data
  // const getFaculty=location?.state?.getFaculty
  const [value, setValue] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openDoubt, setOpenDoubt] = React.useState(true)
  const [closeDoubt, setCloseDoubt] = React.useState(false)
  const [courses, setCourses] = React.useState([])
  const [subjects, setSubjects] = React.useState([])
  const [faculty, setFaculty] = React.useState([]);
  const [doubt, setDoubt] = React.useState([]);
  const [doubtReq, setDoubtReq] = React.useState({
    selectClose: false, selectOpen: true, selectedFaculty: [facultyId], role: 'faculty'
  });
  const navigate = useNavigate()
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const getFacultyDetail = async () => {

    const res = await get(`faculties/${facultyId}/facultyDetails`)
    console.log(res);

    if (res?.statusCode === 200) {
      setLoading(true)

      setFaculty(res?.data?.response)
      setCourses(res?.data?.response?.courses)
      setSubjects(res?.data?.response?.courses?.map((data) => data?.subjects))


    }
    else {
      console.log(res)
    }
    setLoading(false)
  }

  const facultyStatus = async (id, updatedStatus) => {
    const res = await patch(`faculties/${id}`, { "status": !updatedStatus })
    if (res?.statusCode === 200) {
      getFacultyDetail()
    }
  }

  const getDoubt = async () => {
    console.log(doubtReq);
    const res = await post('/doubts/getDoubtList', doubtReq)
    console.log(res);
    if (res?.statusCode === 200) {
      setDoubt(res?.data)
    }
    else {
      console.log(res)
    }
  }

  const clickRow = (params) => {
    navigate(`/admin/home/doubts#${params?.row?.id}`)
  }


  React.useEffect(() => {
    getFacultyDetail()
    getDoubt()
  }, [doubtReq?.selectOpen, doubtReq?.selectClose])


  // DATAGRID STYLES 

  const datagridStylesCss = {

    '& .css-rtrcn9-MuiTablePagination-root': {
      color: theme.palette.headings.main
    },
    '& .css-zylse7-MuiButtonBase-root-MuiIconButton-root.Mui-disabled': {
      color: theme.palette.disabled.main
    },
    '& .css-8nphli': {
      color: theme.palette.headings.main
    },
    '& .css-1deacqj.Mui-disabled': {
      color: theme.palette.disabled.main
    },
    '& .css-1utq5rl': {
      color: theme.palette.headings.main
    },
    '& .css-1j7qk7u': {
      color: theme.palette.headings.table,
    },
    border: 0,
    // backgroundColor: '#F9FAFF',
    backgroundColor: 'theme.palette.bg.adminApp',
    flex: 1,
    border: `1px solid ${theme.palette.searchBorder.main}`,
    flex: 1,
    '&>.css-wop1k0-MuiDataGrid-footerContainer': {
      '& .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar': {
        color: theme.palette.headings.table,
      },

      '& .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar': {
        color: theme.palette.headings.table,
      },
      '& .css-rtrcn9-MuiTablePagination-root .MuiTablePagination-selectLabel ': {
        color: theme.palette.headings.table,
      },
      '& .css-rtrcn9-MuiTablePagination-root .MuiTablePagination-input': {
        color: theme.palette.headings.table,
      },
      '& .css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon': {
        color: theme.palette.headings.table,
      },
      '& .css-i4bv87-MuiSvgIcon-root': {
        color: theme.palette.headings.table,
      },
      '& .css-levciy-MuiTablePagination-displayedRows': {
        color: theme.palette.headings.table,
      },
      // display: '',
      // justifyContent: 'flex-start',
      borderTop: `1px solid  transparent`,
      color: theme.palette.headings.table,
    },
    '& .css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root': {
      color: theme.palette.headings.table,
    },
    '&>.MuiDataGrid-main': {
      '&>.MuiDataGrid-columnHeaders': {

        // backgroundColor: '#55555512',
        // backgroundColor: theme.palette.bg.tableHeader,
        color: theme.palette.headings.table,
        fontWeight: '600',
        fontSize: "14px",
        borderBottom: `1px solid transparent`,
        justifyContent: 'space-between',
        borderRadius: '0px'
      },
      '&>.MuiDataGrid-columnHeaderTitleContainer': {
        border: 'none',
      },



      '& div div div div >.MuiDataGrid-cell': {
        border: 'none',
        fontSize: 14,
        // flexGrow: 1,
      },
      '& .MuiDataGrid-row': {
        cursor: 'pointer',
        marginTop: 1,
        color: theme.palette.headings.table
      },

    }

  }


  return (
    <div style={{ backgroundColor: theme.palette.bg.adminApp }} className='flex-1 p-4  flex flex-col '>


      <div className='flex gap-1 '>
        <ArrowBackIcon sx={{ color: theme.palette.headings.mode, cursor: 'pointer' }} onClick={() => { navigate(-1) }} />
        <h4
          style={{ color: theme.palette.headings.mode }}
          className='text-lg capitalize lg:text-lg font-semibold tracking-wider'
        >
          {faculty?.name}
        </h4>
        <h4
          style={{ color: theme.palette.headings.mode }}
          className='text-lg lg:text-lg font-semibold tracking-wider'
        >
          {faculty?.rating}
        </h4>
      </div>

      <div className=' flex-1 flex flex-col mt-6 gap-4  xl:flex-row'>
        <div className='flex-1 flex flex-col gap-2'>
          <div className='flex gap-4 flex-col'>

            <div className='flex flex-col  gap-1 lg:flex-row'>
              <div
                style={{
                  backgroundColor: theme.palette.bg.adminApp,
                  border: `1px solid ${theme.palette.searchBorder.main}`
                }}
                className='flex rounded gap-4  p-2 px-3'>
                <div className='w-20 flex items-center justify-center h-full'>
                  <Avatar
                    src={`${baseUrl}uploads/staff/download/${faculty?.image}`}
                    sx={{
                      borderRadius: 0,
                      width: '100%',
                      height: '100%'
                    }}
                  />
                </div>
                <div className='flex flex-col justify-between gap-1'>
                  <div className='flex justify-between '>
                    <div >
                      <Typography sx={{ color: theme.palette.headings.main }}>Basic info</Typography>
                    </div>
                    <div className='flex items-center gap-1'>
                      <AntSwitch size="small"
                        checked={faculty?.status}
                        onClick={(e) => { e.stopPropagation(); facultyStatus(faculty?._id, faculty?.status); }} inputProps={{ 'aria-label': 'ant design' }}
                        sx={{ borderColor: '#3366FF', color: '#98D2C5' }}
                      />
                      <EditFaculty faculty={faculty?._id} getFacultyDetail={getFacultyDetail} onClick={(e) => { e.stopPropagation() }} />
                    </div>
                  </div>
                  <div className='text-sm'>
                    <p style={{ color: theme.palette.headings.main }}>Mobile no. : {faculty?.mobile}</p>
                    <p style={{ color: theme.palette.headings.main }}>Email Id : {faculty?.email}</p>
                  </div>
                </div>
              </div>

              <div className='flex   gap-1'>
                <div
                  style={{
                    backgroundColor: theme.palette.bg.adminApp,
                    border: `1px solid ${theme.palette.searchBorder.main}`
                  }}
                  className='rounded p-3'>
                  <Typography sx={{ color: theme.palette.headings.main }}  >Total open doubts</Typography>
                  <Typography sx={{ color: '#FF3333', fontWeight: 700, marginTop: 2, fontSize: 26 }}>{faculty?.openDoubt}</Typography>
                </div>
                <div
                  style={{
                    backgroundColor: theme.palette.bg.adminApp,
                    border: `1px solid ${theme.palette.searchBorder.main}`
                  }}
                  className='rounded p-3'>
                  <Typography sx={{ color: theme.palette.headings.main }}>Total closed doubts</Typography>
                  <Typography sx={{ color: '#00B05B', fontWeight: 700, marginTop: 2, fontSize: 26 }}>{faculty?.closedDoubt}</Typography></div>
              </div>
            </div>

          </div>
          <Box className='flex-1 max-w-[90vw]  flex flex-col gap-8'  >
            <Tabs
              value={value}
              onChange={handleChange}
              TabIndicatorProps={{
                style: { backgroundColor: "#67BBA2", height: "3px" },
              }}
            >
              <Tab
                label="Open doubts"
                onClick={() => {
                  setDoubtReq((prevState) => ({
                    ...prevState,
                    selectClose: false,
                    selectOpen: true,
                  }));
                }}
                sx={{
                  color: theme.palette.headings.selected,
                  fontSize: 14,
                  '&.Mui-selected': {
                    color: theme.palette.headings.mode,
                  },
                }}
              />

              <Tab
                label="Closed doubts"
                onClick={() => {
                  setDoubtReq((prevState) => ({
                    ...prevState,
                    selectClose: true,
                    selectOpen: false,
                  }));
                }}
                sx={{
                  color: theme.palette.headings.selected,
                  fontSize: 14,
                  '&.Mui-selected': {
                    color: theme.palette.headings.mode,
                  },
                }}
              />
            </Tabs>


            <DataGrid
              rows={doubt}
              loading={loading}
              autoHeight={true}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              onRowClick={(params) => clickRow(params)}

              hideFooterPagination
              hideFooterSelectedRowCount
              disableColumnMenu

              disableColumnSelector
              disableColumnResize='true'
              pageSizeOptions={[5, 10, 15, 20, 30, 50, 75, 100]}

              sx={datagridStylesCss}

              slots={{
                footer: () => (
                  <>
                    <Typography variant="text" className='underline text-[#7BB5A8] font-semibold cursor-pointer flex justify-end pr-4 pb-2' onClick={() => { navigate("/admin/home/doubts") }}>View all</Typography>
                  </>
                ),
              }}

            />

          </Box>

        </div>

        <div
          style={{
            backgroundColor: theme.palette.bg.adminApp,
            border: `1px solid ${theme.palette.searchBorder.main}`
          }}
          className='flex-1 rounded flex flex-col  p-3'>
          <Typography
            sx={{
              color: theme.palette.headings.main,
              margin: "10px 0",
              fontWeight: '700'
            }}
          >
            Courses
          </Typography>

          {courses?.map((course, index) => {


            return (<div key={course?.id}>

              <Accordion
                style={{ boxShadow: "none", backgroundColor: '#D9D9D93B', marginBottom: 5, }}

                defaultExpanded={index === 0 ? true : false}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon
                    sx={{
                      color: theme.palette.headings.main
                    }}
                  />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ paddingBottom: 0 }}
                >
                  <Typography
                    sx={{
                      color: theme.palette.headings.main
                    }}
                  >
                    {course?.name} ({course?.country?.currencyName})
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {
                    course?.subjects?.map((subject) => {

                      return (

                        <div key={subject?.id} className='flex items-center mb-5 justify-between'>
                          <Typography
                            sx={{
                              color: theme.palette.headings.main
                            }}
                          >
                            {subject?.name}
                          </Typography>
                          <Typography><span className='text-[#00B05B] font-semibold'>{subject?.closedDoubt}|</span><span className='text-[#FF3333] font-semibold'>{subject?.openDoubt}</span></Typography>
                        </div>

                      )

                    })
                  }

                </AccordionDetails>
              </Accordion>



            </div>)

          })}

        </div>
      </div>

    </div >

  )
}
