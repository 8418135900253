import React, { useState } from 'react'
import { adminAuthState, facultyAuthState, studentAuthState } from '../Services/RecoilService'
import { useRecoilState } from 'recoil'
import { Outlet, useNavigate } from 'react-router-dom'

const AdminAuth = () => {
    const navigate = useNavigate();
    const [authState, setAuthState] = useRecoilState(studentAuthState)
    const [_facultyAuthState, set_FacultyAuthState] = useRecoilState(facultyAuthState)
    const [_adminAuthState, set_AdminAuthState] = useRecoilState(adminAuthState)
    const [authenticated, setAuthenticated] = useState(false)

    React.useEffect(() => {

        const path = window?.location?.pathname?.split('/');

            if (!_adminAuthState?.id) {
                navigate('/admin')
            } else {
                setAuthenticated(true)
            }
      

    }, [authenticated])


    return (
        <div>
            <div className="w-full h-full">
                {authenticated ?
                    <Outlet /> :
                    <div className="w-full h-full flex justify-center items-center">
                        <div>
                            <h1 className="text-red-700 text-[5vmax]"></h1>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default AdminAuth
