import React, { useState } from 'react'
import { useTheme } from '@mui/system';

import { Button, IconButton, InputBase, Typography, Box } from '@mui/material'
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

// import {BiSearch} from '@mui/icons-material/Search' 
import SearchIcon from '@mui/icons-material/Search';
export default function Search(props) {
  const theme = useTheme();

  const [searchData, setSearchData] = useState("")
  const submitSearch = async (e) => {
    setSearchData(e.target.value)

    if (props.getFaculty) {
      props.getFaculty(null, e.target.value)
    }
    else {
      props.getStudent(e.target.value)
    }
  }

  
  return (
    <>

      <TextField
        value={props?.filterData?.text}
        onChange={(e) => {
          props.setFilterData({
            ...props.filterData,
            text: e.target.value
          });
        }}
        sx={{
          '& .MuiInputBase-root': {
            color: theme.palette.headings.main,
            // style={{ backgroundColor: theme.palette.searchBg.main, border: `1px solid ${theme.palette.searchBorder.main}` }}
            fontWeight: '500',
            borderColor: 'red',
            border: `1px solid ${theme.palette.searchBorder.main}`,
            padding: '2px 10px',
            [theme.breakpoints.down('sm')]: {
              fontSize: '1.5vmax', // Font size for large screens
              padding: '3px 10px',
            },
            [theme.breakpoints.up('lg')]: {
              fontSize: '.9vmax', // Font size for large screens
              padding: '2px 10px',
            },
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ color: theme.palette.headings.main }} />
            </InputAdornment>
          ),
        }}
        id="outlined-basic"
        size='small'
        placeholder="Search"
        variant="outlined"
        fullWidth
      />
    </>

  )
}
