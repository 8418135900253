import React from 'react'
import { useTheme } from '@mui/system';
import ChatList from '../StudentComponents/ChatList'
import { LogOutDarkIcon, LogOutIcon } from '../StudentComponents/Icons';
import { adminAuthState, currentDoubtId, currentRole, currentScreen, currentTheme, facultyAuthState, studentAuthState } from '../../Services/RecoilService';
import { useRecoilState } from 'recoil';
import { get, patch, post } from '../../Services/api-services';
import { useLocation, useNavigate } from 'react-router-dom';
import AskDoubt from './AskDoubt'
import UserMedia from '../StudentComponents/UserMedia';
import ChatArea from './ChatArea';
// import { SocketContext, socket } from '../../Socket/socket';
import { emitNewMessage, setUpSocket, statusChange } from '../../Socket/Emit';
import { useContext } from "react";
import SocketContext from '../StudentComponents/socketContext/Context';
import FilterDrawer from '../StudentComponents/FilterDrawer';
import PreviewImaDialogue from '../StudentComponents/PreviewImageDialogue'
import { CurrentDoubtIdContext } from '../../App';
import { debounce, filter } from "lodash"

const MainScreen = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const [ctheme, setCTheme] = useRecoilState(currentTheme)
    const [authState, setAuthState] = useRecoilState(studentAuthState)
    const [_facultyAuthState, set_FacultyAuthState] = useRecoilState(facultyAuthState)
    const [_adminAuthState, set_AdminAuthState] = useRecoilState(adminAuthState)
    const [role, setRole] = useRecoilState(currentRole)
    const [userDets, setUserDets] = React.useState('')
    const [doubtDets, setDoubtDets] = React.useState('')
    const [message, setMessage] = React.useState('')
    const [doubtId, setDoubtId] = React.useState('')
    const [askDoubt, setAskDoubt] = React.useState(false);
    const [isChaList, setisChaList] = React.useState(true);
    const [isMedia, setIsMedia] = React.useState(true);
    const [isChat, setIsChat] = React.useState(true);
    const [courses, setCourses] = React.useState([])
    const [doubts, setDoubts] = React.useState([])
    const [userMessages, setUserMessages] = React.useState([])
    const [currentDoubt, setcurrentDoubt] = React.useState(null)
    const [image, setImage] = React.useState([])
    const [imageType, setImageType] = React.useState('camera')
    const [mediaType, setMediaType] = React.useState('text')
    const [audio, setaudio] = React.useState(null)
    const [openMedia, setOpenMedia] = React.useState(false)
    const [currentMedia, setCurrentMedia] = React.useState(null)
    const [allMedia, setAllMedia] = React.useState([])
    const [currentStatus, setCurrectStatus] = React.useState('')
    // const [currentDoubtId, setCurrectDoubtId] = React.useState('')
    const [allFaculties, setAllFaculties] = React.useState([])
    const [selectClose, setSelectClose] = React.useState(false)
    const [selectOpen, setSelectOpen] = React.useState(false)
    const [picname, setPicName] = React.useState('')
    const [liked, setLiked] = React.useState('')
    const [tempStatus, setTempStatus] = React.useState(false)
    const [Bclosed, setBclosed] = React.useState(false)
    const [isUploading, setIsUploading] = React.useState(false)
    const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
    const [searchText, setSearchText] = React.useState('')
    const [userStatus, setuserStatus] = React.useState(true)
    const [isFilter, setIsFilter] = React.useState(false)

    const [filterData, setFilterData] = React.useState({
        selectedStudents: role === 'student' ? [authState?.userId] : [],
        selectedFaculty: role === 'faculty' ? [_facultyAuthState?.userId] : [],
        role: role,
        selectedAdmin: role === 'admin' ? [_adminAuthState?.userId] : [],
        selectStartDate: '',
        selectEndDate: '',
        selectedCourses: [],
        selectedSubjects: [],
        selectOpen: false,
        selectClose: false,
        // status:'',
        text: ''
    })

    // RESET FILTER 

    const removeAll = () => {
        const resetData = {
            selectedStudents: role === 'student' ? [authState?.userId] : [],
            selectedFaculty: role === 'faculty' ? [_facultyAuthState?.userId] : [],
            role: role,
            selectedAdmin: role === 'admin' ? [_adminAuthState?.userId] : [],
            selectStartDate: '',
            selectEndDate: '',
            selectedCourses: [],
            selectedSubjects: [],
            faculty: [],
            selectOpen: false,
            selectClose: false,
            // status:'',
            text: ''
        }
        setFilterData(resetData)
        getAllDoubts(resetData);
        setIsFilter(false)
    }


    // Event handler for the 'resize' event

    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };

    // Effect to attach and detach the 'resize' event listener

    React.useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);

    // // SET THE SCREEN ON MOBILE 

    React.useEffect(() => {
        if (screenWidth > 1024) {
            setisChaList(true)
            setIsMedia(true)
            setIsChat(true)
        } else {
            setisChaList(true)
            setIsMedia(false)
            setIsChat(false)
        }
    }, [screenWidth])


    const { _messages, _doubts } = useContext(SocketContext);

    React.useEffect(() => {
        setUpSocket(authState?.userId)
    }, [])


    React.useEffect(() => {
        updateMessages();
    }, [_messages])

    React.useEffect(() => {
        updateMessages();
        GetUserDetails();
        // alert('hello')
    }, [_doubts])

    const updateMessages = async () => {
        if (currentDoubt) {
            const res = await getDoubtDetails(currentDoubt);
        }
        getAllDoubts(filterData)
    }

    React.useEffect(() => {
        GetUserDetails();
        GetCourses();
        getAllDoubts(filterData);
        getFaculties()
    }, [])

    const GetUserDetails = async () => {
        try {
            if (role === 'student') {
                const res = await get(`cases/${authState?.userId}`);
                if (res?.data?.deleted) {
                    setuserStatus(false)
                } else {
                    setuserStatus(true)
                }

                if (authState?.userId) {
                    setUserDets(res?.data);
                }


            } else if (role === 'faculty') {

                const res = await get(`faculties/${_facultyAuthState?.userId}/facultyDetails`);
                if (!res?.data?.response?.status) {
                    setuserStatus(false)
                } else {
                    setuserStatus(true)
                }

                if (_facultyAuthState?.userId) {
                    setUserDets(res.data?.response);
                }


            } else {
                // Handle other roles or navigate as needed
                const res = await get(`admins/${_adminAuthState?.userId}`)

                if (_adminAuthState?.userId) {
                    // navigate('/admin/doubts')
                    setUserDets(res.data?.response);
                }


            }

        } catch (e) {
            console.log(`ERROR WHILE FETCHING USER DETAILS --- ${e}`)
            console.log(e?.response?.data?.error?.statusCode, 'hello');
        }
    }


    const getFaculties = async () => {
        try {
            const res = await get('faculties')
            if (res?.statusCode === 200) {
                setAllFaculties(res?.data)
            }
        } catch (e) { console.log(e); }
    }


    const GetCourses = async () => {
        try {

            if (role === 'student') {
                const body = {
                    caseId: authState?.userId
                }
                const res = await post(`subscribedCourses/getCoursesBycaseId`, body);
                if (res?.data) {
                    setCourses(res?.data)
                }
            } else if (role === 'faculty') {
                const res = await get(`faculties/${_facultyAuthState?.userId}/getfacultyCourses`)
                console.log(res);
                if (res?.data) {
                    setCourses(res?.data)
                }
            } else {
                // const res = await get(`courses`)
                const res = await get('courses?filter={"where": {"deleted":false},"include":"country"}')

                if (res?.data) {
                    setCourses(res?.data)
                }
            }

        } catch (e) {
            console.log(e);
        }
    }

    const uploadImage = async (e) => {
        let formData = new FormData()
        formData.append('file', e.target.files[0])
        const res = await post(`uploads/staff/upload`, formData)
        if (res?.statusCode === 200) {
            if (role === 'student') {
                const ress = await patch(`cases/${authState?.userId}`, { image: res?.data?.result?.files?.file[0].name })
            }
            else if (role === 'faculty') {
                const ress = await patch(`faculties/${_facultyAuthState?.userId}`, { image: res?.data?.result?.files?.file[0].name })
            }
            GetUserDetails();
        }

    }

    const getAllDoubts = async (data) => {
        try {
            const response = await post('doubts/getDoubtList', data);

            if (response.data) {
                setDoubts(response.data);
            }
        } catch (e) {
            console.log(`ERROR WHILE FETCHING DOUBTS --- ${e}`);
        }
    };

    React.useEffect(() => {
        xfatchFilterStudent(filterData);
    }, [filterData?.text])


    const xfatchFilterStudent = React.useCallback(debounce((text) => getAllDoubts(text), 300), []);

    const updateStatusFunc = async (req, temp) => {
        const body = {}
        // if (req) body.status = req;
        // if (temp) body.responded = temp;
        if (req) {
            body.status = req;
            body.responded = temp;
        } else {
            body.responded = temp;
        }


        try {
            const res = await patch(`doubts/${currentDoubt}`, body)
            setCurrectStatus(res?.status)
            statusChange({ update: 'update status' });
            getDoubtDetails(currentDoubt)
        } catch (e) {
            console.log(e);
        }
    }

    const updateLikeFunc = async (req, temp) => {
        try {
            const res = await patch(`doubts/${currentDoubt}`, { likeAns: req, tempLike: temp })
            getDoubtDetails(currentDoubt)
        } catch (e) {
            console.log(e);
        }
    }

    const Logout = async () => {
        try {
            const res = await post('cases/logout')
            console.log(res);
            localStorage.clear();
            window.location.reload();

            // if (res?.data?.status === 200) {
            //     setIsOtp(true);
            //     const _snackbar = {
            //         message: res?.data?.message,
            //         open: true,
            //         duration: 3000,
            //         type: 'success'
            //     };

            //     dispatch(showToast(_snackbar))
            //     setAuthState(res?.data?.data)
            //     setRole('student');
            //     navigate('/student/home')


            // } else {
            //     const _snackbar = {
            //         message: res?.data?.error,
            //         open: true,
            //         duration: 3000,
            //         type: 'error'
            //     };

            //     dispatch(showToast(_snackbar))
            // }
        } catch (e) {
            console.log(`ERROR WHILE FETCHING USER DETAILS --- ${e}`)
        }
    }

    const getDoubtDetails = async (id) => {
        try {
            const response = await get(`doubts/${id}/getMessages?role=${role}`);

            if (response?.data?.response?.likeAns) {
                setLiked(response?.data?.response?.likeAns)
            } else {
                setLiked('')
            }
            if (response?.data?.response?.responded) {
                setTempStatus(response?.data?.response?.responded)
            } else {
                setTempStatus(false)
            }

            // if (response?.data?.response?.tempLike) {
            //     setTempLiked(response?.data?.response?.TempLike)
            // }

            if (response?.statusCode === 200) {
                setCurrectStatus(response?.data?.response?.status)
                // setLikeAns(response?.data?.response?.likeAns)
                // setCurrectDoubtId(response?.data?.response?._id)
            }
            const _messages = response?.data?.response?.messages?.reverse();
            setDoubtDets(response?.data?.response)
            setUserMessages(_messages)
            setDoubtId(response?.data?.response?._id)

            return 'success';
        } catch (e) {
            console.log(`ERROR WHILE FETCHING DOUBT DETAILS --- ${e}`)
        }
    }

    const onTextMessage = async () => {
        try {
            const body = [{
                message: message,
                type: 'text',
                status: true,
                read: false,
                doubtId: doubtDets?._id,
                sendFrom: role,
                caseId: doubtDets?.studentId,
                facultyId: doubtDets?.facultyId
            }]

            emitNewMessage(body);
            getAllDoubts(filterData);
            setMessage('')

            if (role === 'student') {
                updateStatusFunc('new')
            }

            if (role === 'faculty' && currentStatus === 'answering') {
                updateStatusFunc('answered')
            }
        } catch (e) {
            console.log(`ERROR WHILE SENDING MESSAGE --- ${e}`)
        }
    }

    // UPLOADS FILES FROM MSG

    const uploadFiles = async (fileType, files) => {
        try {

            let formdata = new FormData();

            if (fileType == 'audios') {
                formdata.append("file", files, new Date().getTime() + '.mp3');
            } else {
                for (let index = 0; index < files?.length; index++) {
                    formdata.append("file", files[index], files[index]?.name);
                }
            }

            return post(`uploads/${fileType}/upload`, formdata);
            // return response;

        } catch (error) {
            console.error(`Error uploading ${fileType}:`, error);
        }
    };

    // MSG IMAGES FROM MSG

    const handleSendImages = async () => {
        try {
            const body = [];
            const containerType = mediaType === 'audio'
                ? 'audios'
                : mediaType === 'image'
                    ? 'images'
                    : 'documents';

            setIsUploading(true);
            const imagesUploadResponse = await uploadFiles(containerType, image);
            setIsUploading(false);
            const filesArray = Object.values(imagesUploadResponse?.data?.result?.files?.file || []);

            // Extract file names
            const fileNamesArray = filesArray.map((file) => file.name);

            // Create an array of body objects with different filenames
            const bodiesArray = fileNamesArray.map((filename) => ({
                message: filename,
                type: mediaType,
                status: true,
                read: false,
                doubtId: doubtDets?._id,
                sendFrom: role,
                caseId: doubtDets?.studentId,
                facultyId: doubtDets?.facultyId,
                // filename: filename, // Add the filename to the body
            }));

            emitNewMessage(bodiesArray);
            if (role === 'student') {
                updateStatusFunc('new')
            }

            setImage([])

        } catch (error) {
            console.error(`Error while Sending Image Msg :`, error);
        }
    }

    // FILTER DRAWER

    const [filterOpen, setFilterOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setFilterOpen(true);
    };

    const handleDrawerClose = () => {
        setFilterOpen(false)
    };

    const _previewMedia = (media) => {
        setOpenMedia(true)
        setCurrentMedia(media)
        setAllMedia(doubtDets?.messages);
    }

    // const selectDoubt = async (id) => {
    //     setcurrentDoubt(id);
    //     await getDoubtDetails(id);
    //     getAllDoubts();
    // }

    const selectDoubt = async (id) => {
        // if (screenWidth < 1024) {
        if (role === 'admin') {
            navigate(`/${role}/home/doubts#${id}`, { replace: true })
        } else {
            navigate(`/${role}/home#${id}`, { replace: true })
        }
        // }
    }

    const selectMedia = async () => {
        if (screenWidth < 1024) {
            navigate(`/${role}/home#${doubtId}#media`, { replace: true })
        }
    }


    React.useEffect(() => {
        if (location?.hash) {
            const id = location?.hash.split('#')[1];
            setcurrentDoubt(id);
            setDoubtId(id)
            getDoubtDetails(id).then(() => {
                getAllDoubts(filterData);
            });

            if (screenWidth < 1024) {
                if (location?.hash?.includes('#media')) {
                    setIsChat(false)
                    setisChaList(false)
                    setIsMedia(true)
                } else {
                    setIsChat(true)
                    setisChaList(false)
                    setIsMedia(false)
                }

            } else {
                setIsChat(true)
                setisChaList(true)
                setIsMedia(true)
            }

        }
    }, [location?.hash])

    const backHome = async () => {
        if (screenWidth < 1024) {
            navigate(`/${role}/home`, { replace: true })
            setIsChat(false)
            setisChaList(true)
            setIsMedia(false)
        }
    }

    const backToChat = async () => {
        if (screenWidth < 1024) {
            navigate(`/${role}/home#${doubtId}`, { replace: true })
            setIsChat(true)
            setisChaList(false)
            setIsMedia(false)
        }
    }

    return (
        <>
            <div style={{ backgroundColor: theme.palette.bg.main }} className='w-full h-screen flex  items-start'>

                {/* CHATLIST COMPONENT */}
                {isChaList ?
                    <div className='w-full lg:block h-screen lg:w-1/4'>
                        <ChatList
                            onchange={() => setAskDoubt(true)}
                            ctheme={ctheme}
                            userDets={userDets}
                            doubts={doubts}
                            setDoubts={setDoubts}
                            doubtId={doubtId}
                            // theme={ctheme}
                            onSelect={(id) => selectDoubt(id)}
                            onFilter={handleDrawerOpen}
                            role={role}
                            uploadImage={uploadImage}
                            picname={picname}
                            Bclosed={Bclosed}
                            searchText={searchText}
                            setSearchText={setSearchText}
                            filterData={filterData}
                            setFilterData={setFilterData}
                            userStatus={userStatus}
                            screenWidth={screenWidth}
                            isFilter={isFilter}
                        />

                    </div>
                    : null
                }

                <div style={{ backgroundColor: theme.palette.bg.third }} className=' flex-1 flex lg:flex items-center justify-center cursor-pointer bg-[#FFFFFF] h-screen'>
                    {/* LOGOUT ICON */}
                    <div
                        onClick={Logout}
                        className='fixed hidden lg:flex right-0 top-0 py-4 px-5 z-50'
                    >
                        {ctheme === 'dark' ? (
                            <LogOutDarkIcon />
                        ) : (
                            <LogOutIcon />
                        )}
                    </div>

                    {isChat ? (
                        <div className='w-full lg:w-[70%]'>
                            <ChatArea
                                userStatus={userStatus}
                                isUploading={isUploading}
                                doubtDets={doubtDets}
                                setDoubtDets={setDoubtDets}
                                userMessages={userMessages}
                                setUserMessages={setUserMessages}
                                ctheme={ctheme}
                                message={message}
                                setMessage={setMessage}
                                onSend={onTextMessage}
                                image={image}
                                currentStatus={currentStatus}
                                setCurrectStatus={setCurrectStatus}
                                currentDoubtId={currentDoubtId}
                                setImage={setImage}
                                onSendImages={handleSendImages}
                                imageType={imageType}
                                setImageType={setImageType}
                                mediaType={mediaType}
                                setMediaType={setMediaType}
                                audio={audio}
                                setaudio={setaudio}
                                role={role}
                                Bclosed={Bclosed}
                                setBclosed={setBclosed}
                                tempStatus={tempStatus}
                                setTempStatus={setTempStatus}
                                liked={liked}
                                setLiked={setLiked}
                                setIsMedia={setIsMedia}
                                setIsChat={setIsChat}
                                setIsChatList={setisChaList}
                                getDoubtDetails={getDoubtDetails}
                                onUpdateStatus={(_status, _temp) => updateStatusFunc(_status, _temp)}
                                onUpdateLikeStatus={(_likeStatus, tempLike) => updateLikeFunc(_likeStatus, tempLike)}
                                backHome={backHome}
                                selectMedia={selectMedia}
                                screenWidth={screenWidth}
                            />
                        </div>
                    ) : null}


                    {(doubtDets && isMedia) ? (
                        <div className='w-full  lg:w-[30%] '>
                            <UserMedia
                                doubtDets={doubtDets}
                                onPreview={(media) => _previewMedia(media)}
                                setIsChat={setIsChat}
                                setIsMedia={setIsMedia}
                                role={role}
                                doubtId={doubtId}
                                backToChat={backToChat}
                            />
                        </div>
                    ) : null}

                    <FilterDrawer
                        open={filterOpen}
                        onClose={handleDrawerClose}
                        allCourses={courses}
                        allFaculties={allFaculties}
                        filterData={filterData}
                        setFilterData={setFilterData}
                        getAllDoubts={() => {
                            getAllDoubts(filterData);
                            setIsFilter(true)
                        }}
                        role={role}
                        selectClose={selectClose}
                        setSelectClose={setSelectClose}
                        selectOpen={selectOpen}
                        setSelectOpen={setSelectOpen}
                        removeAll={removeAll}
                    />
                </div>

                {openMedia &&
                    <PreviewImaDialogue
                        currentMedia={currentMedia}
                        setCurrentMedia={setCurrentMedia}
                        allMedia={allMedia}
                        setOpenMedia={setOpenMedia}
                        openMedia={openMedia}
                    />
                }

            </div>

            {askDoubt ?
                <AskDoubt
                    courses={courses}
                    open={askDoubt}
                    student={userDets}
                    onClose={() => setAskDoubt(false)}
                    studentId={authState?.userId}
                    getDoubtDetails={getDoubtDetails}
                    currentDoubtId={currentDoubtId}
                    onAddDoubt={() => getAllDoubts(filterData)}
                    audio={audio}
                    setaudio={setaudio}
                    image={image}
                    setImage={setImage}
                    mediaType={mediaType}
                    setMediaType={setMediaType}
                // onSignUp={() => { setOpenLogin(false); handleSignup() }}
                /> : null
            }
        </>
    )
}

export default MainScreen
