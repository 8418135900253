import React from 'react'
import { useLocation } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import 'react-toastify/dist/ReactToastify.css';
import {
    Typography,

} from "@mui/material";
import { post } from "../../Services/api-services";
import { useNavigate } from "react-router-dom";
import { passwordRegex } from "../../ValidationsRegex";
import { showToast } from '../../store/Actions/Action';
import { useDispatch } from 'react-redux';
import loginImg from "../../Assets/Images/loginImg.png";
import aptlogo from "../../Assets/Images/aptlogo.png"
import macbook from '../../Assets/Images/macbook.png'

const ResetPassword = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const receivedData = location.state;
    const [formResetPassword, setFormResetPassword] = React.useState(null)
    const [confirmPassword, setConfirmPassword] = React.useState(null)

    // CHANGE PASSWORD FORM 

    const changePassword = async () => {
        try {

            const formdata = {
                newPassword: formResetPassword
            }

            const res = await post(`Admins/reset-password?access_token=${receivedData?.token}`, formdata)
            if (res?.statusCode === 204) {
                const _snackbar = {
                    // message: res?.data?.message,
                    message: 'Password  Changed',
                    open: true,
                    duration: 3000,
                    type: 'success'
                };

                dispatch(showToast(_snackbar))
                navigate('/admin')
            } else {
                const _snackbar = {
                    // message: res?.data?.message,
                    message: 'Password not Changed',
                    open: true,
                    duration: 3000,
                    type: 'error'
                };

                dispatch(showToast(_snackbar))
            }

        } catch (e) {
            console.log(e)
        }
    }


    return (
        <div className="flex    gap-10">

            <div className="h-screen hidden w-full flex-1 xl:flex">
                <div className="hidden h-full xl:flex " >
                    <img src={loginImg} className="w-[70%] mt-8" />
                    <div className="absolute mt-8 left-20">
                        <img src={aptlogo} alt="aptlogo" width={126.93} height={95.2} />
                        <p className="text-[#686464] font-normal">Student doubt panel</p>
                    </div>
                    <img src={macbook} alt="macbook" className="w-[764.51px] absolute top-1/3 left-24" />
                </div>

            </div>

            {/* Reset Password  container */}

            <div className="flex-1 h-screen  items-center justify-center flex flex-col px-3 left-0 right-0 xl:w-full">
                {/* <div className="hidden xl:flex flex-col absolute left-6 top-6 "> */}

                {/* <div className="items-center justify-center flex flex-col gap-8 xl:my-[40%]"> */}
                <div className="items-center w-full lg:w-[35vw] justify-center flex flex-col gap-8">
                    <div className="my-3">
                        <Typography className="text-[#555555]  font-extrabold" variant="h6" sx={{ fontWeight: 800 }}>Forgot password</Typography>
                    </div>
                    {/* <p className="text-sm text-[#5D5D5D] font-medium">We’ve send otp to your registered email Id {adminLogin?.email}</p> */}
                    <div className="flex flex-col w-full">
                        <TextField
                            // id="input-with-icon-textfield"

                            label="New password"
                            type="text"
                            onChange={(e) => setFormResetPassword(e?.target?.value)}
                            error={
                                formResetPassword?.length > 0 && !passwordRegex?.test(formResetPassword)
                            }
                            helperText={
                                formResetPassword?.length > 0 && !passwordRegex?.test(formResetPassword)
                                    ? "enter valid Password"
                                    : ""
                            }
                            sx={{
                                '& label.Mui-focused': {
                                    color: "#9D9D9D",
                                },
                                '& .MuiInput-underline:after': {
                                    borderBottomColor: '#A9CEC6',

                                },
                                '& .MuiInput-underline:before': {
                                    borderBottomColor: '#A9CEC6',

                                },


                            }}

                            variant="standard"

                        />
                    </div>
                    <div className="flex flex-col w-full">
                        <TextField
                            // id="input-with-icon-textfield"

                            label="Confirm password"
                            type="text"
                            onChange={(e) => setConfirmPassword(e?.target?.value)}
                            error={
                                confirmPassword?.length > 0 && formResetPassword !== confirmPassword
                            }
                            helperText={
                                confirmPassword?.length > 0 && formResetPassword !== confirmPassword
                                    ? "Password not Matched"
                                    : ""
                            }
                            autoComplete="off"
                            sx={{
                                '& label.Mui-focused': {
                                    color: "#9D9D9D",
                                },
                                '& .MuiInput-underline:after': {
                                    borderBottomColor: '#A9CEC6',
                                },
                                '& .MuiInput-underline:before': {
                                    borderBottomColor: '#A9CEC6',
                                },
                            }}
                            variant="standard"
                        />
                    </div>
                    <button
                        disabled={
                            !(formResetPassword && confirmPassword && passwordRegex?.test(formResetPassword) && passwordRegex?.test(confirmPassword))
                        }
                        className="rounded-full text-[#FFFFFF] bg-[#98D2C5]  w-full py-2 disabled:opacity-50"
                        onClick={changePassword}
                    >
                        Confirm password
                    </button>
                </div>
            </div>


        </div>
    )
}

export default ResetPassword
