import React, { useEffect } from 'react'
import QuizIcon from '@mui/icons-material/Quiz';
import Card from '@mui/material/Card';
import { Box, ThemeProvider } from '@mui/system';
import CardContent from '@mui/material/CardContent';
import { DataGrid } from '@mui/x-data-grid';
import { baseUrl, get, post } from "../../Services/api-services"

import { Avatar, Typography } from '@mui/material';

import Map from '../../Components/Map';
import Heatmap from '../../Components/Heatmap';
import { useTheme } from '@mui/system';

// FACULTIES COLUMNS 

const columns = [

  {
    field: 'name',
    headerName: 'Name',
    minWidth: 170,
    flex: 1,

    renderCell: (params) => {
      return <div className='flex gap-2  items-center'>
        <div >  {params.row.image ? <Avatar alt={params.row.name} src={`${baseUrl}/uploads/staff/download/${params.row.image}`} /> : <Avatar>{params.row.name[0]?.toUpperCase()}</Avatar>}  </div>
        <div className='flex flex-wrap flex-col'>
          <p className='font-bold flex flex-wrap'>{params.row.name}</p>
          <Typography className='text-[#FFCE31] font-extrabold block lg:hidden'>{params.row.rating}</Typography>
        </div>
      </div>
    }
  },

  {
    field: 'rating',
    headerName: 'Rating',
    minWidth: 170,
    flex: 1,


    renderCell: (params) => {

      return <div>
        <Typography className='text-[#FFCE31] font-extrabold hidden lg:block'>{typeof params.row.rating === 'number' ? params.row.rating.toFixed(2) : ''}</Typography>
      </div>
    },


  },
  {
    field: 'avarageHr',
    headerName: 'Average time / Hr',
    minWidth: 170,
    flex: 1,
    renderCell: (params) => {
      return (
        <div className='flex items-center font-bold' style={{ opacity: params?.row?.status ? 1 : 0.4 }}>
          <p>{(params?.row?.avarageHr ?? 0).toFixed(2)}</p>
        </div>
      )
    },

  },
  {
    field: ' totalDoubt',
    headerName: 'Doubts',
    minWidth: 50,
    renderCell: (params) => {
      const od = params.row.totalDoubt - params.row.closedDoubt
      return <div className='flex items-center font-bold'>
        <p className='text-[#FF3333]'>{od}|</p> <p className='text-[#00B05B]'>{params.row.closedDoubt}</p>
      </div>
    },

  },

];


export default function Dashboard(props) {

  const theme = useTheme();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [dashboard, setDashboard] = React.useState(null);
  const [topFaculty, setTopFaculty] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [askedData, setAskedData] = React.useState({})
  const [closedData, setClosedData] = React.useState({})

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const getAdminData = async () => {
    try {
      setLoading(true)
      const res = await get('Admins/dashboard')

      if (res?.statusCode === 200) {
        setDashboard(res?.data?.response)
        setTopFaculty(res?.data?.response?.faculties)

      }

      setLoading(false)
    } catch (e) {
      console.log(e);
    }

  }


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  const cardData = [
    {
      'icon': <QuizIcon sx={{ color: 'Background' }} />,
      'title': 'Total doubts',
      'count': dashboard?.totalDoubts,
      'color': theme.palette.headings.main
    },
    {
      'icon': <QuizIcon sx={{ color: 'Background' }} />,
      'title': 'Open doubts',
      'count': dashboard?.openDoubts,
      'color': '#FF3333'

    },
    {
      'icon': <QuizIcon sx={{ color: 'Background' }} />,
      'title': 'Closed doubts',
      'count': dashboard?.closeDoubts,
      'color': '#00B05B'

    },
  ]

  const heatMapDatabyStudentApi = async () => {
    try {
      const res = await post('/doubts/heatmapData')
      if (res?.statusCode === 200) {
        setAskedData(res?.data)
      }
    } catch (e) {
      console.log(e);
    }
  }

  const heatMapDatabyFacultyApi = async () => {
    try {
      const res = await post('/doubts/heatmapData', { type: "closed" })
      console.log(res);
      setClosedData(res?.data)
      if (res?.statusCode === 200) {
      }
    } catch (e) {
      console.log(e);
    }
  }


  React.useEffect(() => {
    getAdminData()
    heatMapDatabyStudentApi()
    heatMapDatabyFacultyApi()
  }, [])


  return (
    <div
      style={{
        backgroundColor: theme.palette.bg.adminApp,
        color: theme.palette.headings.main
      }}
      className='w-full flex-1 flex flex-col  font-[Lufga]    pt-8 p-4'>
      <h4
        style={{ color: theme.palette.headings.mode }}
        className='text-lg lg:text-lg font-semibold tracking-wider'
      >
        DASHBOARD
      </h4>
      <div className=' flex-1 flex w-full  flex-col   mt-6 gap-4  lg:flex-row '>
        <div className='w-full  flex flex-col gap-4 '>
          <div className='flex flex-col  w-full  gap-4  lg:flex-row  justify-between'>
            {cardData.map((items, index) => {
              return (
                <div
                  style={{
                    boxShadow: '4.300174713134766px 4.300174713134766px 4.300174713134766px 0px #0000000F',
                    backgroundColor: theme.palette.bg.tableBody,
                    border: `1px solid ${theme.palette.searchBorder.main}`
                  }}
                  className='  w-full'
                >
                  <CardContent>
                    <div className='flex gap-2 items-center'>
                      <div className='bg-[#67BBA261] flex items-center justify-center rounded-full w-[41.67px] h-[41.67px]'>
                        {items.icon}
                      </div>
                      <p style={{ color: theme.palette.headings.main }}>{items.title}</p>
                    </div>
                    <span style={{ color: `${items.color}`, fontSize: 46.22 }}  >{items.count}</span>
                  </CardContent>
                </div>
              )
            })}
          </div>
          <div className=' flex-1 flex flex-col max-w-[89vw]'
            style={{
              boxShadow: '4.300174713134766px 4.300174713134766px 4.300174713134766px 0px #0000000F',
              backgroundColor: theme.palette.bg.adminApp,
              border: `1px solid ${theme.palette.searchBorder.main}`
            }}
          >
            <h3 style={{ color: theme.palette.headings.main }} className='mt-4 ml-4 text-[#616161] font-semibold mb-2'>Top 10 faculty</h3>

            {/* <div style={{ maxHeight: '20vh', overflowY: 'auto', backgroundColor:'red' }}> */}

            <DataGrid

              loading={loading}
              getRowId={(row) => row._id}
              // autoHeight={true}
              rows={topFaculty}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },

              }}
              hideFooter
              hideFooterPagination
              hideFooterSelectedRowCount
              disableColumnMenu

              disableColumnSelector
              disableColumnResize='true'
              sortable
              pageSizeOptions={[5, 10, 15, 20, 30, 50, 75, 100]}

              sx={{
                border: 0,
                // backgroundColor: '#F9FAFF',
                backgroundColor: theme.palette.bg.adminApp,
                border: `1px solid transparent`,
                flex: 1,
                '&>.css-wop1k0-MuiDataGrid-footerContainer': {
                  '& .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar': {
                    color: theme.palette.headings.table,
                  },

                  '& .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar': {
                    color: theme.palette.headings.table,
                  },
                  '& .css-rtrcn9-MuiTablePagination-root .MuiTablePagination-selectLabel ': {
                    color: theme.palette.headings.table,
                  },
                  '& .css-rtrcn9-MuiTablePagination-root .MuiTablePagination-input': {
                    color: theme.palette.headings.table,
                  },
                  '& .css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon': {
                    color: theme.palette.headings.table,
                  },
                  '& .css-i4bv87-MuiSvgIcon-root': {
                    color: theme.palette.headings.table,
                  },
                  '& .css-levciy-MuiTablePagination-displayedRows': {
                    color: theme.palette.headings.table,
                  },
                  // display: '',
                  // justifyContent: 'flex-start',
                  borderTop: `1px solid  transparent`,
                  color: theme.palette.headings.table,
                },
                '& .css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root': {
                  color: theme.palette.headings.table,
                },
                '&>.MuiDataGrid-main': {
                  '&>.MuiDataGrid-columnHeaders': {

                    // backgroundColor: '#55555512',
                    // backgroundColor: theme.palette.bg.tableHeader,
                    color: theme.palette.headings.table,
                    fontWeight: '600',
                    fontSize: "14px",
                    borderBottom: `1px solid transparent`,
                    justifyContent: 'space-between',
                    borderRadius: '0px'
                  },
                  '&>.MuiDataGrid-columnHeaderTitleContainer': {
                    border: 'none',
                  },



                  '& div div div div >.MuiDataGrid-cell': {
                    border: 'none',
                    fontSize: 14,
                    // flexGrow: 1,
                  },
                  '& .MuiDataGrid-row': {
                    cursor: 'pointer',
                    marginTop: 1,
                    color: theme.palette.headings.table
                  },

                },
              }}

            />
            {/* </div>/ */}
          </div>

        </div>
        <div className=' gap-5 w-full   flex flex-col'
          style={{
            boxShadow: '4.300174713134766px 4.300174713134766px 4.300174713134766px 0px #0000000F',
            backgroundColor: theme.palette.bg.adminApp,
            border: `1px solid ${theme.palette.searchBorder.main}`
          }}
        >
          <div className=' w-full   flex flex-col '>
            <h2 style={{ color: theme.palette.headings.main }} className='themeFont pt-5 pl-3'>Doubts asked by student</h2>
            <div className='flex flex-1 flex-col-reverse gap-4 p-3 lg:flex-row'>
              <div className=' max-w-[80vw]  w-full  lg:w-2/3'>

                <Heatmap
                  orientation="vertical"

                  data={askedData.hourlyDoubtOfEveryDay}
                  xAxisLabels={askedData.dateArray}
                  yAxisLabels={askedData.hoursArray}
                />

              </div>
              <div className='w-full lg:w-1/3'>
                <Map />
              </div>

            </div>
          </div>
          <div className=' w-full flex flex-col ' >
            <h2 style={{ color: theme.palette.headings.main }} className='themeFont font-[Lufga]  pt-5 pl-3'>Doubts cleared by faculty</h2>
            <div className='flex w-full flex-1 flex-col-reverse gap-4 p-3 lg:flex-row'>
              <div className='w-full  max-w-[80vw]  lg:w-2/3'>
                {/* <HeatMapTable className='' /> */}
                <Heatmap

                  orientation="vertical"

                  data={closedData.hourlyDoubtOfEveryDay}
                  xAxisLabels={closedData.dateArray}
                  yAxisLabels={closedData.hoursArray}
                />
              </div>
              <div className='.w-full lg:w-1/3'>
                <Map />
              </div>

            </div>
          </div>
        </div>
      </div>
    </div >
  )
}
