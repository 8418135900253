import { getTheme, updateSnackbar, getStatus } from "../Reducers/Reducer";

// Actions.js
export const asyncUpdateTheme = (data) => async (dispatch, getState) => {
    try {
        dispatch(getTheme(data));
    } catch (e) {
        console.error(`ERROR IN UPDATING THEME --- ${e}`);
    }
};

export const asyngetStatus = (data) => async (dispatch, getState) => {
    try {
        dispatch(getStatus(data));
    } catch (e) {
        console.log(`ERROR IN UPDATE MAX VOLUME OI --- ${e}`)
    }
}

export const showToast = (data) => async (dispatch, getState) => {
    try {
        dispatch(updateSnackbar(data))
    } catch (e) {
        console.log('ERROR IN ACTION.JSX UPDATETOAST')
    }
}


