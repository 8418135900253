import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import Drawer from "@mui/material/Drawer";
import { Box, Button, Switch, Avatar } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DashboardIcon from "@mui/icons-material/Dashboard";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import LogoutIcon from "@mui/icons-material/Logout";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { useLocation } from "react-router-dom";
import LightModeIcon from "@mui/icons-material/LightMode";
import QuizIcon from "@mui/icons-material/Quiz";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Outlet, useNavigate } from "react-router-dom";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import Stack from "@mui/material/Stack";
import { baseUrl, get, patch, post } from '../../Services/api-services'
import EmailIcon from "@mui/icons-material/Email";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { adminAuthState } from "../../Services/RecoilService";
import { useRecoilState } from "recoil";
import { useTheme } from '@mui/system';
import SwitchButton from "../../Student/StudentComponents/SwitchButton";


const drawerWidth = 240;



export default function AdminHome(props) {
  const theme = useTheme()

  const { windowAdmin } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [appbarHeight, setAppbarHeight] = React.useState();
  const [studentProfile, setStudentProfile] = React.useState();
  const [admin, setAdmin] = React.useState(null);
  const [dOpen, setDopen] = React.useState(true);
  const adminPic = React.useRef(null);
  const appbar = React.useRef(null);
  const location = useLocation();
  const path = location.pathname.split("/");
  const [authState, setAuthState] = useRecoilState(adminAuthState)
  // const adminId = '656ab9973bacb21be96e0ad6'
  const adminId = location?.state?.adminId


  const role = path[path.length - 1];
  // const uploadProfile=async()=>{
  //   const res =await post(`/uploads/{images}/upload`)
  // }
  const getAdminData = async () => {
    try {
      const res = await get(`admins/${authState?.userId}`)

      if (res?.statusCode === 200) {

        setAdmin(res?.data)

      }
    } catch (e) {
      console.log(e);
    }

  }

  React.useEffect(() => {
    setAppbarHeight(appbar.current.clientHeight);
    getAdminData()


  }, [appbar]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const navigate = useNavigate();
  function handleNavigate(link) {
    const navText = link.toLowerCase().replace(/\s+/g, "");
    navigate(`/admin/home/${navText}`);
  }

  const uploadImage = async (e) => {


    let formData = new FormData()

    formData.append('file', e.target.files[0])


    const res = await post(`uploads/staff/upload`, formData)
    if (res?.statusCode === 200) {



      const ress = await patch(`Admins/${authState?.userId}`, { image: res?.data?.result?.files?.file[0].name })
      getAdminData();
    }

  }

  const logoutAdmin = async () => {
    const res = await post('Admins/logout')

    if (res?.statusCode === 204) {
      localStorage.removeItem("studentData");
      window.location.replace("/admin")
      // navigate("/admin")

    }


  };

  const drawer = (

    <div style={{ height: '100%', padding: 0, backgroundColor: theme.palette.bg.admin }} >
      <Toolbar>

        <div className="flex justify-between pt-2">
          <div className="flex flex-col  justify-center bg-grey-lighter">
            <label className=" flex flex-col items-center w-20 h-20 bg-[grey] text-blue rounded-full shadow-lg tracking-wide uppercase border border-blue cursor-pointer overflow-hidden hover:bg-blue hover:text-white">
           
              <Avatar sx={{ width: '100%', height: '100%' }} alt={admin?.name} src={`${baseUrl}/uploads/staff/download/${admin?.image}`} />
              <input
                type="file"
                className="hidden"
                accept="image/*"
                // onChange={(e) => {
                //   // setStudentProfile(e.target.files[0]);
                //   uploadImage(e)
                // }}
                onChange={uploadImage}
                ref={adminPic}
              />
            </label>
            <div className="bg-[#fff]   rounded-full p-1 absolute top-12 left-20 cursor-pointer">
              <CameraAltOutlinedIcon
                onClick={() => adminPic.current.click()}
              />
            </div>
            <Typography variant="h6" className="text-[#FFFFFF] mt-24  font-[Lufgasemibold]" sx={{ marginTop: 3, fontWeight: 500 }}  >
              {admin?.name}
            </Typography>
            <Stack direction="row" alignItems="center" gap={1} size="small">
              <EmailIcon size="small" sx={{ color: "#FFFFFF", fontSize: 12, }} />
              <Typography
                size="small"
                sx={{ fontSize: 12, color: "#FFFFFF", }}
                variant="body1"
              >
                {admin?.email}
              </Typography>
            </Stack>
          </div>
          <div>
            <ChevronLeftOutlinedIcon
              onClick={() => { setDopen(false); handleDrawerToggle() }}
              // onClick={handleDrawerToggle}
              sx={{ color: "#FFFFFF", cursor: 'pointer', position: 'absolute', right: 0 }}
            />
          </div>
        </div>

      </Toolbar>
      <Divider sx={{ marginTop: 4, backgroundColor: "#FFFFFF2B" }} />
      <div className="flex justify-center mt-10">
        <List className="  justify-start items-center flex flex-col gap-10 ">
          {[
            {
              icon: <DashboardIcon />,
              title: "Dashboard",
            },
            {
              icon: <FormatListBulletedIcon />,
              title: "Faculty",
            },
            {
              icon: <PeopleAltIcon />,
              title: "Student",
            },
            {
              icon: <QuizIcon />,
              title: "Doubts",
            },
          ].map((items, index) => (
            <ListItem
              key={items}
              disableElevation={true}

              onClick={() => {
                handleNavigate(items.title);
              }}
            >
              <div className={path[3] === items.title?.toLowerCase() ? "flex gap-8 items-center cursor-pointer font-[Lufga] w-44 rounded p-2  px-7  bg-[#FFFFFF] transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110  duration-300 "
                : "flex gap-8 items-center  cursor-pointer w-44 rounded p-2 px-7  text-[#FFFFFF] transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110  duration-300 "}>

                {items.icon}

                <Typography >
                  {items.title}
                </Typography>
              </div>
            </ListItem>


            // <ListItem
            //   key={items}
            //   disablePadding
            //   disableElevation={true}

            //   onClick={() => {
            //     handleNavigate(items.title);
            //   }}
            //   className={path[3] === items.title?.toLowerCase() ? " h-10 w-48 px-3  rounded-sm flex  transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110  duration-300 bg-[#FFFFFF] " : "h-10   rounded-sm flex  w-80 transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110  duration-300 "}

            // >
            //   <div className={path[3] === items.title?.toLowerCase() ? "flex hover:text-lg  items-center gap-4   text-[#555555] cursor-pointer  justify-center" : "flex hover:text-lg  items-center gap-4 text-[#FFFFFF] cursor-pointer  justify-center"}>
            //     {items.icon}
            //     <Typography>
            //       {items.title}
            //     </Typography>
            //   </div>
            // </ListItem>
          ))}
        </List>
      </div>
    </div>
  );

  // Remove this const when copying and pasting into your project.
  const container =
    windowAdmin !== undefined ? () => windowAdmin().document.body : undefined;
  return (
    <Box className="flex h-screen px-0">
      <CssBaseline sx={{ paddingX: 0 }} />
      <AppBar
        elevation={0}
        ref={appbar}
        position="fixed"
        sx={{
          width: {
            sx: dOpen ? `calc(100% - ${drawerWidth}px)` : "100%",
            // border: "0px, 0px, 1px, 0px",
            // borderColor: "#E2E2E2",
          },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: theme.palette.bg.adminApp,
          // border: 1,
          borderBottom: `1px solid ${theme.palette.searchBorder.main}`
        }}

      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            {/* <MenuIcon color="primary" /> */}
            <MenuOpenIcon sx={{ color: theme.palette.headings.main }} onClick={() => setDopen(true)} />



          </IconButton>
          <div className=" w-full  flex gap-4 justify-between items-center ">
            {!dOpen ? <IconButton> <MenuOpenIcon sx={{ color: theme.palette.headings.main }} onClick={() => setDopen(true)} /> </IconButton> : <div></div>}


            <div className="flex items-center gap-8">
              <div className="flex gap-1 items-center ">
                <div className='flex items-center gap-2'>
                  <LightModeIcon
                    sx={{
                      color: '#FFCE31'
                    }}
                  />
                  <small style={{ color: theme.palette.headings.mode }} className='font-normal text-xs'>Light mode</small>
                  <SwitchButton />
                </div>
              </div>
              <Box
                sx={{
                  boxShadow: 3,

                  bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                  color: (theme) =>
                    theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                  p: 1,

                  borderRadius: 2,
                  textAlign: 'center',
                  fontSize: '0.875rem',
                  fontWeight: '700',
                }}
                className='hidden lg:block'
                onClick={logoutAdmin}
              >
                <LogoutIcon sx={{ color: 'red', cursor: 'pointer' }} />

              </Box>
              {/* <div className="bg-[#FFFFFF] p-2 shadow-2xl" sx={{boxShadow: '4.300174713134766px 4.300174713134766px 4.300174713134766px 0px #0000000F' }} onClick={logoutAdmin}>
              </div> */}
            </div>

            {/* <div className='flex gap-2  items-center'>
              <div>
                <p className='font-bold text-[#4B4B4B]'> Sanskriti Moolchandani</p>
                <span className="font-montserrat font-light text-[#4B4B4B] text-xs"> <PhoneIcon /> +91 9824893475</span>
              </div>
              <div >   <Avatar />    </div>

            </div> */}
          </div>
        </Toolbar>
      </AppBar>

      <Box
        component="nav"
        sx={{
          display: dOpen ? "block" : "none",
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
        }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              paddingX: 0,
              width: drawerWidth,
            },
            '& .css-12i7wg6-MuiPaper-root-MuiDrawer-paper': {
              borderRight: 'none',
            }
          }}
          open={false}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        className="flex flex-1  flex-col "
        sx={{
          flexGrow: 1,

          width: { sm: `calc(100% - ${drawerWidth}px)` },
          height: { sm: `calc(100%- ${appbarHeight}px)` },
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}
AdminHome.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  windowAdmin: PropTypes.func,
};
