import * as React from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import { currentTheme } from '../../Services/RecoilService';
import { useRecoilState } from 'recoil';


const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 12,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                // backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff'   ,
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 9,
        height: 9,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));


const SwitchButton = () => {
    const [checked, setChecked] = React.useState(false);
    const [theme, setTheme] = useRecoilState(currentTheme)

    React.useEffect(() => {

        if (theme == 'dark') {
            setChecked(true);
        }

    }, [])

    const handleChange = () => {
        setChecked((prevChecked) => {
            const newChecked = !prevChecked;
            if (newChecked) {
                setTheme('dark')
            } else {
                setTheme('light')
            }
            return newChecked;
        });
    };


    return (
        <>
            <Stack direction="row" spacing={1} alignItems="center">
                {/* <AntSwitch size='small' inputProps={{ 'aria-label': 'ant design' }} /> */}
                <AntSwitch
                    size="small"
                    inputProps={{ 'aria-label': 'ant design' }}
                    checked={checked}
                    onChange={handleChange}
                />
            </Stack>
        </>
    )
}


export default SwitchButton