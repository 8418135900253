import React from 'react'
import AdminLogin from '../Admin/AdminLogin/AdminLogin'
import { Route, Routes } from 'react-router-dom'
import AdminHome from '../Admin/AdminHome/AdminHome'
import DashboardIndex from '../Admin/Dashboard/DashboardIndex'
import Dashboard from '../Admin/Dashboard/Dashboard'
import FacultyIndex from '../Admin/Faculty/FacultyIndex'
import Faculty from '../Admin/Faculty/Faculty'
import FacultyDetail from '../Admin/Faculty/FacultyDetail'
import Student from '../Admin/Students/Student'
import StudentIndex from '../Admin/Students/StudentIndex'
import StudentDetail from '../Admin/Students/StudentDetail'
import DoubtIndex from '../Admin/Doubts/DoubtIndex'
import Doute from '../Admin/Doubts/Doubt'
import Trial from '../Trial'
import Snackbar from '@mui/material/Snackbar';
import { useDispatch, useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';
import MuiAlert from '@mui/material/Alert';
import { asyngetStatus, showToast } from "../store/Actions/Action";
import AdminAuth from '../Admin/AdminAuth'
import TableComponent from '../Admin/TableComponent'
import ForgotPassword from '../Admin/AdminLogin/ForgotPassword'
import ResetPassword from '../Admin/AdminLogin/ResetPassword'

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function MainRoutes() {
  const dispatch = useDispatch();
  const { snackbar } = useSelector((state) => state.homeOption)

  const handleClose = (event, reason) => {
    dispatch(asyngetStatus({ open: false, message: '', duration: 2000, type: '' }))
  };

  return (

    <>
      <Routes>
        <Route path="/admin" >
          <Route path="" element={<AdminLogin />} />
          <Route path="login" element={<AdminLogin />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="reset-password" element={<ResetPassword />} />
        </Route>

        <Route path="/admin" element={<AdminAuth />} >

          <Route path="home" element={<AdminHome />} >
            {/* <Route path="table" element={<TableComponent />} /> */}
            <Route path="" element={<DashboardIndex />} >
              <Route path="" element={<Dashboard />} />
            </Route>

            <Route path="dashboard" element={<DashboardIndex />} >
              <Route path="" element={<Dashboard />} />
            </Route>

            <Route path='faculty' element={<FacultyIndex />}>
              <Route path='' element={<Faculty />} />
              <Route path='facultyDetail' element={<FacultyDetail />} />
            </Route>

            <Route path='student' element={<StudentIndex />}>
              <Route path='' element={<Student />} />
              <Route path='studentDetail' element={<StudentDetail />} />
            </Route>


            <Route path='doubts' element={<Doute />}>
              <Route path='' element={<Doute />} />
            </Route>

          </Route>
        </Route>

      </Routes >

      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar open={snackbar.open} autoHideDuration={snackbar.duration} onClose={handleClose}>
          <Alert onClose={handleClose} severity={snackbar.type} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  )
}
