import * as React from 'react';
import { createTheme } from "@mui/material/styles";
import { useSelector, useDispatch } from 'react-redux';


const lightTheme = createTheme({
  palette: {
    bg: {
      main: '#fff',
      secondary: '#F8F8F8',
      third: '#FFFFFF',
      chatInput: '#F2F2F2',
      dialog: '#FFF',
      button: '#FF581040',
      card: '#FFFFFF',
      chatHeadbg: '#98D2C533',
      studentChat: '#F7F7F7',
      admin: '#000000A6',
      adminApp: '#F8F8F8',
      tableBody: '#FFFFFF',
      tableHeader: '#55555512',
      tableHeadeB: '#E2E2E2'
    },
    headings: {
      main: '#1C1C1CC1',
      // secondary: '#464646',
      secondary: '#464646',
      mode: '#555555',
      table: '#4B4B4B',
      selected: '#4F4F4F6B'
    },
    disabled: {
      main: '#808080'
    },
    searchBg: {
      main: '#FFFFFF'
    },
    searchBorder: {
      main: '#EBEBEB',
      secondary: '#E2E2E2'
    },
    askDoubt: {
      main: '#CBCBCB'
    },
    inputBorder: {
      main: '#D3D3D3',
      card: '#3636361F',
    },
    inputColor: {
      main: '#1C1C1C'
    },
    inputText: {
      main: '#565656',
    },
    IconColor: {
      main: '#FFFFFF',
      secondary: '#98D2C5',
    },
    IconBg: {
      main: '#98D2C5',
      secondary: '#FFFFFF'
    },
    IconStroke: {
      main: '#7BB5A8',
      secondary: '#EBEBEB'
    },
    Text: {
      main: '#464646',
      secondary: '#464646',
      third: '#B1AEAE',
      fourth: '#464646',
      fileHeading: '#464646'
    },
    primary: {
      main: "#262626",
    },
    secondary: {
      main: "#fff"
    },
    error: {
      main: "#e53935"
    },
    white: {
      main: '#fff'
    },
    buy: {
      main: "#00A880C7"
    },
    sell: {
      main: "#EB5B3C"
    },

    tealGreen: {
      main: '#98D2C5',
      secondary: 'red'
    },
    // darkGray: {
    //   main: '#555555 '
    // },
    darkGray: '#555555',
    lightGray: {
      main: '#9D9D9D'
    },
    gray: {
      main: "#A4A4A4"
    }

  },
});


const darkTheme = createTheme({
  palette: {
    bg: {
      main: '#1D1C1C',
      secondary: '#171717',
      third: '#1D1C1C',
      chatInput: '#111111',
      dialog: '#171717',
      button: '#FF581040',
      card: '#282727',
      chatHeadbg: '#98D2C533',
      studentChat: '#171717',
      admin: '#000000',
      adminApp: '#171717',
      tableBody: '#1D1C1C',
      tableHeader: '#282727',
      tableHeadeB: '#3C3C3C'
    },
    headings: {
      main: '#FFFFFF',
      secondary: '#FFFFFF',
      mode: '#FFFFFF',
      table: '#FFFFFF',
      selected: '#FFFFFF6B'
    },
    searchBg: {
      main: '#282727'
    },
    searchBorder: {
      main: '#EBEBEB24',
      secondary: '#3C3C3C'
    },
    askDoubt: {
      main: '#3e3e3e'
    },
    disabled: {
      main: '#808080'
    },
    inputBorder: {
      main: '#D3D3D3',
      card: '#F1F1F11F',
    },
    inputColor: {
      main: 'white'
    },
    inputText: {
      main: '#A8A8A8'
    },
    searchBorder: {
      main: '#EBEBEB24'
    },
    IconColor: {
      main: '#98D2C5',
      secondary: '#98D2C5'
    },
    IconBg: {
      main: '#FFFFFF',
      secondary: '#282727'
    },
    IconStroke: {
      main: '#7BB5A8',
      secondary: '#EBEBEB24'
    },
    Text: {
      main: '#FFFFFF',
      secondary: '#AAAAAA',
      third: '#B1AEAE',
      fourth: '#B3B3B3',
      fileHeading: '#C2C2C2',
    },
    primary: {
      main: "#262626",
    },
    secondary: {
      main: "#fff"
    },
    error: {
      main: "#e53935"
    },
    white: {
      main: '#fff'
    },
    buy: {
      main: "#00A880C7"
    },
    sell: {
      main: "#EB5B3C"
    },
    tealGreen: {
      main: 'red'
    },
    darkGray: {
      main: '#555555 '
    },
    lightGray: {
      main: '#9D9D9D '
    },
    gray: {
      main: "#A4A4A4"
    }

  },
});


export { lightTheme }

export { darkTheme }


