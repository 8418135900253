import React from 'react'
import { Outlet } from 'react-router-dom'

export default function FacultyIndex() {
  return (
    <div className='flex flex-1 flex-col'>
      <Outlet/>
    </div>
  )
}
