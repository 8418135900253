import * as React from 'react';


// import Slider, { sliderClasses } from '@mui/material/Slider';

// function valueText(value) {
//   return `${value}°C`;
// }
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

function valuetext(value) {
  return `${value}°C`;
}



export default function Rating({ setRating, filterData, setFilterData }) {


  const [value, setValue] = React.useState(filterData?.rating);


  const handleChange = (event, newValue) => {
    // setValue(newValue);
    // console.log(newValue);
    setFilterData({ ...filterData, rating: newValue });
  };


  return (




    <Box sx={{ width: 250, marginLeft: 2, borderBottom: 'none' }} >

      <Slider
        sx={{ color: '#7BB5A8' }}
        getAriaLabel={() => 'Temperature range'}
        value={filterData?.rating}
        min={0}
        max={10}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
      />
    </Box>
  );
}