import React from 'react'
import { useTheme } from '@mui/system';
import OtpInput from 'react-otp-input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField';
import { RightIcon, LoginUser, PhoneIcon, RightArrow } from '../StudentComponents/Icons';
import InputAdornment from '@mui/material/InputAdornment';
import { Button, styled } from "@mui/material"
import { get, post } from '../../Services/api-services';
import { currentRole, studentAuthState } from '../../Services/RecoilService';
import { useRecoilState } from 'recoil';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';
import { mobileRegex, otpRegex } from '../../ValidationsRegex';
import { showToast } from '../../store/Actions/Action';
import { useDispatch } from 'react-redux';
import OutlinedInput from '@mui/material/OutlinedInput';


const Loginimg = require('../../Assets/Images/loginpageImg.png')
const LoginpageImg = require('../../Assets/Images/loginpageImg.png')
const AptLogo = require('../../Assets/Images/aptlogo.png')





const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const [otp, setOtp] = React.useState('');
  const [age, setAge] = React.useState('');
  const [mobile, setmobile] = React.useState('')
  const [isOtp, setIsOtp] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [authState, setAuthState] = useRecoilState(studentAuthState)
  const [Role, setRole] = useRecoilState(currentRole)
  const [timer, setTimer] = React.useState(30);
  const [isTimerRunning, setIsTimerRunning] = React.useState(false);
  const [countryCode, setcountryCode] = React.useState(null)
  const [phoneCode, setPhoneCode] = React.useState('');
  const [countryPhoneLength, setCountryPhoneLength] = React.useState('');
  const [isValidMobile, setIsValidMobile] = React.useState(false);
  const [selectedCoutry, setselectedCoutry] = React.useState(null)


  const MobileSendOtpImg = require('../../Assets/Images/MobileSendOtpImg.png')
  const EnterOTpMobileImg = require('../../Assets/Images/EnterOTPMobileImg.png')
  const AptLogo = require('../../Assets/Images/aptlogo.png')

  const handleChange = (event) => {

    const selectedPhoneCode = event.target.value;
    const selectedCountry = countryCode.find(country => country.phoneCode === selectedPhoneCode);
    setselectedCoutry(selectedCountry)
    // Update the state for phone code
    setPhoneCode(selectedPhoneCode);

    // Update the state for PhoneLenmgth
    setCountryPhoneLength(selectedCountry?.phoneLength);

    if (mobile?.length === selectedCountry?.phoneLength) {
      setIsValidMobile(true)
    } else {
      setIsValidMobile(false)
    }

  };

  React.useEffect(() => {
    if (authState?.id) {
      navigate('/student/home')
    }
  }, [])

  React.useEffect(() => {
    getCountreis();
  }, [])



  // Effect to run the timer when isTimerRunning changes
  React.useEffect(() => {
    let interval;

    // Start the timer
    if (isTimerRunning) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    // Clear the interval when the component unmounts or timer reaches 0
    return () => {
      clearInterval(interval);
    };
  }, [isTimerRunning]);


  const studentLogin = async () => {
    try {
      setIsLoading(true)
      const res = await post('cases/sendOTP', { mobile: mobile, country: selectedCoutry?.id })
      setIsLoading(false);
      setTimer(30);
      // Start the timer
      setIsTimerRunning(true);

      if (res?.data?.status === 200) {
        const _snackbar = {
          message: res?.data?.message,
          open: true,
          duration: 3000,
          type: 'success'
        };

        dispatch(showToast(_snackbar))

        setIsOtp(true);
      } else {
        const _snackbar = {
          message: res?.data?.error,
          open: true,
          duration: 3000,
          type: 'error'
        };

        dispatch(showToast(_snackbar))
      }
    } catch (e) {
      setIsLoading(false)
      let response = e?.response?.data?.error
      console.log(response);
      const _snackbar = {
        message: response.message,
        open: true,
        duration: 3000,
        type: response.status
      };
      console.log(_snackbar)
      dispatch(showToast(_snackbar))
      console.log(`ERROR WHILE SENDING OTP --- ${e}`)
    }
  }

  const varifyOtp = async () => {
    try {
      setIsLoading(true)
      const res = await post('cases/verifyOTP', { otp: otp, mobile: mobile })
      console.log(res);
      setIsLoading(false)
      if (res?.data?.status === 200) {
        setIsOtp(true);
        const _snackbar = {
          message: res?.data?.message,
          open: true,
          duration: 3000,
          type: 'success'
        };

        dispatch(showToast(_snackbar))
        setAuthState(res?.data?.data)
        setRole('student');
        navigate('/student/home')


      } else {
        const _snackbar = {
          message: 'Invalid OTP',
          open: true,
          duration: 3000,
          type: 'error'
        };

        dispatch(showToast(_snackbar))
      }

    } catch (e) {
      setIsLoading(false)
      let response = e?.response?.data
      const _snackbar = {
        message: response.message,
        open: true,
        duration: 3000,
        type: response.status
      };
      console.log(_snackbar)
      dispatch(showToast(_snackbar))
      console.log(`ERROR WHILE MATCHING OTP --- ${e}`)
    }
  }

  React.useEffect(() => {
    if (authState?.data?.id) {
      navigate('/student/home')
    }
  }, [])


  const getCountreis = async () => {
    try {
      const res = await post('countries/getCountry');
      setcountryCode(res?.data?.data);
      // Find the country with code '91'
      const indiaCountry = res?.data?.data.find(country => country?.phoneCode === "91");
      // setPhoneCode(indiaCountry?.phoneCode)
      setPhoneCode(indiaCountry?.phoneCode)
      setselectedCoutry(indiaCountry)
      setCountryPhoneLength(indiaCountry?.phoneLength)

      // If '91' country is found, store its details in a separate state
      if (indiaCountry) {
        // setIndiaCountry(indiaCountry);
      }
    } catch (e) {
      console.log(e);
    }
  };


  return (
    <>
      {/* STUDENT LOGIN MOBILE VIEW */}
      {isOtp === true ? (
        <div className='w-full h-screen flex flex-col items-center lg:hidden  overflow-hidden'>
          {/* HEADER PART */}
          <div className='w-full bg-[#d3fff55c] flex flex-col items-center gap-7 py-16 px-3'>
            <div className='fixed top-5 left-5'>
              <img className='w-14' src={AptLogo} alt="AptLogo" />
            </div>
            <div className='w-full flex items-center justify-center'>
              <div onClick={() => setIsOtp(false)} className='fixed cursor-pointer top-16 left-8'>
                <RightArrow />
              </div>
              <img className='w-1/4 object-contain' src={EnterOTpMobileImg} alt="EnterOTpMobileImg" />
            </div>
            <h1 style={{ color: theme.palette.darkGray }} className='font-semibold text-[2.5vmax]'>OTP</h1>
            <p style={{ color: theme.palette.lightGray.main }} className='font-[Lufga] font-medium text-center leading-none text-[2vmax] w-[90%]'>Please enter the OTP sent to your <br /> mobile number</p>
          </div>
          {/* INPUT PART */}
          <div className='w-full flex flex-1 flex-col items-center justify-between py-20'>
            <div className='w-full flex items-center py-8 justify-center'>
              <OtpInput
                value={otp}
                onChange={setOtp}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    varifyOtp();
                  }
                }}
                inputType='number'
                shouldAutoFocus={false}
                helperText={otp?.length > 0 && !otpRegex?.test(otp) ? "Please Enter Valid OTP" : ""}
                error={otp?.length > 0 && !otpRegex?.test(otp)}
                numInputs={6}
                // containerStyle='outline'
                separator=''
                renderSeparator={<span> </span>}
                renderInput={(props) => <input {...props} />}
                inputStyle={{
                  width: '45px',
                  height: '50px',
                  margin: '0 8px',
                  borderRadius: '4px',
                  fontSize: '16px',
                  textAlign: 'center',
                  fontWeight: '600',
                  outline: 'none',
                  background: '#55555526',
                  color: '#555555'
                }}
              />
            </div>
            <div className='w-full flex flex-col items-center gap-16'>
              <div className='w-full flex flex-col items-center gap-2'>
                <p style={{ color: theme.palette.lightGray.main }} className='font-[Lufga] font-medium tracking-wide text-[1.5vmax]'>Didn’t receive OTP?</p>

                <Button
                  onClick={(e) => {
                    studentLogin();
                  }}
                  disabled={timer > 0}
                  sx={{
                    color: theme.palette.tealGreen.main,
                    // borderBottom: `1px solid  ${theme.palette.tealGreen.main}`,
                    borderRadius: 0
                  }}
                >
                  <h6
                    style={{ color: theme.palette.darkGray }}
                    className='fint-[Lufga] capitalize font-semibold text-[1.4vmax] underline leading-5'
                  >
                    {timer > 0 ? `Resend OTP in ${timer} seconds` : 'Resend OTP'}
                  </h6>
                </Button>
              </div>
              <div className='w-full flex items-center justify-center'>
                <LoadingButton
                  loading={isLoading}
                  sx={{
                    padding: '1.7vmax 0', boxShadow: 'none', backgroundColor: '#98D2C5', borderRadius: '1vw', width: '80%', '&:hover': {
                      backgroundColor: '#98D2C5',
                      boxShadow: 'none'
                      // pointerEvents: 'none',  // Disable pointer events on hover
                    },
                  }}
                  onClick={varifyOtp}
                  fullWidth
                  disabled={
                    !(otpRegex?.test(otp))
                  }
                  variant="contained"
                  size="small"
                >
                  Verify OTP
                </LoadingButton>
              </div>
            </div>
          </div>

        </div>
      ) : (
        <div className='w-full h-screen flex flex-col items-center lg:hidden  overflow-hidden'>

          {/* HEADER PART */}

          <div className='w-full bg-[#98D2C55C] flex flex-col items-center gap-7 py-16 px-3'>
            <div className='fixed top-5 left-5'>
              <img className='w-14' src={AptLogo} alt="AptLogo" />
            </div>
            <div className='w-full flex items-center justify-center'>
              <img className='w-1/4 object-contain' src={MobileSendOtpImg} alt="MobileSendOtpImg" />
            </div>
            <h1 style={{ color: theme.palette.darkGray }} className='font-semibold text-[2.5vmax]'>Mobile Number</h1>
            <p style={{ color: theme.palette.lightGray.main }} className='font-[Lufga] font-medium text-center leading-none text-[2vmax] w-[90%]'>We need to send OTP to authenticate your number</p>
          </div>

          {/* INPUT PART */}

          <div className='w-full flex-1 flex flex-col items-center justify-between py-16'>
            <div className='flex w-full px-3 items-start py-10 gap-2'>

              <div className='w-full '>

                <TextField
                  id="standard-basic"
                  placeholder="Enter Mobile Number"
                  helperText={
                    mobile?.length > 0 && !isValidMobile
                      // !mobileRegex?.test(mobile)
                      ? `mobile no. should contains ${countryPhoneLength} Digit`
                      : ""
                  }
                  error={
                    mobile?.length > 0 && !isValidMobile
                      // !mobileRegex?.test(mobile))
                      ? `mobile no. should contains ${countryPhoneLength} Digit`
                      : ""
                  }
                  InputProps={{
                    startAdornment: (
                      <div className=''>
                        <FormControl variant="standard" size='small' sx={{ minWidth: 60 }}>
                          <Select
                            displayEmpty
                            value={phoneCode}
                            onChange={handleChange}
                            // input={<OutlinedInput />}
                            // MenuProps={MenuProps}
                            inputProps={{ 'aria-label': 'Without label' }}
                          >
                            <MenuItem disabled value="">
                              <em >
                                <h6 className='text-lg'>+91</h6>
                              </em>
                            </MenuItem>
                            {countryCode?.map((country, index) => (
                              <MenuItem
                                key={index}
                                value={country?.phoneCode}
                              // style={getStyles(name, personName, theme)}
                              >
                                <h6 className='text-lg'>+{country?.phoneCode}</h6>
                              </MenuItem>
                            ))}
                          </Select>


                        </FormControl>
                      </div>
                    ),
                  }}
                  variant="standard"
                  fullWidth
                  type="number"
                  value={mobile}
                  onChange={(e) => {
                    setmobile(e?.target?.value);
                    setIsValidMobile(() =>
                      e?.target?.value?.length === countryPhoneLength
                        ? true
                        : false
                    );
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      studentLogin();
                    }
                  }}

                  sx={{
                    "& .MuiInputBase-root": {
                      color: "#555555",
                      fontWeight: "700",
                      [theme.breakpoints.down("sm")]: {
                        fontSize: "2vmax", // Font size for large screens
                      },
                      [theme.breakpoints.up("lg")]: {
                        fontSize: ".9vmax", // Font size for large screens
                      },
                    },
                  }}

                />

              </div>
            </div>
            <div className='w-full flex items-center justify-center'>
              <LoadingButton
                loading={isLoading}
                sx={{
                  padding: '1.7vmax 0', boxShadow: 'none', backgroundColor: '#98D2C5', borderRadius: '1vw', width: '80%', '&:hover': {
                    backgroundColor: '#98D2C5',
                    boxShadow: 'none'
                    // pointerEvents: 'none',  // Disable pointer events on hover
                  },
                }}
                onClick={studentLogin}
                fullWidth
                disabled={
                  !(mobileRegex.test(mobile))
                }
                variant="contained"
                size="small"
              >
                GET OTP
              </LoadingButton>
            </div>
          </div>

        </div >
      )}

      {/* STUDENT LOGIN DEKSTOP VIEW */}

      < div className='w-full relative h-screen  hidden lg:flex bg-[#F8F8F8]'>
        <div className='w-full flex items-start flex-col h-20 fixed px-20 py-2 top-0 z-10'>
          <div className='h-full flex flex-col items-center'>
            <img className='h-full' src={AptLogo} alt="AptLogo" />
            <h6 className='font-medium text-[#686464] '>Student doubt panel</h6>
          </div>
        </div>
        <div className='w-1/2  h-full  left-0 z-0'>
          <img className='w-full h-full object-cover' src={Loginimg} alt="Loginimg" />
        </div>
        <div className='w-1/2 h-full relative z-50 flex'>
          <div className='w-full h-full flex items-center justify-center'>
            <div className='w-1/2 flex items-center gap-14 flex-col'>
              <div className='flex flex-col items-center gap-2'>
                <LoginUser />
                <h1 style={{ color: theme.palette.darkGray }} className='text-[1.4vmax] font-semibold tracking-wider'>WELCOME</h1>
              </div>
              {isOtp && isOtp === true ? (
                <>
                  {/* MOBILE CONFIRM OTP FIELD */}
                  <div className='w-full flex items-start gap-12 flex-col'>
                    <div className='w-full  flex items-start gap-2 flex-col'>
                      <div className='w-full flex items-center justify-between py-4'>
                        <div onClick={() => setIsOtp(false)} className='flex cursor-pointer items-center gap-2'>
                          <RightArrow />
                          <h6 style={{ color: theme.palette.lightGray.main }} className='font-medium'>OTP</h6>
                        </div>
                        <h6 style={{ color: theme.palette.lightGray.main }} className='font-normal'>{timer > 0 ? `00:${timer} ` : ''}</h6>
                      </div>

                      <div className='w-full'>
                        <TextField
                          id="standard-basic"
                          placeholder="Enter OTP"
                          helperText={otp?.length > 0 && !otpRegex?.test(otp) ? "Please Enter Valid OTP " : ""}
                          error={otp?.length > 0 && !otpRegex?.test(otp)}
                          variant="standard"
                          fullWidth
                          type='text'
                          value={otp}
                          onChange={(e) => setOtp(e?.target?.value)}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              varifyOtp();
                            }
                          }}
                          sx={{
                            '& .MuiInputBase-root': {
                              color: '#555555',
                              fontWeight: '700',
                              [theme.breakpoints.down('sm')]: {
                                fontSize: '1.5vmax', // Font size for large screens
                              },
                              [theme.breakpoints.up('lg')]: {
                                fontSize: '.9vmax', // Font size for large screens
                              },
                            },
                          }}

                        />
                      </div>

                      <div className='w-full flex cursor-pointer items-center justify-end py-2'>
                        <Button
                          // variant='outlined'
                          onClick={(e) => {
                            studentLogin();
                            // handleResendOtp();
                          }}
                          disabled={timer > 0}
                          sx={{
                            color: theme.palette.tealGreen.main,
                            borderBottom: `1px solid  ${theme.palette.tealGreen.main}`,
                            borderRadius: 0
                          }}
                          className=' font-medium tracking-wide text-[.9vmax] underline leading-5'
                        >
                          Resend OTP
                          {/* {timer > 0 ? `00:${timer} ` : ''} */}
                        </Button>
                      </div>
                    </div>
                    <div className='w-full flex items-center justify-center'>
                      <LoadingButton
                        loading={isLoading}
                        sx={{
                          padding: '.6vmax 0', boxShadow: 'none', backgroundColor: '#98D2C5', borderRadius: '5vw', width: '80%', '&:hover': {
                            backgroundColor: '#98D2C5',
                            boxShadow: 'none'
                            // pointerEvents: 'none',  // Disable pointer events on hover
                          },
                        }}
                        onClick={varifyOtp}
                        fullWidth
                        disabled={
                          !(otpRegex?.test(otp))
                        }
                        variant="contained"
                        size="small"
                      >
                        Verify
                      </LoadingButton>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {/* MOBILE INPUT FIELD */}

                  < div className='w-full flex items-start gap-16 flex-col'>
                    <div className='w-full  flex items-start gap-2 flex-col'>
                      <h6 style={{ color: theme.palette.lightGray.main }} className='font-medium'>Mobile no.</h6>
                      <div className='flex w-full px-3 items-start py-10 gap-2'>

                        <div className='w-5/6 '>

                          <TextField
                            id="standard-basic"
                            placeholder="Enter Mobile Number"
                            helperText={
                              mobile?.length > 0 && !isValidMobile
                                // !mobileRegex?.test(mobile)
                                ? `mobile no. should contains ${countryPhoneLength} Digit`
                                : ""
                            }
                            error={
                              mobile?.length > 0 && !isValidMobile
                                // !mobileRegex?.test(mobile))
                                ? `mobile no. should contains ${countryPhoneLength} Digit`
                                : ""
                            }
                            InputProps={{
                              startAdornment: (
                                <div className=''>
                                  <FormControl variant="standard" size='small' sx={{ minWidth: 60 }}>
                                    <Select
                                      displayEmpty
                                      value={phoneCode}
                                      onChange={handleChange}
                                      // input={<OutlinedInput />}
                                      // MenuProps={MenuProps}
                                      inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                      <MenuItem disabled value={phoneCode}>
                                        <em>
                                          <h6 className='text-lg'>+{phoneCode}</h6>
                                        </em>
                                      </MenuItem>
                                      {countryCode?.map((country, index) => (
                                        <MenuItem
                                          key={index}
                                          value={country?.phoneCode}
                                        // style={getStyles(name, personName, theme)}
                                        >
                                          <h6 className='text-lg'>+{country?.phoneCode}</h6>
                                        </MenuItem>
                                      ))}
                                    </Select>


                                  </FormControl>
                                </div>
                              ),
                            }}
                            variant="standard"
                            fullWidth
                            type="number"
                            value={mobile}
                            onChange={(e) => {
                              setmobile(e?.target?.value);
                              setIsValidMobile(() =>
                                e?.target?.value?.length === countryPhoneLength
                                  ? true
                                  : false
                              );
                            }}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                studentLogin();
                              }
                            }}
                            sx={{
                              "& .MuiInputBase-root": {
                                color: "#555555",
                                fontWeight: "700",
                                [theme.breakpoints.down("sm")]: {
                                  fontSize: "1.5vmax", // Font size for large screens
                                },
                                [theme.breakpoints.up("lg")]: {
                                  fontSize: ".9vmax", // Font size for large screens
                                },
                              },
                            }}
                          />

                        </div>
                      </div>
                    </div>
                    <div className='w-full flex items-center justify-center'>
                      <LoadingButton
                        loading={isLoading}
                        sx={{
                          padding: '.6vmax 0',
                          boxShadow: 'none',
                          backgroundColor: '#98D2C5',
                          borderRadius: '5vw',
                          width: '80%',
                          '&:hover': {
                            backgroundColor: '#98D2C5',
                            boxShadow: 'none'
                          },
                        }}
                        onClick={studentLogin}
                        fullWidth
                        disabled={
                          // !(mobileRegex.test(mobile))
                          !isValidMobile
                        }
                        variant="contained"
                        size="small"
                      >
                        Send OTP
                      </LoadingButton>
                    </div>

                  </div>
                </>
              )}

            </div>
          </div>
        </div>
      </div >

    </>
  )
}

export default Login
