import React from 'react'
import { useTheme } from '@mui/system';
import { AttachmentIcon, AttachmentInputIcon, CameraIcon, CameraInputIcon, CrossIcon, DateArrow, DisLikeIcon, LikeIcon, MicIcon, MobileMedia, RightArrow, SendIcon } from '../StudentComponents/Icons';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import AudioRecoderComponent from '../StudentComponents/AudioRecoderComponent';
import SelectImages from '../StudentComponents/SelectImages';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import PreviewImaDialogue from '../StudentComponents/PreviewImageDialogue'
import { useLocation, useNavigate } from 'react-router-dom';
import { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

// CAMERA

import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { baseUrl, get, post, processFileName } from '../../Services/api-services';
import { currentDoubtId, currentRole, currentScreen } from '../../Services/RecoilService';
import { useRecoilState } from 'recoil';

const Document = require('../../Assets/Images/Document.png')
const NodoubtLightImg = require('../../Assets/Images/NoDoubtLightInmg.png')
const NoDoubtDarkImg = require('../../Assets/Images/NpDoubtDarkImg.png')


const FileImg = require('../../Assets/Images/FileImg.png')

const cameraStyles = {
    width: '100%', // Replace with your desired width
    height: '100%', // Replace with your desired height
    // Add any additional styles you want to apply

};



const ChatArea = (props) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const [isRecord, setIsRecord] = React.useState(false)
    const imageRef = React.useRef(null)
    const docsRef = React.useRef(null)
    const [isCamera, setIsCamera] = React.useState(false)
    const chatRef = React.useRef(null)
    const [currentMedia, setCurrentMedia] = React.useState(null)
    const [allMedia, setAllMedia] = React.useState([])
    const [openMedia, setOpenMedia] = React.useState(false)
    const [role, setRole] = useRecoilState(currentRole)
    const [isUploading, setIsUploading] = React.useState(false)
    const headRef = React.useRef(null);
    const divRef = React.useRef(null);
    const inpRef = React.useRef(null);
    const [chatHeight, setchatHeight] = React.useState(null)
    const audioRef = React.useRef([]);
    // MENU OPTION

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);



    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {
        if (window.innerWidth > 1024) {
        } else {
            // getDoubtDetails();
        }
    }, [])


    // GET DOUBT DETAILS 

    React.useEffect(() => {

        if (chatRef && chatRef?.current) {
            chatRef.current.scrollTop = chatRef?.current?.scrollHeight
        }
    }, [props?.doubtDets?.messages])

    // INPUT STYLE 

    const inputStyle = {
        '& .MuiInputBase-root': {
            // border: '1px solid transparent',
            // borderRadius: '4vw',
            [theme.breakpoints.down('sm')]: {
                fontSize: '2vmax', // Font size for large screens

            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '.8vmax', // Font size for large screens
            },
            color: theme.palette.inputText.main,
            backgroundColor: theme.palette.bg.chatInput,
        },
        '& label.Mui-focused': {
            color: 'lightGreen',
            border: '1px solid red'
        },
        "& fieldset": { border: 'none' },
        // , backgroundColor: '#F2F2F2'
        // "& fieldset": { backgroundColor: 'black' },

    }

    // FUNTION TO HANDLE TAKE PHOTO 

    function handleTakePhoto(dataUri) {

        // Convert the data URI to a Blob
        const blob = dataURItoBlob(dataUri);

        // Create FormData object and append the image data
        const formData = new FormData();
        formData.append('photo', blob, 'photo.jpg'); // 'photo' is the field name, you can adjust it as needed


        for (let pair of formData.entries()) {
            // console.log(pair[0], pair[1]);
        }

        // setImage(URL.createObjectURL(blob));
        props?.setImage((prevImageUrls) => [...prevImageUrls, blob]);
        props?.setImageType('camera')
        props?.setMediaType('image')
        setIsCamera(false)

    }

    // Function to convert data URI to Blob

    function dataURItoBlob(dataURI) {
        const byteString = atob(dataURI.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab], { type: 'image/jpeg' }); // Adjust the type accordingly
    }

    // REMOVE SELECT IMAGE FROM THE ARRAY

    const removeImageAtIndex = (indexToRemove) => {
        props?.setImage((prevImageUrls) =>
            prevImageUrls.filter((_, index) => index !== indexToRemove)
        );
    };

    const clickInput = (type) => {
        if (type === 'documents') {
            docsRef.current.click()
        } else {
            imageRef.current.click()
        }
    }

    function handleCameraError(error) {
        console.log('handleCameraError', error);
    }

    React.useEffect(() => {
        setchatHeight(divRef?.current?.offsetHeight - headRef?.current?.offsetHeight - inpRef?.current?.offsetHeight - 30)
    }, [headRef?.current]);

    const stopAndPlayAudio = (_index) => {
        try {
            for (let index = 0; index < props?.userMessages?.length; index++) {
                const id = `audio-${index}`;
                if (document.getElementById(id)) {
                    if (_index !== index)
                        document.getElementById(id)?.pause()
                }
            }
        }
        catch (e) {
            console.log(e)
        }
    }

    return (
        <>

            {props?.doubtDets ? (
                <div
                    ref={divRef}
                    className='w-full relative h-screen overflow-y-auto cursor-default'
                >
                    {/* HEADER */}
                    <div ref={headRef} style={{ backgroundColor: theme.palette.bg.main }} className='w-full '>

                        {(props?.role === 'faculty'  || props?.role === 'student') && props?.userStatus === false && (
                            <div className='w-full h-14 bg-[#FFE9E9] border-b border-[#FF3333] flex items-center justify-center'>
                                <h6 className='text-[#FF3333] text-sm text-center'>Admin has disabled you to use this panel, please ask admin to provide you  panel access!</h6>
                            </div>
                        )}

                        <div
                            className='w-full flex items-center justify-between  py-3 px-4 pt-7 border-b border-[#3636361F] '
                        >
                            <div className='flex flex-col items-start'>
                                {props?.role === 'student' ? (
                                    <h4 style={{ color: theme.palette.headings.main }} className='text-sm lg:text-sm'>{props?.doubtDets?.faculty?.name}</h4>
                                ) : props?.role === 'faculty' ? (
                                    <h4 style={{ color: theme.palette.headings.main }} className='text-sm lg:text-sm'>{props?.doubtDets?.student?.name}</h4>
                                ) : (
                                    <h4 style={{ color: theme.palette.headings.main }} className='text-sm lg:text-sm'>{props?.doubtDets?.student?.name}</h4>
                                )}

                                <h6 className='text-sm lg:text-sm text-[#E54C4C]'>{props?.doubtDets?.subject?.course?.name}</h6>
                                <p style={{ color: theme.palette.headings.main }} className='text-sm lg:text-xs'>{props?.doubtDets?.subject?.name}</p>
                            </div>
                            <div
                                onClick={(props?.selectMedia)}
                                className='flex items-center gap-2 lg:hidden'
                            >
                                <MobileMedia />
                                <DateArrow Color={theme.palette.headings.main} />
                            </div>
                        </div>

                        <div style={{ backgroundColor: theme.palette.bg.chatHeadbg }} className='px-4 w-full flex items-start gap-3 py-3 my-1'>
                            <div
                                onClick={() => {
                                    props?.backHome();
                                    props?.setDoubtDets('')
                                }}
                                className='block lg:hidden cursor-pointer'
                            >
                                <RightArrow />
                            </div>
                            <div className='w-full  '>
                                <h6
                                    style={{
                                        color: theme.palette.headings.secondary,
                                        wordBreak: 'break-word',
                                    }}
                                    className='text-sm lg:text-sm font-semibold'
                                >
                                    {props?.doubtDets?.title}
                                </h6>
                            </div>
                        </div>
                    </div>

                    {/* CHATAREA */}
                    {/* {screen} */}
                    {/* MWSSAGES AREA */}


                    <div
                        ref={chatRef}
                        style={{ maxHeight: chatHeight }}
                        className='w-full overflow-y-auto flex flex-col-reverse py-8'>
                        {props?.doubtDets?.messages && props?.userMessages?.map((message, index) => {
                            return (
                                <>
                                    {props?.role === 'student' ? (
                                        message?.sendFrom === 'student' ? (
                                            <div key={index} className='flex w-full items-center justify-end py-2 px-4'>
                                                {message?.type === 'text' ? (
                                                    <div className='max-w-[90%] flex-wrap overflow-hidden lg:max-w-[60%]  '>
                                                        <div style={{ backgroundColor: theme.palette.bg.studentChat }} className='w-full px-4 py-4 rounded'>
                                                            <p style={{ color: theme.palette.headings.secondary, wordBreak: 'break-word', }} className='text-sm lg:text-xs  break-words font-medium'>{message?.message}</p>
                                                            <p style={{ color: theme.palette.headings.secondary }} className='text-sm lg:text-xs font-medium'>{message?.read}</p>
                                                        </div>
                                                        <div className='py-2'>
                                                            <h6 className='text-sm lg:text-xs font-normal text-[#AAAAAA]'>
                                                                {new Date(message?.createdAt).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                ) : message?.type === 'image' ? (
                                                    <div className='flex w-full items-center justify-end py-2  px-0 lg:px-0'>
                                                        <div className=' w-9/12 lg:w-1/3 h-[40vh] lg:h-[30vh]'>
                                                            <div
                                                                onClick={() => {
                                                                    {
                                                                        setOpenMedia(true)
                                                                        setCurrentMedia(message)
                                                                        setAllMedia(props?.doubtDets?.messages);
                                                                    }
                                                                }}
                                                                style={{ backgroundColor: theme.palette.bg.studentChat }}
                                                                className='w-full h-full overflow-hidden rounded shadow border border-[#C7C7C7]'
                                                            >
                                                                <img className='w-full h-full  object-contain' src={`${baseUrl}uploads/images/download/${message?.message}`} alt={message?.message} />
                                                            </div>
                                                            <div className='py-2 w-full flex items-center justify-start'>
                                                                <h6 className='text-sm lg:text-xs font-normal text-[#AAAAAA]'>
                                                                    {new Date(message?.createdAt).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : message?.type === "document" ? (

                                                    <div className='w-[67%] lg:w-[32%] relative flex flex-col  items-start rounded '>
                                                        <div
                                                            onClick={() => {
                                                                window.open(`${baseUrl}uploads/documents/download/${message.message}`)
                                                            }}
                                                            className='w-full cursor-pointer lg:w-full relative flex h-12 items-start rounded bg-[#F1F1F1]'>
                                                            <div className='bg-[#98D2C5] flex items-center justify-center rounded-s w-[25%] h-full'>
                                                                <img src={FileImg} alt="FileImg" />
                                                            </div>
                                                            <div className='h-full w-[70%] flex items-center justify-center overflow-hidden'>
                                                                <h6 className='text-sm lg:text-xs whitespace-nowrap'>{processFileName(message.message, 6)}</h6>
                                                            </div>
                                                            {/* <div className='absolute top-[-10%] right-[-4%]'>
                                                       <CrossIcon   />
                                                </div> */}
                                                        </div>
                                                        <div className='py-2 w-full flex items-center justify-start'>
                                                            <h6 className='text-sm lg:text-xs font-normal text-[#AAAAAA]'>
                                                                {new Date(message?.createdAt).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                ) : message?.type === "audio" ? (
                                                    <div className='flex w-full items-center justify-end py-2 px-0 lg:px-0'>
                                                        <div className=' w-9/12 lg:w-1/3 '>
                                                            <div
                                                                className='w-full overflow-hidden rounded  '>
                                                                <audio
                                                                    className='w-full  rounded-full'
                                                                    id={`audio-${index}`}
                                                                    onPlay={() => stopAndPlayAudio(index)}
                                                                    src={`${baseUrl}uploads/audios/download/${message?.message}`} type="audio/mp3"
                                                                    controls
                                                                />
                                                                {/* <img className='w-full  object-cover' src={Document} alt="Document" /> */}
                                                            </div>
                                                            <div className='py-2'>
                                                                <h6 className='text-sm lg:text-xs font-normal text-[#AAAAAA]'>
                                                                    {new Date(message?.createdAt).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div >
                                        ) : (
                                            <div  className='flex items-center justify-start py-2 px-4'>
                                                {message?.type === 'text' ? (
                                                    <div className='max-w-[90%]  flex-wrap overflow-hidden lg:max-w-[60%]'>
                                                        <div style={{ backgroundColor: theme.palette.bg.chatHeadbg }} className=' w-full px-4 py-4 rounded'>
                                                            <p style={{ color: theme.palette.headings.secondary, wordBreak: 'break-word', }} className='text-sm lg:text-xs  break-words font-medium'>{message?.message}</p>
                                                        </div>
                                                        <div className='py-2 w-full flex items-center justify-end'>
                                                            <h6 className='text-sm lg:text-xs font-normal text-[#AAAAAA]'>
                                                                {new Date(message?.createdAt).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                ) : message?.type === 'image' ? (
                                                    <div
                                                        onClick={() => {
                                                            {
                                                                setOpenMedia(true)
                                                                setCurrentMedia(message)
                                                                setAllMedia(props?.doubtDets?.messages);
                                                            }
                                                        }}
                                                        className='flex w-full items-center justify-start py-2 px-0 lg:px-0'>
                                                        <div className=' w-9/12 lg:w-1/3 '>
                                                            <div style={{ backgroundColor: theme.palette.bg.studentChat }} className='w-full rounded shadow border border-[#C7C7C7]'>
                                                                <img className='w-full  object-cover' src={`${baseUrl}uploads/images/download/${message?.message}`} alt={message?.message} />
                                                            </div>
                                                            <div className='py-2 w-full flex items-center justify-end'>
                                                                <h6 className='text-sm lg:text-xs font-normal text-[#AAAAAA]'>
                                                                    {new Date(message?.createdAt).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : message?.type === "document" ? (
                                                    <div className='w-[67%] lg:w-[32%] relative flex flex-col  items-start rounded '>
                                                        <div
                                                            onClick={() => {
                                                                window.open(`${baseUrl}uploads/documents/download/${message.message}`)
                                                            }}
                                                            className='w-full cursor-pointer lg:w-full relative flex h-12 items-start rounded bg-[#F1F1F1]'>
                                                            <div className='bg-[#98D2C5] flex items-center justify-center rounded-s w-[25%] h-full'>
                                                                <img src={FileImg} alt="FileImg" />
                                                            </div>
                                                            <div className='h-full w-[70%] flex items-center justify-center overflow-hidden'>
                                                                <h6 className='text-sm lg:text-xs whitespace-nowrap'>{processFileName(message.message, 6)}</h6>
                                                            </div>
                                                            {/* <div className='absolute top-[-10%] right-[-4%]'>
                                                           <CrossIcon   />
                                                    </div> */}
                                                        </div>
                                                        <div className='py-2 w-full flex items-center justify-end'>
                                                            <h6 className='text-sm lg:text-xs font-normal text-[#AAAAAA]'>
                                                                {new Date(message?.createdAt).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                ) : message?.type === "audio" ? (
                                                    <div className='flex w-full items-center justify-start py-2 px-0 lg:px-0'>
                                                        <div className=' w-9/12 lg:w-1/3 '>
                                                            <div className='w-full overflow-hidden rounded'>
                                                                <audio className='w-full  rounded-full'
                                                                    id={`audio-${index}`}
                                                                    onPlay={() => stopAndPlayAudio(index)}
                                                                    src={`${baseUrl}/uploads/audios/download/${message?.message}`} type="audio/mp3"
                                                                    controls
                                                                />
                                                                {/* <img className='w-full  object-cover' src={Document} alt="Document" /> */}
                                                            </div>
                                                            <div className='py-2'>
                                                                <h6 className='text-sm lg:text-xs font-normal text-[#AAAAAA]'>
                                                                    {new Date(message?.createdAt).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null}

                                            </div>
                                        )
                                    ) : (
                                        message?.sendFrom === 'faculty' ? (
                                            <div key={index} className='flex w-full items-center justify-end py-2 px-4'>
                                                {message?.type === 'text' ? (
                                                    <div className='max-w-[90%] flex-wrap overflow-hidden lg:max-w-[60%]  '>
                                                        <div style={{ backgroundColor: theme.palette.bg.studentChat }} className='w-full px-4 py-4 rounded'>
                                                            <p style={{ color: theme.palette.headings.secondary, wordBreak: 'break-word', }} className='text-sm lg:text-xs  break-words font-medium'>{message?.message}</p>
                                                        </div>
                                                        <div className='py-2'>
                                                            <h6 className='text-sm lg:text-xs font-normal text-[#AAAAAA]'>
                                                                {new Date(message?.createdAt).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                ) : message?.type === 'image' ? (
                                                    <div className='flex w-full  items-center justify-end py-2  px-0 lg:px-0'>
                                                        <div className=' w-9/12 lg:w-1/3 h-[40vh] lg:h-[30vh]'>
                                                            <div
                                                                onClick={() => {
                                                                    {
                                                                        setOpenMedia(true)
                                                                        setCurrentMedia(message)
                                                                        setAllMedia(props?.doubtDets?.messages);
                                                                    }
                                                                }}
                                                                style={{ backgroundColor: theme.palette.bg.studentChat }}
                                                                className='w-full h-full  rounded shadow border border-[#C7C7C7]'
                                                            >
                                                                <img className='w-full h-full  object-contain' src={`${baseUrl}uploads/images/download/${message?.message}`} alt={message?.message} />
                                                            </div>
                                                            <div className='py-2 w-full flex items-center justify-start'>
                                                                <h6 className='text-sm lg:text-xs font-normal text-[#AAAAAA]'>
                                                                    {new Date(message?.createdAt).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : message?.type === "document" ? (
                                                    <div className='w-[67%] cursor-pointer lg:w-[32%] relative flex flex-col  items-start rounded '>
                                                        <div
                                                            onClick={() => {
                                                                window.open(`${baseUrl}uploads/documents/download/${message.message}`)
                                                            }}
                                                            className='w-full cursor-pointer lg:w-full relative flex h-12 items-start rounded bg-[#F1F1F1]'>
                                                            <div className='bg-[#98D2C5] flex items-center justify-center rounded-s w-[25%] h-full'>
                                                                <img src={FileImg} alt="FileImg" />
                                                            </div>
                                                            <div className='h-full w-[70%] flex items-center justify-center overflow-hidden'>
                                                                <h6 className='text-sm lg:text-xs whitespace-nowrap'>{processFileName(message.message, 6)}</h6>
                                                            </div>
                                                            {/* <div className='absolute top-[-10%] right-[-4%]'>
                                                               <CrossIcon   />
                                                        </div> */}
                                                        </div>
                                                        <div className='py-2 w-full flex items-center justify-start'>
                                                            <h6 className='text-sm lg:text-xs font-normal text-[#AAAAAA]'>
                                                                {new Date(message?.createdAt).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                ) : message?.type === "audio" ? (
                                                    <div className='flex w-full items-center justify-end py-2 px-0 lg:px-0'>
                                                        <div className=' w-9/12 lg:w-1/3 '>
                                                            <div className='w-full overflow-hidden rounded  '>
                                                                <audio className='w-full  rounded-full'
                                                                    id={`audio-${index}`}
                                                                    onPlay={() => stopAndPlayAudio(index)}
                                                                    src={`${baseUrl}uploads/audios/download/${message?.message}`} type="audio/mp3"
                                                                    controls
                                                                />
                                                                {/* <img className='w-full  object-cover' src={Document} alt="Document" /> */}
                                                            </div>
                                                            <div className='py-2'>
                                                                <h6 className='text-sm lg:text-xs font-normal text-[#AAAAAA]'>
                                                                    {new Date(message?.createdAt).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>
                                        ) : (
                                            <div className='flex items-center justify-start py-2 px-4'>
                                                {message?.type === 'text' ? (
                                                    <div className='max-w-[90%] flex-wrap overflow-hidden lg:max-w-[60%]'>
                                                        <div style={{ backgroundColor: theme.palette.bg.chatHeadbg }} className=' w-full px-4 py-4 rounded'>
                                                            <p style={{ color: theme.palette.headings.secondary, wordBreak: 'break-word', }} className='text-sm lg:text-xs  break-words font-medium'>{message?.message}</p>
                                                        </div>
                                                        <div className='py-2 w-full flex items-center justify-end'>
                                                            <h6 className='text-sm lg:text-xs font-normal text-[#AAAAAA]'>
                                                                {new Date(message?.createdAt).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                ) : message?.type === 'image' ? (
                                                    <div className='flex w-full items-center justify-start py-2 px-0 lg:px-0'>
                                                        <div className=' w-9/12 lg:w-1/3 h-[40vh] lg:h-[30vh]'>
                                                            <div
                                                                onClick={() => {
                                                                    {
                                                                        setOpenMedia(true)
                                                                        setCurrentMedia(message)
                                                                        setAllMedia(props?.doubtDets?.messages);
                                                                    }
                                                                }}
                                                                style={{ backgroundColor: theme.palette.bg.studentChat }}
                                                                className='w-full h-full rounded shadow border border-[#C7C7C7]'
                                                            >
                                                                <img className='w-full h-full  object-contain' src={`${baseUrl}uploads/images/download/${message?.message}`} alt={message?.message} />
                                                            </div>
                                                            <div className='py-2 w-full flex items-center justify-end'>
                                                                <h6 className='text-sm lg:text-xs font-normal text-[#AAAAAA]'>
                                                                    {new Date(message?.createdAt).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : message?.type === "document" ? (
                                                    <div
                                                        onClick={() => {
                                                            window.open(`${baseUrl}uploads/documents/download/${message.message}`)
                                                        }}
                                                        className='w-[67%] cursor-pointer lg:w-[32%] relative flex flex-col  items-end rounded '>
                                                        <div className='w-full lg:w-full relative flex h-12 items-start rounded bg-[#F1F1F1]'>
                                                            <div className='bg-[#98D2C5] flex items-center justify-center rounded-s w-[25%] h-full'>
                                                                <img src={FileImg} alt="FileImg" />
                                                            </div>
                                                            <div className='h-full w-[70%] flex items-center justify-center overflow-hidden'>
                                                                <h6 className='text-sm lg:text-xs whitespace-nowrap'>{processFileName(message.message, 6)}</h6>
                                                            </div>

                                                            {/* <div className='absolute top-[-10%] right-[-4%]'>
                                                               <CrossIcon   />
                                                        </div> */}
                                                        </div>
                                                        <div className='py-2 w-full flex items-center justify-end'>
                                                            <h6 className='text-sm lg:text-xs font-normal text-[#AAAAAA]'>
                                                                {new Date(message?.createdAt).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                ) : message?.type === "audio" ? (
                                                    <div className='flex w-full items-center justify-start py-2 px-0 lg:px-0'>
                                                        <div className=' w-9/12 lg:w-1/3 '>
                                                            <div className='w-full overflow-hidden rounded  '>
                                                                <audio className='w-full  rounded-full'
                                                                    id={`audio-${index}`}
                                                                    onPlay={() => stopAndPlayAudio(index)}
                                                                    src={`${baseUrl}uploads/audios/download/${message?.message}`} type="audio/mp3"
                                                                    controls
                                                                />
                                                                {/* <img className='w-full  object-cover' src={Document} alt="Document" /> */}
                                                            </div>
                                                            <div className='py-2'>
                                                                <h6 className='text-sm lg:text-xs font-normal text-[#AAAAAA]'>
                                                                    {new Date(message?.createdAt).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>
                                        )
                                    )}
                                </>
                            );
                        })}
                    </div>

                    {/* INUPUT AREA  */}

                    {props?.image?.length > 0 || isCamera ? (
                        <p></p>
                    ) : (

                        <div
                            ref={inpRef}
                            style={{ backgroundColor: theme.palette.bg.main }} className=' px-3 lg:px-6  fixed bottom-0 w-full lg:w-[52%] py-2 '>

                            {/* FACULTY CONTAINTER */}
                            {props?.userStatus === true && (

                                props?.role === 'faculty' && (props?.currentStatus === 'new') ? (
                                    <div className='flex items-center justify-center  w-full  gap-2 lg:flex-row py-2'>
                                        <button className="bg-[#67BBA2]   text-[#FFFFFF] font-medium py-2 px-4 rounded   shadow-xl" onClick={() => props?.onUpdateStatus('answering')}>
                                            <h6 className='text-sm whitespace-nowrap mx-2 lg:text-xs font-medium'>Answer?</h6>
                                        </button>
                                        <button className="bg-[#67BBA2]   text-[#FFFFFF] font-medium py-2 px-6 rounded  shadow-xl" onClick={() => { props?.onUpdateStatus('query'); }}>
                                            <h6 className='text-sm whitespace-nowrap mx-2 lg:text-xs font-medium'> Query</h6>
                                        </button>
                                    </div>
                                ) : props?.role === 'faculty' && (props?.currentStatus === 'query') ? (
                                    <div style={{ backgroundColor: theme.palette.bg.chatInput }} className='rounded-full overflow-hidden flex items-center shadow  w-full'>

                                        {/* AUDIO RECORDER COMPONENT */}

                                        <div className={`px-2 ${isRecord || props?.audio ? 'w-full' : 'w-fit'} pl-4`}>
                                            <AudioRecoderComponent
                                                audio={props?.audio}
                                                image={props?.image}
                                                setaudio={props?.setaudio}
                                                setimage={props?.setImage}
                                                isRecord={isRecord}
                                                setIsRecord={setIsRecord}
                                                onAudioSend={props.onSendImages}
                                                mediaType={props?.mediaType}
                                                setMediaType={props?.setMediaType}
                                            />
                                        </div>

                                        {/* TEXTFIELD  */}
                                        {!isRecord && !props?.audio && (
                                            <div className='bg-[#F2F2F2] rounded-full flex items-center w-full'>
                                                <TextField
                                                    id="input-with-icon-textfield"
                                                    placeholder='Write Something'
                                                    // helperText={signinForm.email?.length > 0 && !emailRegex?.test(signinForm.email) ? "Please Enter Valid Email" : ""}
                                                    // error={signinForm.email?.length > 0 && !emailRegex?.test(signinForm.email)}
                                                    fullWidth
                                                    // variant="standard"
                                                    size='small'
                                                    className='custom-placeholder border !border-[F4F4F4] !shadow-none '
                                                    sx={inputStyle}
                                                    type='text'
                                                    value={props?.message}
                                                    autoFocus={false}
                                                    onKeyUp={(e) => e?.which == 13 ? props.onSend() : null}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton onClick={handleClick}>
                                                                    <AttachmentInputIcon color={theme.palette.headings.secondary} />
                                                                </IconButton>
                                                                <IconButton onClick={() => setIsCamera(true)}>
                                                                    <CameraInputIcon color={theme.palette.headings.secondary} />
                                                                </IconButton>
                                                                <IconButton onClick={props.onSend}
                                                                >
                                                                    <SendIcon color={theme.palette.headings.secondary} />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    // value={signinForm.email}
                                                    onChange={(e) => props.setMessage(e?.target?.value)}
                                                />
                                            </div>
                                        )}

                                    </div>
                                ) : props?.role === 'faculty' && (props?.currentStatus === 'answering') ? (
                                    <div style={{ backgroundColor: theme.palette.bg.chatInput }} className='rounded-full overflow-hidden flex items-center shadow  w-full'>

                                        {/* AUDIO RECORDER COMPONENT */}

                                        <div className={`px-2 ${isRecord || props?.audio ? 'w-full' : 'w-fit'} pl-4`}>
                                            <AudioRecoderComponent
                                                audio={props?.audio}
                                                image={props?.image}
                                                setaudio={props?.setaudio}
                                                setimage={props?.setImage}
                                                isRecord={isRecord}
                                                setIsRecord={setIsRecord}
                                                onAudioSend={props.onSendImages}
                                                mediaType={props?.mediaType}
                                                setMediaType={props?.setMediaType}
                                            />
                                        </div>

                                        {/* TEXTFIELD  */}
                                        {!isRecord && !props?.audio && (
                                            <div className='bg-[#F2F2F2] rounded-full flex items-center w-full'>
                                                <TextField
                                                    id="input-with-icon-textfield"
                                                    placeholder='Write Something'
                                                    // helperText={signinForm.email?.length > 0 && !emailRegex?.test(signinForm.email) ? "Please Enter Valid Email" : ""}
                                                    // error={signinForm.email?.length > 0 && !emailRegex?.test(signinForm.email)}
                                                    fullWidth
                                                    // variant="standard"
                                                    size='small'
                                                    className='custom-placeholder border !border-[F4F4F4] !shadow-none '
                                                    sx={inputStyle}
                                                    type='text'
                                                    value={props?.message}
                                                    autoFocus={false}
                                                    onKeyUp={(e) => e?.which == 13 ? props.onSend() : null}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton onClick={handleClick}>
                                                                    <AttachmentInputIcon color={theme.palette.headings.secondary} />
                                                                </IconButton>
                                                                <IconButton onClick={() => setIsCamera(true)}>
                                                                    <CameraInputIcon color={theme.palette.headings.secondary} />
                                                                </IconButton>
                                                                <IconButton onClick={props.onSend}
                                                                >
                                                                    <SendIcon color={theme.palette.headings.secondary} />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    // value={signinForm.email}
                                                    onChange={(e) => props.setMessage(e?.target?.value)}
                                                />
                                            </div>
                                        )}
                                    </div>
                                ) : props?.role === 'faculty' && (props?.currentStatus === 'answered') ? (
                                    <div className='flex items-center justify-center flex-col w-full  gap-2 lg:flex-row py-2'>
                                        <h6 style={{ color: theme.palette.headings.main }} className='text-sm whitespace-nowrap lg:text-sm font-medium'>Want to close the doubt?</h6>
                                        <div className='flex items-center gap-2'>
                                            <button className="  text-[#BCBCBC] font-medium py-2 px-2 rounded flex gap-2 items-center  shadow-xl"
                                                onClick={() => { props?.onUpdateStatus('new', null); props?.onUpdateLikeStatus(null) }}

                                            >
                                                <h6 className='text-sm whitespace-nowrap mx-2 lg:text-xs font-medium'>Cancel</h6>
                                            </button>
                                            <button className="bg-[#FF3333]   text-[#FFFFFF] font-medium py-2 px-3 rounded flex gap-2 items-center shadow-xl"
                                                onClick={() => { props?.onUpdateStatus('closed', null) }}
                                            >
                                                <h6 className='text-sm whitespace-nowrap mx-2 lg:text-xs font-medium'>Close</h6>
                                            </button>
                                        </div>
                                    </div>
                                ) : props?.role === 'faculty' && props?.currentStatus === 'closed' ? (
                                    <div className='flex flex-col lg:flex-row w-full justify-center items-center gap-2'>
                                        <h6 style={{ color: theme.palette.headings.main }} className='text-sm whitespace-nowrap lg:text-sm font-medium'>Your doubt is closed please Reopen to continue</h6>
                                        <button className="bg-[#00B05B] text-[#FFFFFF] font-medium py-2 px-2 rounded flex gap-2 items-center  shadow-xl"
                                            onClick={() => { props?.onUpdateStatus('reopened', null); props?.onUpdateLikeStatus(null); }}
                                        >
                                            <h6 className='text-sm whitespace-nowrap mx-2 lg:text-xs font-medium'>Re-open</h6>
                                        </button>
                                    </div>
                                ) : props?.role === 'faculty' && (props?.currentStatus === 'reopened') ? (
                                    <div className='flex items-center justify-center  w-full  gap-2 lg:flex-row py-2'>
                                        <button className="bg-[#67BBA2]   text-[#FFFFFF] font-medium py-2 px-4 rounded   shadow-xl" onClick={() => props?.onUpdateStatus('answering')}>
                                            <h6 className='text-sm whitespace-nowrap mx-2 lg:text-sm font-medium'>Answer?</h6>
                                        </button>
                                        <button className="bg-[#67BBA2]   text-[#FFFFFF] font-medium py-2 px-6 rounded  shadow-xl" onClick={() => { props?.onUpdateStatus('query'); }}>
                                            <h6 className='text-sm whitespace-nowrap mx-2 lg:text-sm font-medium'>Query</h6>
                                        </button>
                                    </div>
                                ) : (
                                    // <h1>dddd</h1>
                                    <></>
                                )
                            )}


                            {/* STUDENT CONTAINER */}
                            {/* // onClick={() => { props?.onUpdateLikeStatus('liked'); setAskforClosed('opened') }} */}
                            {props?.userStatus === true && (
                                props?.role === 'student' && (props?.currentStatus === 'new' || props?.currentStatus === 'answering') ? (
                                    <div style={{ backgroundColor: theme.palette.bg.chatInput }} className='rounded-full overflow-hidden flex items-center shadow  w-full'>

                                        {/* AUDIO RECORDER COMPONENT */}

                                        <div className={`px-2 ${isRecord || props?.audio ? 'w-full' : 'w-fit'} pl-4`}>
                                            <AudioRecoderComponent
                                                audio={props?.audio}
                                                image={props?.image}
                                                setaudio={props?.setaudio}
                                                setimage={props?.setImage}
                                                isRecord={isRecord}
                                                setIsRecord={setIsRecord}
                                                onAudioSend={props.onSendImages}
                                                mediaType={props?.mediaType}
                                                setMediaType={props?.setMediaType}
                                            />
                                        </div>

                                        {/* TEXTFIELD  */}
                                        {!isRecord && !props?.audio && (
                                            <div className='bg-[#F2F2F2] rounded-full flex items-center w-full'>
                                                <TextField
                                                    id="input-with-icon-textfield"
                                                    placeholder='Write Something'
                                                    // helperText={signinForm.email?.length > 0 && !emailRegex?.test(signinForm.email) ? "Please Enter Valid Email" : ""}
                                                    // error={signinForm.email?.length > 0 && !emailRegex?.test(signinForm.email)}
                                                    fullWidth
                                                    // variant="standard"
                                                    size='small'
                                                    className='custom-placeholder border !border-[F4F4F4] !shadow-none '
                                                    sx={inputStyle}
                                                    type='text'
                                                    value={props?.message}
                                                    autoFocus={false}
                                                    onKeyUp={(e) => e?.which == 13 ? props.onSend() : null}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton onClick={handleClick}>
                                                                    <AttachmentInputIcon color={theme.palette.headings.secondary} />
                                                                </IconButton>
                                                                <IconButton onClick={() => setIsCamera(true)}>
                                                                    <CameraInputIcon color={theme.palette.headings.secondary} />
                                                                </IconButton>
                                                                <IconButton onClick={props.onSend}>
                                                                    <SendIcon color={theme.palette.headings.secondary} />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    // value={signinForm.email}
                                                    onChange={(e) => props.setMessage(e?.target?.value)}
                                                />
                                            </div>
                                        )}
                                    </div>
                                ) : props?.role === 'student' && (props?.currentStatus === 'query') ? (
                                    <div style={{ backgroundColor: theme.palette.bg.chatInput }} className='rounded-full overflow-hidden flex items-center shadow  w-full'>

                                        {/* AUDIO RECORDER COMPONENT */}

                                        <div className={`px-2 ${isRecord || props?.audio ? 'w-full' : 'w-fit'} pl-4`}>
                                            <AudioRecoderComponent
                                                audio={props?.audio}
                                                image={props?.image}
                                                setaudio={props?.setaudio}
                                                setimage={props?.setImage}
                                                isRecord={isRecord}
                                                setIsRecord={setIsRecord}
                                                onAudioSend={props.onSendImages}
                                                mediaType={props?.mediaType}
                                                setMediaType={props?.setMediaType}
                                            />
                                        </div>

                                        {/* TEXTFIELD  */}
                                        {!isRecord && !props?.audio && (
                                            <div className='bg-[#F2F2F2] rounded-full flex items-center w-full'>
                                                <TextField
                                                    id="input-with-icon-textfield"
                                                    placeholder='Write Something'
                                                    // helperText={signinForm.email?.length > 0 && !emailRegex?.test(signinForm.email) ? "Please Enter Valid Email" : ""}
                                                    // error={signinForm.email?.length > 0 && !emailRegex?.test(signinForm.email)}
                                                    fullWidth
                                                    // variant="standard"
                                                    size='small'
                                                    className='custom-placeholder border !border-[F4F4F4] !shadow-none '
                                                    sx={inputStyle}
                                                    type='text'
                                                    value={props?.message}
                                                    autoFocus={false}
                                                    onKeyUp={(e) => e?.which == 13 ? props.onSend() : null}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton onClick={handleClick}>
                                                                    <AttachmentInputIcon color={theme.palette.headings.secondary} />
                                                                </IconButton>
                                                                <IconButton onClick={() => setIsCamera(true)}>
                                                                    <CameraInputIcon color={theme.palette.headings.secondary} />
                                                                </IconButton>
                                                                <IconButton onClick={props.onSend}
                                                                >
                                                                    <SendIcon color={theme.palette.headings.secondary} />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    // value={signinForm.email}
                                                    onChange={(e) => props.setMessage(e?.target?.value)}
                                                />
                                            </div>
                                        )}
                                    </div>
                                ) : props?.role === 'student' && (props?.currentStatus === 'closed' || props?.currentStatus === 'answered') && !props?.liked && !props?.tempStatus ? (
                                    <div className='flex items-center justify-center flex-col w-full  gap-2 lg:flex-row py-2'>
                                        <h6 style={{ color: theme.palette.headings.main }} className='text-sm whitespace-nowrap lg:text-sm font-medium'>Are you satisfied with the answer?</h6>
                                        <div className='flex items-center gap-2'>
                                            <button className="bg-[#00B05B]   text-[#FFFFFF] font-medium py-2 px-2 rounded flex gap-1 items-center  shadow-xl"
                                                // onClick={() => { props?.onUpdateLikeStatus('liked'); setAskforClosed('opened') }}
                                                onClick={() => {
                                                    props?.onUpdateLikeStatus('liked', 'liked');
                                                    props?.onUpdateStatus(null, true);
                                                }}
                                            >
                                                <h6 className='text-sm whitespace-nowrap mx-2 lg:text-xs font-medium'>Yes</h6>
                                                <LikeIcon color='#ffff' />
                                            </button>
                                            <button className="bg-[#FF3333]   text-[#FFFFFF] font-medium py-2 px-3 rounded flex gap-1 items-center shadow-xl"
                                                // onClick={() => { props?.onUpdateLikeStatus('new'); props?.onUpdateLikeStatus('disliked') }}
                                                onClick={() => {
                                                    props?.onUpdateStatus(null, true);
                                                    props?.onUpdateLikeStatus('disliked', 'disliked');
                                                }}
                                            >
                                                <h6 className='text-sm whitespace-nowrap mx-2 lg:text-xs font-medium'>No</h6>
                                                <DisLikeIcon color='#ffff' />
                                            </button>
                                        </div>
                                    </div>
                                ) : props?.role === 'student' && props?.currentStatus === 'answered' && props?.liked && props?.tempStatus ? (
                                    <div className='flex items-center justify-center flex-col w-full  gap-2 lg:flex-row py-2'>
                                        <h6 style={{ color: theme.palette.headings.main }} className='text-sm whitespace-nowrap lg:text-sm font-medium'>Want to close the doubt?</h6>
                                        <div className='flex items-center gap-2'>
                                            <button className="  text-[#BCBCBC] font-medium py-2 px-2 rounded flex gap-2 items-center  shadow-xl"
                                                onClick={() => { props?.onUpdateStatus('new', null); props?.onUpdateLikeStatus(null) }}
                                            >
                                                <h6 className='text-sm whitespace-nowrap mx-2 lg:text-xs font-medium'>Cancel</h6>
                                            </button>
                                            <button className="bg-[#FF3333]   text-[#FFFFFF] font-medium py-2 px-3 rounded flex gap-2 items-center shadow-xl"
                                                onClick={() => { props?.onUpdateStatus('closed'); props?.onUpdateLikeStatus(null) }}
                                            >
                                                <h6 className='text-sm whitespace-nowrap mx-2 lg:text-xs font-medium'>Close</h6>
                                            </button>
                                        </div>
                                    </div>
                                ) : props?.role === 'student' && props?.currentStatus === 'closed' ? (
                                    <div className='flex items-center justify-center flex-col w-full  gap-2 lg:flex-row py-2'>
                                        <h6 style={{ color: theme.palette.headings.main }} className='text-sm whitespace-nowrap lg:text-sm font-medium'>Your doubt is closed please Reopen to continue</h6>
                                        <button className="bg-[#00B05B] text-[#FFFFFF] font-medium py-2 px-2 rounded flex gap-2 items-center  shadow-xl"
                                            onClick={() => { props?.onUpdateStatus('reopened', null); props?.onUpdateLikeStatus(null); }}
                                            size='small'
                                        >
                                            <h6 className='text-sm whitespace-nowrap mx-2 lg:text-xs font-medium'>Re-open</h6>
                                        </button>
                                    </div>
                                ) : props?.role === 'student' && (props?.currentStatus === 'reopened') ? (
                                    <div style={{ backgroundColor: theme.palette.bg.chatInput }} className='rounded-full overflow-hidden flex items-center shadow  w-full'>

                                        {/* AUDIO RECORDER COMPONENT */}

                                        <div className={`px-2 ${isRecord || props?.audio ? 'w-full' : 'w-fit'} pl-4`}>
                                            <AudioRecoderComponent
                                                audio={props?.audio}
                                                image={props?.image}
                                                setaudio={props?.setaudio}
                                                setimage={props?.setImage}
                                                isRecord={isRecord}
                                                setIsRecord={setIsRecord}
                                                onAudioSend={props.onSendImages}
                                                mediaType={props?.mediaType}
                                                setMediaType={props?.setMediaType}
                                            />
                                        </div>

                                        {/* TEXTFIELD  */}
                                        {!isRecord && !props?.audio && (
                                            <div className='bg-[#F2F2F2] rounded-full flex items-center w-full'>
                                                <TextField
                                                    id="input-with-icon-textfield"
                                                    placeholder='Write Something'
                                                    // helperText={signinForm.email?.length > 0 && !emailRegex?.test(signinForm.email) ? "Please Enter Valid Email" : ""}
                                                    // error={signinForm.email?.length > 0 && !emailRegex?.test(signinForm.email)}
                                                    fullWidth
                                                    // variant="standard"
                                                    size='small'
                                                    className='custom-placeholder border !border-[F4F4F4] !shadow-none '
                                                    sx={inputStyle}
                                                    type='text'
                                                    value={props?.message}
                                                    autoFocus={false}
                                                    onKeyUp={(e) => e?.which == 13 ? props.onSend() : null}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton onClick={handleClick}>
                                                                    <AttachmentInputIcon color={theme.palette.headings.secondary} />
                                                                </IconButton>
                                                                <IconButton onClick={() => setIsCamera(true)}>
                                                                    <CameraInputIcon color={theme.palette.headings.secondary} />
                                                                </IconButton>
                                                                <IconButton onClick={props.onSend}
                                                                >
                                                                    <SendIcon color={theme.palette.headings.secondary} />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    // value={signinForm.email}
                                                    onChange={(e) => props.setMessage(e?.target?.value)}
                                                />
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    // <h1>dddd</h1>
                                    <></>
                                )
                            )}

                        </div>
                    )}

                    {/* SELECT  MEDIA OPTIONS */}

                    <div>

                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}

                            sx={{
                                '& .MuiMenu-paper': {
                                    backgroundColor: theme.palette.bg.chatInput,
                                    border: '1px solid #E1E1E112',
                                    borderRadius: '5px',
                                    padding: '.5vmax',
                                    top: "83% !important"
                                }
                            }}

                        >

                            <MenuItem onClick={() => {
                                imageRef.current.click()
                                setAnchorEl(null);
                            }} >
                                <div className='flex items-center gap-3'>
                                    <InsertPhotoOutlinedIcon sx={{ color: theme.palette.headings.secondary }} />
                                    <h6 style={{ color: theme.palette.headings.secondary }} className='text-sm lg:text-xs '>Photos</h6>
                                </div>
                            </MenuItem>
                            <MenuItem onClick={() => {
                                docsRef.current.click()
                                setAnchorEl(null);
                            }}>
                                <div className='flex items-center gap-3'>
                                    <InsertDriveFileOutlinedIcon sx={{ color: theme.palette.headings.secondary }} />
                                    <h6 style={{ color: theme.palette.headings.secondary }} className='text-sm lg:text-xs'>Document</h6>
                                </div>
                            </MenuItem>
                        </Menu>

                        {/* SELECT IMAGE INPUT */}

                        <input
                            ref={imageRef}
                            onClick={() => { imageRef.current.value = null }}
                            accept="image/*"
                            type="file"
                            name="myImage"
                            onChange={(event) => {
                                let temp = true;
                                let file = [];

                                for (let index = 0; index < event?.target?.files?.length; index++) {
                                    const currentFile = event?.target?.files[index];

                                    // Check if the file type is an image
                                    if (currentFile?.type?.split('/')[0] === 'image') {
                                        // Check if the file is not already in the array
                                        if (!props?.image?.find((img) => img.name === currentFile.name)) {
                                            file.push(currentFile);
                                        } else {
                                            // console.log('Image already exists in the array:', currentFile.name);
                                        }
                                    } else {
                                        temp = false;
                                        console.log('Wrong data');
                                    }
                                }

                                if (temp) {
                                    props?.setImage((prevImages) => {
                                        if (Array.isArray(prevImages)) {
                                            return [...prevImages, ...file];
                                        } else {
                                            return [...file];
                                        }
                                    });
                                    props?.setImageType('gallery');
                                    props?.setMediaType('image');
                                } else {
                                    console.log('Wrong data!');
                                }
                            }}
                            multiple
                            className="!hidden"
                        />

                        {/* SELECT DOCUMENT INPUT */}

                        <input
                            ref={docsRef}
                            onClick={() => { docsRef.current.value = null }}
                            type="file"
                            name="myImage"
                            onChange={(event) => {
                                let temp = true;
                                let newFiles = [];

                                for (let index = 0; index < event?.target?.files?.length; index++) {
                                    newFiles.push(event?.target?.files[index]);
                                }

                                if (temp) {
                                    props?.setImage((prevImages) => {
                                        if (Array.isArray(prevImages)) {
                                            // Filter out files that are already present in the state
                                            const uniqueFiles = newFiles.filter((newFile) => {
                                                return !prevImages.some((prevFile) => prevFile.name === newFile.name);
                                            });

                                            return [...prevImages, ...uniqueFiles];
                                        } else {
                                            return [...newFiles];
                                        }
                                    });

                                    props?.setImageType('docs');
                                    props?.setMediaType('document');
                                } else {
                                    console.log('Wrong data!');
                                }
                            }}
                            multiple
                            className="!hidden"
                        />

                        {/* {cameraImage && <img src={cameraImage} alt="#" />} */}

                    </div>

                    {/* SHOW SELECTED IMAGES */}

                    {props?.image.length > 0 &&
                        <SelectImages
                            isUploading={props?.isUploading}
                            imageType={props?.imageType}
                            onOpenCamera={() => setIsCamera(true)}
                            images={props?.image}
                            closeImages={() => props?.setImage([])}
                            onadd={(type) => clickInput(type)}
                            onRemove={(index) => removeImageAtIndex(index)}
                            onImageSend={props?.onSendImages}
                            mediaType={props?.mediaType}
                        />
                    }

                    {/* PREVIEW IMAGE COMPOINENT */}.

                    {openMedia && props?.image?.length === 0 &&
                        <PreviewImaDialogue
                            currentMedia={currentMedia}
                            setCurrentMedia={setCurrentMedia}
                            allMedia={allMedia}
                            setOpenMedia={setOpenMedia}
                            openMedia={openMedia}
                        />
                    }

                    {/* OPEN CAMERA COMPONENT */}

                    {isCamera &&
                        <div style={{ backgroundColor: theme.palette.bg.chatInput, }} className='w-full lg:w-[70%] rounded  h-full  lg:h-1/2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 '>
                            <div style={{ backgroundColor: theme.palette.bg.chatInput, }} className='w-full flex absolute top-0 z-50 py-3   px-2 items-center justify-between'>
                                <h1 style={{ color: theme.palette.headings.secondary }} className='text-sm lg:text-sm tracking-wider'>Take A Photo</h1>
                                <div onClick={() => setIsCamera(false)} className='cursor-pointer'>
                                    <CrossIcon />
                                </div>
                            </div>
                            <div className='w-full h-full '>
                                <Camera
                                    style={cameraStyles}
                                    isFullscreen={props?.screenWidth > 1024 ? false : true}
                                    onTakePhoto={(dataUri) => { handleTakePhoto(dataUri); }}
                                    idealFacingMode={FACING_MODES.ENVIRONMENT}
                                    idealResolution={{ width: 640, height: 480 }}
                                    onCameraError={(error) => { handleCameraError(error); }}
                                />
                            </div>
                        </div>
                    }

                </div >

            ) : (

                // NO DOUBT 

                <div className='w-full h-full flex items-center justify-center'>
                    <div className='hidden lg:block'>
                        {props.ctheme === 'dark' ? (
                            <img src={NoDoubtDarkImg} alt="NoDoubtDarkImg" />
                        ) : (
                            <img src={NodoubtLightImg} alt="NodoubtImg" />
                        )}
                    </div>
                    <div className='block lg:hidden'>
                        <Box sx={{ display: 'flex' }}>
                            <CircularProgress />
                        </Box>
                    </div>
                </div>

            )}


        </>
    )
}

export default ChatArea
