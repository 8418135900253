import React from 'react'
import { Box } from '@mui/system';
import { useTheme } from '@mui/system';

import { Typography } from '@mui/material';
export default function Map() {
  const theme = useTheme();

  return (
    <div className='  flex  gap-4 lg:flex-col'>
      <div >
        <Typography style={{ color: theme.palette.headings.main }} className=' font-semibold'>Most doubt asked</Typography>
        <Box className='bg-[#7BB5A8] rounded' width={28} height={11}></Box>




      </div>
      <div>
        <Typography style={{ color: theme.palette.headings.main }} className=' font-semibold'>Less doubt asked</Typography>
        <Box className='bg-[#7BB5A88A] rounded' width={28} height={11}></Box>


      </div>
      <div>
        <Typography style={{ color: theme.palette.headings.main }} className=' font-semibold'>Very-Less doubt asked</Typography>
        <Box className='bg-[#7BB5A824] rounded' width={28} height={11}></Box>
      </div>


    </div>

  )
}
