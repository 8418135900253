import { Typography } from '@mui/material'
import React from 'react'
import Search from '../../Components/Search'
import AddFaculty from '../../Dialogs/AddFaculty'
import Tooltip from '@mui/material/Tooltip';
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import { Switch, Avatar, } from '@mui/material';
import EditFaculty from '../../Dialogs/EditFaculty';
import FilterDrawer from '../../Components/Filterdrawer';
import FacultyFilter from './FacultyFilter';
import FacultyMap from '../../Dialogs/FacultyMap';
import { useNavigate } from 'react-router-dom';
import { baseUrl, get, patch, post } from '../../Services/api-services'
import { useTheme } from '@mui/system';
import { statusChange } from '../../Socket/Emit';
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { debounce, filter } from "lodash"



const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#98D2C5',
        // backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));



export default function Faculty() {
  const theme = useTheme();
  const [page, setPage] = React.useState(10);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [faculties, setFaculties] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [filterOpen, setFilterOpen] = React.useState(false);
  const [courses, setCourses] = React.useState([])
  const [count, setCount] = React.useState(0)
  const [allCourses, setAllCourses] = React.useState([])
  const [filterData, setFilterData] = React.useState({
    rating: [0, 0],
    // selectedAdmin: role === 'admin' ? [_adminAuthState?.userId] : [],
    date: {
      start: '',
      end: ''
    },
    course: [],
    subject: [],
    faculty: [],
    // status:'',
    text: null

  })


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  // RESET FILTER 

  const removeAll = () => {
    const resetData = {
      rating: [0, 0],
      // selectedAdmin: role === 'admin' ? [_adminAuthState?.userId] : [],
      date: {
        start: '',
        end: ''
      },
      course: [],
      subject: [],
      faculty: [],
      // status:'',
      text: ''
    }
    setFilterData(resetData)
    getFaculty();
  }


  const dataGridRef = React.useRef();

  const getFaculty = async (req) => {
    try {
      setLoading(true)

      const res = await post('faculties/getfacultyList', { "filter": req })
      if (res?.statusCode === 200) {
        setFaculties(res?.data)
        setCount(1);
      }

      setLoading(false)
    } catch (e) {
      console.log(e);
    }
  }

  const GetCourses = async () => {
    try {

      // const res = await get(`courses`)
      const res = await get('courses?filter={"where": {"deleted":false},"include":"country"}')

      if (res?.data) {
        setCourses(res?.data)
        setAllCourses(res?.data)
      }

    } catch (e) {
      console.log(e);
    }
  }


  React.useEffect(() => {
    GetCourses()
  }, []);


  const facultyStatus = async (id, updatedStatus) => {


    const res = await patch(`faculties/${id}`, { "status": !updatedStatus })

    if (res?.statusCode === 200) {
      getFaculty()

      statusChange({ update: 'update status' });

    }
  }

  const columns = [

    {
      field: 'name',
      headerName: 'Name',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {

        return <div className='flex gap-2  items-center' style={{ opacity: params?.row?.status ? 1 : 0.4 }}>

          <div >  {params.row.image ? <Avatar alt="Cindy Baker" src={`${baseUrl}uploads/staff/download/${params.row.image}`} /> : <Avatar>{params.row.name[0]?.toUpperCase()}</Avatar>}  </div>
          <div>
            <p className='font-bold '>{params.row.name}</p>
          </div>
        </div>
      }

    },

    {
      field: 'rating',
      headerName: 'Rating',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {

        return <div className='flex gap-2  items-center' style={{ opacity: params?.row?.status ? 1 : 0.4 }}>
          <Typography className='text-[#FFCE31]' >{typeof params.row.rating === 'number' ? params.row.rating.toFixed(2) : ''}</Typography>

        </div>
      }

    },

    {
      // field: 'subjects',
      headerName: 'Courses',
      minWidth: 150,
      flex: 1,
      opacity: 0.5,
      renderCell: (params) => {

        const coursesArr1 = []
        const courseArr = [...params?.row?.fullcourses]

        courseArr?.forEach(function (value) {
          if (value) {
            coursesArr1.push(value);
          }
        });

        const count = coursesArr1.length - 1
        const show = []
        coursesArr1.slice(1).forEach(function (value) {
          show.push(value?.name, ",")
        })

        return (
          <div className='flex items-center gap-1' style={{ opacity: params?.row?.status ? 1 : 0.4 }}>
            {show?.length > 1 ? (
              <Tooltip title={show}>
                <div className='flex items-center gap-2'>
                  <p>{coursesArr1[0]?.name}</p>
                  {count ? <span className='text-[#98D2C5]'>+{count}</span> : null}
                </div>
              </Tooltip>
            ) : (
              <div className='flex items-center gap-2'>
                <p>{coursesArr1[0]?.name}</p>
                {count ? <span className='text-[#98D2C5]'>+{count}</span> : null}
              </div>
            )}
          </div>

        )
      },


    },

    {
      field: 'subjects',
      headerName: 'Subjects',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        const arr = [...params.value]

        const count = arr.length - 1
        const show = []
        arr.slice(1).forEach(function (value) {
          show.push(value.name, ",")
          courses.push(value.courses)
        })

        return <div className='flex items-center gap-1' style={{ opacity: params?.row?.status ? 1 : 0.4 }}>
          {show?.length > 1 ? (
            <Tooltip title={show}>
              <div className='flex items-center gap-2'>
                <p>{arr[0].name}    </p>
                {count ? <span className='text-[#98D2C5]'>+{count}</span> : null}
              </div>
            </Tooltip>
          ) : (
            <div className='flex items-center gap-2'>
              <p>{arr[0].name}    </p>
              {count ? <span className='text-[#98D2C5]'>+{count}</span> : null}
            </div>
          )}
        </div>
      },

    },

    {
      field: 'avarageHr',
      headerName: 'Average time / Hr',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <div className='flex items-center font-bold' style={{ opacity: params?.row?.status ? 1 : 0.4 }}>
            <p>{(params?.row?.avarageHr ?? 0).toFixed(2)}</p>
          </div>
        )
      },

    },
    {
      field: ' totalDoubt',
      headerName: 'Doubts',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        const od = params.row.totalDoubt - params.row.closedDoubt
        return <div className='flex items-center font-bold' style={{ opacity: params?.row?.status ? 1 : 0.4 }}>
          <p className='text-[#FF3333]'>{od}|</p> <p className='text-[#00B05B]'>{params.row.closedDoubt}</p>
        </div>
      },

    },
    {
      field: 'createdAt',
      headerName: 'Created at',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        const date = params.row.createdAt;

        const date1 = date?.split('T')[0]

        return (
          <div style={{ opacity: params?.row?.status ? 1 : 0.4 }}>
            <p>{date1}</p>
          </div>
        )

      },

    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 50,
      renderCell: (params) => {
        const faculty = { ...params.row }

        return <div className='flex items-center gap-1' style={{ opacity: params?.row?.status ? 1 : 0.4 }}>
          <AntSwitch checked={params.row.status} size="small"

            onClick={(e) => { e.stopPropagation(); facultyStatus(params.row._id, params.row.status); }} inputProps={{ 'aria-label': 'ant design' }}
            sx={{ borderColor: '#3366FF' }}
          />
          <EditFaculty row={faculty} getFaculty={getFaculty} onClick={(e) => { e.stopPropagation(); }} style={{ opacity: params?.row?.status ? 1 : 0.4 }} />

        </div>
      },

    },
  ];

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  React.useEffect(() => {

    if (count > 0) {
      xfatchFilterStudent(filterData);
    }

  }, [filterData?.text])

  const xfatchFilterStudent = React.useCallback(debounce((text) => getFaculty(text), 300), []);


  React.useEffect(() => {
    getFaculty()
  }, [])

  const navigate = useNavigate()


  const handleDrawerOpen = () => {
    setFilterOpen(true);
  };


  // DATAGRID STYLES 

  const datagridStylesCss = {

    '& .css-rtrcn9-MuiTablePagination-root': {
      color: theme.palette.headings.main
    },
    '& .css-zylse7-MuiButtonBase-root-MuiIconButton-root.Mui-disabled': {
      color: theme.palette.disabled.main
    },
    '& .css-8nphli': {
      color: theme.palette.headings.main
    },
    '& .css-1deacqj.Mui-disabled': {
      color: theme.palette.disabled.main
    },
    '& .css-1utq5rl': {
      color: theme.palette.headings.main
    },
    '& .css-1j7qk7u': {
      color: theme.palette.headings.main,
    },
    border: 0,
    backgroundColor: theme.palette.bg.adminApp,
    border: `1px solid ${theme.palette.searchBorder.main}`,


    '& .MuiDataGrid-footerContainer': {
      borderTop: `1px solid  ${theme.palette.searchBorder.main}`,
      color: theme.palette.headings.table,
    },

    '& .css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root': {
      color: theme.palette.headings.table,
    },

    '&>.MuiDataGrid-main': {
      '&>.MuiDataGrid-columnHeaders': {

        // backgroundColor: '#55555512',
        backgroundColor: theme.palette.bg.tableHeader,
        color: theme.palette.headings.table,
        fontWeight: '600',
        fontSize: "14px",
        borderBottom: `1px solid ${theme.palette.searchBorder.main}`,
        justifyContent: 'space-between',
      },
      '&>.MuiDataGrid-columnHeaderTitleContainer': {
        border: 'none',
      },

      '& div div div div >.MuiDataGrid-cell': {
        border: 'none',
        fontSize: 14,
        // flexGrow: 1,
      },

      '& .MuiDataGrid-row': {
        cursor: 'pointer',
        marginTop: 1,
        borderRadius: 2,
        color: theme.palette.headings.table
      },

    }

  }


  return (

    <div style={{ backgroundColor: theme.palette.bg.adminApp }} className='flex-1 p-3 flex flex-col  h-full gap-4 '>
      <div className='flex  gap-4  flex-col  justify-between   lg:flex-row'>
        <div>
          <h4
            style={{ color: theme.palette.headings.mode }}
            className='text-lg lg:text-lg font-semibold tracking-wider'
          >
            FACULTY
          </h4>
        </div>
        <div className='flex gap-4  '>
          <Search
            getFaculty={getFaculty}
            filterData={filterData}
            setFilterData={setFilterData}
          />

          <FacultyMap />
          <div className='flex gap-2 items-center   bg-[#EAF2F0] border border-[#E2E2E2] cursor-pointer p-2 px-2'
          // onClick={toggleDrawer(anchor, true)}
          >
            <div onClick={handleDrawerOpen}>
              <FilterAltIcon sx={{ color: '#98D2C5', height: 28, }} />
            </div>
          </div>
          <FacultyFilter
            open={filterOpen}
            getFaculty={() => getFaculty(filterData)}
            faculties={faculties}
            courses={courses}
            setCourses={setCourses}
            filterData={filterData}
            setFilterData={setFilterData}
            allFaculties={faculties}
            removeAll={removeAll}
            allCourses={allCourses}
            onClose={() => setFilterOpen(false)}
          />

          <AddFaculty getFaculty={getFaculty} />
        </div>
      </div>

      {/* Table container */}
      <div
        className='flex-1  max-w-[92vw] lg:max-w-full' ref={dataGridRef}
      >
        <DataGrid
          // autoHeight={true}
          loading={loading}
          disableRowSelectionOnClick
          disableDensitySelector
          getRowId={(row) => row.email}
          onRowClick={(params) => {
            const data = params.row._id
            navigate("facultyDetail", { state: { data } })

          }}

          rows={faculties}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}

          disableColumnMenu
          disableColumnSelector
          disableColumnResize='true'
          sortable

          // hideFooterPagination
          // hideFooterSelectedRowCount
          pageSizeOptions={[5, 10, 15, 20, 30, 50, 75, 100]}

          sx={datagridStylesCss}
        />

      </div>

    </div>


  )
}
