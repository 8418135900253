import * as React from 'react';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
import { showToast } from '../store/Actions/Action';
import { get, post, patch } from '../Services/api-services'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditIcon from '@mui/icons-material/Edit';
import { StringWithSpace, emailRegex, phoneNumberRegex, } from '../Redux/Redux'
import { useTheme } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, InputAdornment, ListItemText, OutlinedInput, } from "@mui/material";
import { mobileRegex } from '../ValidationsRegex';
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


export default function EditFaculty(props) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [facultiesDataByApi, setFacultyDataByApi] = React.useState([])
    const [mobileExistErr, setMobileExistErr] = React.useState(false)
    const [emailExistErr, setEmailExistErr] = React.useState(false)
    const facultyId = props?.row?._id || props?.faculty

    const [open, setOpen] = React.useState(false);

    const [selectedCourseIds, setSelectedCourseIds] = React.useState([]);
    const [selectedCoursesName, setSelectedCoursesName] = React.useState([]);
    const [selectedSubjectIds, setSelectedSubjectIds] = React.useState([]);
    const [selectedSubjectsName, setSelectedSubjectsName] = React.useState([]);
    const [countryCode, setcountryCode] = React.useState(null)
    const [phoneCode, setPhoneCode] = React.useState('');
    const [countryPhoneLength, setCountryPhoneLength] = React.useState('');
    const [isValidMobile, setIsValidMobile] = React.useState(true);
    const [subjectData, setSubjectData] = React.useState([]);
    const [selectedCoutry, setSelectedCoutry] = React.useState(null);

    const textFieldStyle = {

        '& label.Mui-focused': {
            color: theme.palette.headings.main,
        },
        '& label': {
            color: theme.palette.headings.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: theme.palette.headings.main,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#EAEAEA',
            },
            '&:hover fieldset': {
                borderColor: '#EAEAEA',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#EAEAEA',
            },
            color: theme.palette.inputText.main,
            '& .MuiSelect-icon': {
                color: theme.palette.inputText.main
            },
            '& .MuiSelect-select': {
                color: theme.palette.inputText.main
            },
        },
    }

    const [facultyData, setFacultyData] = React.useState({

        email: '',
        name: '',
        mobile: '',
        courses: [],
        subjects: [],
        password: 'apt#123',

    })

    const [courseData, setCourseData] = React.useState([]);

    // GET COURSES DATA 

    const getcourse = async () => {
        const res = await get('courses?filter={"where": {"deleted":false}, "include": [{"relation": "subjects", "scope": {"where": {"deleted": false}}}],"include":"country"}')

        if (res?.statusCode === 200) {

            setCourseData(res?.data)
            const arrays = res?.data?.map((data) => data.id)
            // setSubjectData(res?.data?.map((data)=>data.subjects))
            const resForSubjectApi = await post('subjects/getSubjectByCourse', { courses: arrays })

            if (resForSubjectApi?.statusCode === 200) {
                setSubjectData(resForSubjectApi?.data.subject)


            }
        }
        else {
            console.log(res?.data?.message)
        }
    }

    // GET FACULTY DATA 

    const getFaculty = async (req) => {

        // const res = await post(`faculties/getfacultyList}`)
        const res = await get(`/faculties/${facultyId}?filter={"include":["courses","subjects"]}`)
        getCountreis(res?.data?.countryId);

        if (res?.statusCode === 200) {
            setFacultyDataByApi(res?.data)
            setFacultyData({

                email: res?.data?.email,
                name: res?.data?.name,
                mobile: res?.data?.mobile,
                courses: res?.data?.courses.map((course) => course.id),
                subjects: res?.data?.subjects.map((sub) => sub.id),
                password: 'Eulogik#123'

            })

            setSelectedCourseIds(res?.data?.courses.map((course) => course.id))
            setSelectedCoursesName(res?.data?.courses.map((course) => course.name))
            setSelectedSubjectIds(res?.data?.subjects.map((sub) => sub.id))
            setSelectedSubjectsName(res?.data?.subjects.map((sub) => sub.name))
        }


    }

    // GET ALL COUTNRIES 

    const getCountreis = async (countryId) => {
        try {
            const res = await post('countries/getCountry');
            setcountryCode(res?.data?.data);
            // Find the country with code '91'
            const indiaCountry = res?.data?.data.find(country => country?.id === countryId);
            setPhoneCode(indiaCountry?.phoneCode)
            setSelectedCoutry(indiaCountry)
            setCountryPhoneLength(indiaCountry?.phoneLength)

            // If '91' country is found, store its details in a separate state
            if (indiaCountry) {
                // setIndiaCountry(indiaCountry);
            }
        } catch (e) {
            console.log(e);
        }
    };

    React.useEffect(() => {
        if (open) {
            getcourse()
            getFaculty()
            // getCountreis()
        }
    }, [open]);


    // EDIT FACULTY 

    const EditFacultty = async () => {
        try {
            const id = props?.row?._id || props?.faculty;
            const apiPayload = {
                email: facultyData.email,
                name: facultyData.name,
                mobile: facultyData.mobile,
                courses: facultyData.courses,
                subjects: facultyData.subjects,
                password: facultyData.password,
                country: selectedCoutry?.id,
            };
            const res = await patch(`faculties/${id}/editFaculty`, apiPayload)
            if (res?.data?.statusCode == 200) {
                const _snackbar = {
                    message: 'Faculty Edited Succesfully',
                    open: true,
                    duration: 3000,
                    type: 'success'
                };

                dispatch(showToast(_snackbar))
                if (props?.row?._id) {
                    props.getFaculty();
                } else {
                    props.getFacultyDetail();
                }
                handleClose();
            } else {
                console.log(res?.data?.message);
                const data = res?.data?.message?.split('`')[4];
                const errorMessage = data?.split('(')[0];
                const dupticateString = data?.split('(')[1].split(':')[1];
                const duplicate = dupticateString.match(/\d+/)[0];
                const finalErrorMessage = errorMessage + "with this" + duplicate;

                const _snackbar = {
                    message: data,
                    open: true,
                    duration: 3000,
                    type: 'error'
                };

                dispatch(showToast(_snackbar))

            }

        } catch (e) {
            console.log(e, 'error');
        }
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // HANDLE COUTRY COURSES 

    const handleChangeCourse = async (value) => {


        const __index = selectedCoursesName?.indexOf(value.name);
        const __indexG = selectedCourseIds?.indexOf(value.id);

        if (__indexG >= 0) {
            let array = [...selectedCoursesName];
            array.splice(__index, 1)
            setSelectedCoursesName(array);
            let arrays = [...selectedCourseIds];
            arrays.splice(__indexG, 1)
            setSelectedCourseIds(arrays);
            const res = await post('subjects/getSubjectByCourse', { courses: arrays })

            if (res?.statusCode === 200) {
                setSubjectData(res?.data.subject)
                setFacultyData({ ...facultyData, courses: [...arrays] })

            } else {

            }
        } else {
            const array = [...selectedCoursesName, value.name];
            setSelectedCoursesName(array);
            const arrays = [...selectedCourseIds, value.id];
            setSelectedCourseIds(arrays);
            const res = await post('subjects/getSubjectByCourse', { courses: arrays })

            if (res?.statusCode === 200) {
                setSubjectData(res?.data.subject)
                setFacultyData({ ...facultyData, courses: [...arrays] })

            } else {

            }
        }
        setSelectedSubjectIds([]);
        setSelectedSubjectsName([]);
    }

    // HANDLE COUTRY SUBJECTS 

    const handleChangeSubject = async (value) => {


        const __index = selectedSubjectsName?.indexOf(value.name);
        const __indexG = selectedSubjectIds?.indexOf(value._id);

        if (__indexG >= 0) {
            let array = [...selectedSubjectsName];
            array.splice(__index, 1)
            setSelectedSubjectsName(array);
            let arrays = [...selectedSubjectIds];
            arrays.splice(__indexG, 1)
            setSelectedSubjectIds(arrays);
            setFacultyData({ ...facultyData, subjects: [...arrays] })


        } else {
            const array = [...selectedSubjectsName, value.name];
            setSelectedSubjectsName(array);
            const arrays = [...selectedSubjectIds, value._id];
            setSelectedSubjectIds(arrays);

            setFacultyData({ ...facultyData, subjects: [...arrays] })



        }


    }

    // HANDLE COUTRY CHANGE 

    const handleChange = (event) => {

        const selectedPhoneCode = event.target.value;
        const selectedCountry = countryCode.find(country => country.phoneCode === selectedPhoneCode);
        setSelectedCoutry(selectedCountry)
        // Update the state for phone code
        setPhoneCode(selectedPhoneCode);

        // Update the state for PhoneLenmgth
        setCountryPhoneLength(selectedCountry?.phoneLength);

        if (facultyData.mobile?.length === selectedCountry?.phoneLength) {
            setIsValidMobile(true)
        } else {
            setIsValidMobile(false)
        }

    };


    return (
        <React.Fragment>
            <EditIcon
                size="small"
                sx={{ color: '#98D2C5', cursor: 'pointer' }}
                onClick={(e) => {
                    handleClickOpen()
                    e.stopPropagation();
                }}

            />
            {open ?
                <Dialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                    fullWidth

                >
                    <div style={{ backgroundColor: theme.palette.bg.dialog }} className='w-full  '>

                        <DialogTitle
                            sx={{
                                m: 0, p: 2,
                                backgroundColor: theme.palette.bg.secondary,
                                color: theme.palette.headings.main,
                                borderBottom: `1px solid ${theme.palette.searchBorder.main}`
                            }}
                            id="customized-dialog-title">
                            EDIT FACULTY MEMBER
                        </DialogTitle>
                        <IconButton
                            aria-label="close"

                            onClick={(e) => { e.stopPropagation(); handleClose(); }}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <DialogContent dividers fullWidth>

                            <div className="flex pt-8 pb-2 flex-col gap-10 lg:w-full  ">
                                <div className='flex flex-col gap-10 lg:flex-row'>
                                    <FormControl className='text-xs w-full lg:w-1/2'>

                                        <TextField
                                            className="text-[#A9ABB5] text-xs"

                                            sx={textFieldStyle}
                                            size="small"
                                            type="text"
                                            error={
                                                facultyData?.name?.length > 0 &&
                                                !StringWithSpace?.test(facultyData.name)
                                            }
                                            helperText={
                                                facultyData?.name?.length > 0 &&
                                                    !StringWithSpace?.test(facultyData.name)
                                                    ? "enter valid name"
                                                    : ""
                                            }
                                            value={facultyData.name}
                                            label="Name"
                                            required
                                            onChange={(e) =>
                                                setFacultyData((prevState) => ({
                                                    ...prevState,
                                                    name: e.target.value,
                                                }))
                                            }
                                        />
                                    </FormControl>
                                    <FormControl className='text-xs w-full lg:w-1/2'>

                                        <TextField
                                            className="text-[#A9ABB5] text-xs"

                                            size="small"
                                            type="text"
                                            sx={textFieldStyle}
                                            // onBlur={emailAlreadyExist}
                                            onChange={(e) =>
                                                setFacultyData((prevState) => ({
                                                    ...prevState,
                                                    email: e.target.value,
                                                }))
                                            }
                                            error={
                                                facultyData?.email?.length > 0 &&
                                                !emailRegex?.test(facultyData.email)
                                            }

                                            helperText={
                                                facultyData?.email?.length > 0 &&
                                                    !emailRegex?.test(facultyData.email)
                                                    ? "enter valid email id"
                                                    : ""
                                            }
                                            value={facultyData.email}
                                            label="Email"
                                            required

                                        />
                                        {emailExistErr && <FormHelperText sx={{ color: 'red' }}>
                                            Email already exist.
                                        </FormHelperText>}
                                    </FormControl>

                                </div>
                                <div className='flex flex-col gap-10 lg:flex-row'>

                                    <FormControl className='text-xs w-full lg:w-1/2' required size='small' sx={textFieldStyle}>
                                        <InputLabel id="demo-multiple-checkbox-label">Country </InputLabel>
                                        <Select

                                            labelId="demo-multiple-checkbox-label"
                                            displayEmpty
                                            value={phoneCode}
                                            onChange={handleChange}
                                            // input={<OutlinedInput />}
                                            // MenuProps={MenuProps}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem disabled value={phoneCode}>
                                                <em>
                                                    <h6 className='text-lg'>+{phoneCode}</h6>
                                                </em>
                                            </MenuItem>
                                            {countryCode?.map((country, index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={country?.phoneCode}
                                                // style={getStyles(name, personName, theme)}
                                                >
                                                    <h6 className='text-lg'>+{country?.phoneCode}</h6>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <FormControl className='text-xs w-full lg:w-1/2'>
                                        <TextField
                                            className="text-[#A9ABB5] text-xs"

                                            size="small"
                                            type="text"
                                            sx={textFieldStyle}
                                            // onBlur={mobileAlreadyExist}
                                            onChange={(e) => {
                                                // mobileAlreadyExist(e?.target?.value);
                                                setFacultyData((prevState) => ({
                                                    ...prevState,
                                                    mobile: e.target.value,
                                                }));
                                                setIsValidMobile(() =>
                                                    e?.target?.value?.length === countryPhoneLength
                                                        ? true
                                                        : false
                                                );
                                            }
                                            }
                                            helperText={
                                                facultyData.mobile?.length > 0 && !isValidMobile
                                                    // !mobileRegex?.test(mobile)
                                                    ? `mobile no. should contains ${countryPhoneLength} Digit`
                                                    : ""
                                            }
                                            error={
                                                facultyData.mobile?.length > 0 && !isValidMobile
                                                    // !mobileRegex?.test(mobile))
                                                    ? `mobile no. should contains ${countryPhoneLength} Digit`
                                                    : ""
                                            }
                                            value={facultyData.mobile}
                                            label="Mobile No."
                                            required

                                        />
                                    </FormControl>

                                </div>

                                <div className='flex flex-col gap-10 lg:flex-row'>
                                    <FormControl className='text-xs w-full lg:w-1/2' required size='small' sx={textFieldStyle}>

                                        <InputLabel id="demo-multiple-checkbox-label">Course</InputLabel>
                                        <Select
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            multiple
                                            value={selectedCoursesName}
                                            input={<OutlinedInput label="Course" />}
                                            renderValue={(selected) => selected.join(', ')}
                                            MenuProps={MenuProps}
                                        >
                                            {courseData?.map((item) => (

                                                <MenuItem key={item.name} value={item} onClick={() => handleChangeCourse(item)}>
                                                    <Checkbox
                                                        style={{
                                                            color: "#98D2C5",
                                                        }}
                                                        checked={selectedCourseIds?.indexOf(item?.id) > -1} />

                                                    {item.name}  ({item?.country?.currencyName})
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <FormControl className='text-xs w-full lg:w-1/2' required size='small' sx={textFieldStyle}>

                                        <InputLabel id="demo-multiple-checkbox-label">Subject</InputLabel>
                                        <Select
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            multiple
                                            value={selectedSubjectsName}
                                            input={<OutlinedInput label="Course" />}
                                            renderValue={(selected) => selected.join(', ')}
                                            MenuProps={MenuProps}
                                        >
                                            {subjectData?.map((item) => (

                                                <MenuItem key={item?.name} value={item} onClick={() => handleChangeSubject(item)}>
                                                    <Checkbox
                                                        style={{
                                                            color: "#98D2C5",
                                                        }}
                                                        checked={selectedSubjectIds?.indexOf(item?._id) > -1} />

                                                    <ListItemText primary={`${item?.name} (${item?.courses?.name})`} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                        </DialogContent>
                        <DialogActions className='text-[#E4E4E433 pr-5'>
                            {/* <Button autoFocus onClick={handleClose}>
                        Save changes
                    </Button> */}
                            <ToastContainer />
                            <button
                                disabled={facultyData.name && facultyData.email && facultyData.mobile && selectedCoursesName.length >= 1 && selectedSubjectsName.length >= 1 &&
                                    StringWithSpace?.test(facultyData.name) && emailRegex?.test(facultyData.email) && isValidMobile ? false : true
                                }
                                className='flex gap-2 items-center cursor-pointer bg-[#98D2C5] p-3 px-5 text-[#FFFFFF] disabled:opacity-50' onClick={EditFacultty}>

                                <Typography>SAVE</Typography>
                            </button>
                        </DialogActions>
                    </div>
                </Dialog> : null}
        </React.Fragment>
    );
}