import { socket } from "./Index";

export const emitNewMessage = (data) => {
    socket.emit('new message', data);
};

export const getAllDoubt = () => {
    socket.emit('get Doubt');
};

export const setUpSocket = (userId) => {
    // console.log(userId);
    socket.emit('setUp', userId);
}

export const statusChange = (_data) => {
    socket.emit('statusChange',_data);
};
