import axios from "axios";

export let _localStorage = {};

export const updateLocalStorage = async (data) => {
  // UPDATE LOCAL STORAGE
  _localStorage = data;
  if (localStorage) {
    localStorage.removeItem("studentData");
    window.location.replace(window.location?.pathname);
  }
};

// RETURN TOKEN ACCORDING TO CURRENT PANEL USER
const returnToken = () => {

  let token = localStorage.getItem('studentData');
  token = token ? JSON.parse(token) : null
  // console.log(window?.location?.pathname?.split("/")?.includes('admin'))
  if (window?.location?.pathname?.split("/")?.includes('admin')) {
    return token?.admin?.id
  }
  else if (window?.location?.pathname?.split("/")?.includes('faculty')) {
    return token?.faculty?.id;
  }
  else {
    return token?.student?.id
  }

};

export const baseUrl = `https://doubts-api.aptstudy.com/api/`;
export const socketUrl = 'https://doubts-api.aptstudy.com/'

// export const baseUrl = `http://192.168.1.11:3100/api/`;
// export const socketUrl = 'http://192.168.1.11:3100'

// export const baseUrl = `http://143.110.186.226:3100/api/`;
// export const socketUrl = 'http://143.110.186.226:3100'

// export const baseUrl = `http://localhost:3010/api/`;
// export const socketUrl = 'http://localhost:3010'


// INTERCEPTORS FOR REQUESTS 

axios.interceptors.request.use(
  function (config) {
    config.headers.Authorization = returnToken();
    // console.log(config.headers.Authorization);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const post = async (url, data) => {
  try {
    const res = await axios.post(baseUrl + url, data);
    return { statusCode: res?.status, data: res.data };
  } catch (e) {
    if (e?.response?.data?.error?.statusCode === 401) {
      updateLocalStorage();
    }

    return {
      statusCode: e?.response?.data?.error?.statusCode || 404,
      message:
        e?.response?.data === undefined
          ? e?.message
          : e?.response?.data?.error?.message,
    };
  }
};


export const processFileName = (fileName, count) => {
  // Splitting the file name by '.' to get the name and extension
  const [name, extension] = fileName.split('.');

  // Extracting the first and last 5 characters of the name
  const startChars = name.slice(0, count);
  const endChars = name.slice(-count);

  // Creating the new file name by combining startChars, endChars, and extension
  const newFileName = `${startChars}_${endChars}.${extension}`;

  return newFileName;
};

export const patch = async (url, data) => {
  try {
    const res = await axios.patch(baseUrl + url, data);
    return { statusCode: res.status, data: res.data };
  } catch (e) {
    if (e?.response?.data?.error?.statusCode === 401) {
      updateLocalStorage();
    }
    return {
      statusCode: e?.response?.data?.error?.statusCode || 404,
      message:
        e.response.data === undefined
          ? e?.message
          : e?.response?.data?.error?.message,
    };
  }
};

export const get = async (url) => {
  try {
    const res = await axios.get(baseUrl + url);
    return { statusCode: res.status, data: res.data };
  } catch (err) {
    if (err?.response?.data?.error?.statusCode === 401) {
      updateLocalStorage();
    }
    console.log(err);
  }
};

export const deletethis = async (url) => {
  try {
    const res = await axios.delete(baseUrl + url);
    return { statusCode: res.status, data: res.data };
  } catch (err) {
    if (err?.response?.data?.error?.statusCode === 401) {
      updateLocalStorage();
    }
  }
};

export const yyyymmdd = (date) => {
  const _date = {
    y: new Date(date).getFullYear(),
    m: new Date(date).getMonth() + 1 < 10 ? `0${new Date(date).getMonth() + 1}` : new Date(date).getMonth(),
    d: new Date(date).getDate() < 10 ? `0${new Date(date).getDate()}` : new Date(date).getDate()
  }

  return `${_date.y}-${_date.m}-${_date.d}`
}


