import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';
import { AttachmentIcon, CrossIcon, RightArrow, SelectMedia } from '../StudentComponents/Icons';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import AudioRecoderComponent from '../StudentComponents/AudioRecoderComponent';
import { post, processFileName } from '../../Services/api-services';
import { statusChange } from '../../Socket/Emit';


const customPlaceholderStyle = {
  color: 'red', // Change the color to your desired color
  fontFamily: 'Arial, sans-serif', // Change the font family to your desired font
};



const CustomTextarea = styled(TextareaAutosize)(({ theme }) => ({
  width: '100%',
  // border: `1px solid ${theme.palette.searchBorder.main}`,
  border: `1px solid ${theme.palette.askDoubt.main}`,
  backgroundColor: 'transparent',
  // color: 'black',
  resize: 'none',
  fontWeight: '100',
  padding: theme.spacing(1),
  '&::placeholder': {
    color: '#AEA5A5D9',
  },
  '&:focus': {
    outline: 'none', // Remove the outline
    boxShadow: 'none', // Remove the box-shadow
  },
}));

const FileImg = require('../../Assets/Images/FileImg.png')

export default function AskDoubt(props) {


  const _form = {
    courseId: '',
    subjectId: '',
    facultyId: '',
    studentId: props.studentId,
    title: '',
    description: {
      message: '',
      images: [],
      documents: [],
      audio: ''
    }
  }

  const dispatch = useDispatch();
  const navigate = useNavigate()
  // handle Password Visiblity

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // handle Password Visiblity

  const [askDoubtForm, setAskDoubtForm] = React.useState({ ..._form })
  const [isLoading, setIsLoading] = React.useState(false)
  const [subjects, setsubjects] = React.useState([])
  const [faculties, setFaculties] = React.useState([])
  const [image, setImage] = React.useState([])
  const [docs, setDocs] = React.useState([])
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [audio, setaudio] = React.useState(null)
  const imageRef = React.useRef(null)
  const docsRef = React.useRef(null)
  const [isRecord, setIsRecord] = React.useState(false)

  console.log(image);

  // UPDATE STATE ON FORM CHANGE

  const onFormChange = (name, value) => {
    try {
      const form = { ...askDoubtForm }
      if (name === 'description') {
        form[name].message = value;
      } else {
        form[name] = value;
      }
      setAskDoubtForm(form)
    }
    catch (e) {
      console.log(`ERROR WHILE FORM CHANGE --- ${e}`)
    }
  }


  // LOADING BUTTON STYLE

  const loadingButtonStyle = {

    padding: isMobile ? '1vmax 4vmax' : '0.6vmax 3vmax',
    boxShadow: 'none',
    backgroundColor: '#98D2C5',
    borderRadius: isMobile ? '1vw' : '.3vmax',
    // width: isMobile ? '80%' : '50%',
    width: 'fit-content',
    '&:hover': {
      backgroundColor: '#98D2C5',
      boxShadow: 'none'
    },
    '& .css-1849g51-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root.Mui-disabled': {
      backgroundColor: 'red'
    },
    // .css-9ch1t4-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root.Mui-disabled {
    //   background-color: gray !important;
    //   color: white !important;
    // }

  };

  // TEXTFIELDS STYLE

  const inputStyle = {
    '& .MuiInputBase-root': {
      border: `1px solid ${theme.palette.askDoubt.main}`,
      [theme.breakpoints.down('sm')]: {
        fontSize: '2vmax', // Font size for large screens

      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '.8vmax', // Font size for large screens
      },
      color: theme.palette.inputText.main
    },

  }

  // GET ALL SUBJECTS OF THE COURSE/

  const GetSubjects = async (courseId) => {
    try {
      const data = {
        caseId: props.studentId,
        courseId: courseId
      }
      const res = await post('subscribedSubjects/getSubjectByCourse', data);
      if (res?.data) {
        setsubjects(res.data?.subject)
      }
    } catch (e) {
      console.log(`ERROR WHILE FETCHING SUBJECTS --- ${e}`)
    }
  }

  // GET ALL FACULTY OF THE COURSE/

  const GetFaculties = async (subjectId) => {
    try {
      const data = {
        studentId: props.studentId,
        subjectId: subjectId
      }
      const res = await post('cases/getStudentSubjectFaculty', data);
      if (res?.data) {
        setFaculties(res?.data)
      }
    } catch (e) {
      console.log(`ERROR WHILE FETCHING SUBJECTS --- ${e}`)
    }
  }

  // UPLOADS FILES IN ASK DOUBT FORM

  const uploadFiles = async (fileType, files) => {
    try {

      let formdata = new FormData();

      if (fileType == 'audios') {
        formdata.append("file", files, new Date().getTime() + '.mp3');
      } else {
        for (let index = 0; index < files?.length; index++) {
          formdata.append("file", files[index], files[index]?.name);
        }
      }

      return post(`uploads/${fileType}/upload`, formdata);
      // return response;

    } catch (error) {
      console.error(`Error uploading ${fileType}:`, error);
      throw error; // Re-throw the error so that it can be caught by the calling function
    }
  };

  // UPDATE THE DATA FIR THE ASKDOUBT API BODY

  const updateData = async () => {
    return new Promise(async (resolve, reject) => {
      try {

        setIsLoading(true)
        const _upload = {
          audio: {
            is: audio ? true : false,
            up: false,
            file: []
          },
          docs: {
            is: docs ? true : false,
            up: false,
            file: []
          },
          img: {
            is: image ? true : false,
            up: false,
            file: []
          },
        }

        if (image) {

          const imageUploadResponse = await uploadFiles('images', image);
          console.log(imageUploadResponse);
          _upload.img.is = true
          _upload.img.up = true
          _upload.img.file = imageUploadResponse?.data?.result?.files?.file.map(item => item?.name)

        }

        if (docs) {
          const documentUploadResponse = await uploadFiles('documents', docs);
          _upload.docs.is = true
          _upload.docs.up = true
          _upload.docs.file = documentUploadResponse?.data?.result?.files?.file.map(item => item?.name)

        }
        if (audio) {
          const audioUploadResponse = await uploadFiles('audios', audio);
          _upload.audio.is = true
          _upload.audio.up = true
          _upload.audio.file = audioUploadResponse?.data?.result?.files?.file[0]?.name
        }

        if ((_upload.audio.is ? _upload.audio.up : true) && (_upload.docs.is ? _upload.docs.up : true) && (_upload.img.is ? _upload.img.up : true)) {
          const body = { ...askDoubtForm }
          body.description.audio = _upload.audio.file
          body.description.images = _upload.img.file
          body.description.documents = _upload.docs.file
          resolve(body)
        }
      }
      catch (e) {
        reject(e)
      }
    })
  }

  // ASK DOUBT 

  const askdoubt = async () => {

    try {
      const body = await updateData();
      const response = await post('doubts/addNewDoubt', body)
      setIsLoading(false)
      if (response?.statusCode === 200) {
        props.onClose()
        props?.onAddDoubt();
        statusChange({ update: 'update status' });
      }
      props?.setaudio(null);
    } catch (e) {
      console.log(e);
    }

  }

  const removeImageAtIndex = (indexToRemove, type) => {
    if (type === 'image') {
      setImage((prevImageUrls) =>
        prevImageUrls.filter((_, index) => index !== indexToRemove)
      );
      if (imageRef.current) {
        imageRef.current.value = null;
      }
    } else {
      setDocs((prevImageUrls) =>
        prevImageUrls.filter((_, index) => index !== indexToRemove)
      );
      if (docsRef.current) {
        docsRef.current.value = null;
      }
    }
  };

  // onRemove={(index) => removeImageAtIndex(index)}

  return (

    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      // open='true'
      onClose={() => props.onClose()}
      maxWidth='sm'
      fullWidth
      sx={{
        overflow: 'hidden',
        '& .css-m9glnp-MuiPaper-root-MuiDialog-paper': {
          backgroundColor: `${theme.palette.bg.dialog}`,
          border: `1px solid ${theme.palette.askDoubt.main}`,
          // boxShadow: 'red 0px 3px 8px'
        }
      }}
      aria-labelledby="responsive-dialog-title"
      BackdropProps={{ invisible: true }}
    >
      <div
        style={{
          backgroundColor: theme.palette.bg.dialog,
          border: `1px solid ${theme.palette.askDoubt.main}`,
        }}
        className='w-full  flex'>
        <div className='w-full h-full'>
          <DialogTitle fullWidth id="responsive-dialog-title">
            <div className='flex  items-center justify-start gap-3 py-2 lg:py-1'>
              <div className='block lg:hidden' onClick={() => props.onClose()}>
                <RightArrow />
              </div>
              <h6 style={{ color: theme.palette.headings.main }} className=' font-semibold tracking-wider text-xl'>Ask Doubt</h6>
              <CloseIcon className='!hidden' onClick={() => props.onClose()} sx={{ position: "absolute", top: "4%", right: "1%", transform: 'translate(-50%,-50%)' }} />
            </div>
          </DialogTitle>
          <DialogContent fullWidth >
            <div className='input_area  py-6  w-full h-full   lg:px-4 lg:py-3'>
              <div className='w-full flex flex-col gap-5 '>
                <div className='w-full flex flex-col items-start gap-3 lg:gap-2'>
                  <h6 style={{ color: theme.palette.inputText.main }} className='font-normal lg:font-medium tracking-wider text-sm lg:text-xs'>Course </h6>

                  <FormControl fullWidth size='small' >
                    <Select
                      // value={singupForm?.gender}
                      onChange={(e) => {
                        onFormChange('courseId', e?.target?.value);
                        GetSubjects(e?.target?.value);
                      }}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      fullWidth
                      size='small'
                      sx={{
                        fontSize: { lg: '.9vmax' },
                        border: `1px solid ${theme.palette.askDoubt.main}`,
                        color: theme.palette.inputText.main,
                        '& .MuiSelect-icon': {
                          color: theme.palette.inputText.main
                        },
                        '& .MuiSelect-select': {
                          color: theme.palette.inputText.main
                        },

                      }}
                    >
                      <MenuItem disabled selected>
                        <em>Select Course</em>
                      </MenuItem>
                      {props?.courses?.map((course, idx) => (
                        <MenuItem value={course?.id}>{course?.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                </div>
                <div className='w-full flex flex-col items-start gap-3 lg:gap-2'>
                  <h6 style={{ color: theme.palette.inputText.main }} className='font-normal lg:font-medium tracking-wider text-sm lg:text-xs'>Subject</h6>

                  <FormControl fullWidth size='small' >
                    <Select
                      // value={singupForm?.gender}
                      onChange={(e) => {
                        onFormChange('subjectId', e?.target?.value);
                        GetFaculties(e?.target?.value);
                      }}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      fullWidth
                      size='small'
                      sx={{
                        fontSize: { lg: '.9vmax' },
                        border: `1px solid ${theme.palette.askDoubt.main}`,
                        color: theme.palette.inputText.main,
                        '& .MuiSelect-icon': {
                          color: theme.palette.inputText.main
                        },
                        '& .MuiSelect-select': {
                          color: theme.palette.inputText.main
                        },
                      }}
                    >
                      <MenuItem disabled selected>
                        <em>Select Subject</em>
                      </MenuItem>
                      {subjects?.map((subject, idx) => (
                        <MenuItem value={subject?._id}>{subject?.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                </div>
                <div className='w-full flex flex-col items-start gap-3 lg:gap-2'>
                  <h6 style={{ color: theme.palette.inputText.main }} className='font-normal lg:font-medium tracking-wider text-sm lg:text-xs'>Faculty member</h6>

                  <FormControl fullWidth size='small' >
                    <Select
                      // value={singupForm?.gender}
                      onChange={(e) => onFormChange('facultyId', e?.target?.value)}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      fullWidth
                      size='small'
                      sx={{
                        fontSize: { lg: '.9vmax' },
                        border: `1px solid ${theme.palette.askDoubt.main}`,
                        color: theme.palette.inputText.main,
                        '& .MuiSelect-icon': {
                          color: theme.palette.inputText.main
                        },
                        '& .MuiSelect-select': {
                          color: theme.palette.inputText.main
                        },
                      }}
                    >
                      <MenuItem disabled selected>
                        <em>Select Faculty member</em>
                      </MenuItem>
                      {faculties?.map((faculty, idx) => (
                        <MenuItem value={faculty?.id}>{faculty?.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                </div>
                <div className='w-full flex flex-col items-start gap-3 lg:gap-2'>

                  <h6 style={{ color: theme.palette.inputText.main }} className='font-normal lg:font-medium tracking-wider text-sm lg:text-xs'>Title</h6>
                  <TextField
                    id="input-with-icon-textfield"
                    placeholder='Enter Title'
                    helperText={askDoubtForm?.title?.length > 0 && /^\s/.test(askDoubtForm?.title.charAt(0)) ? "Please Enter Valid Email" : ""}
                    // error={askDoubtForm?.title?.length > 0 && /^\s/.test(askDoubtForm?.title.charAt(0))}
                    error={askDoubtForm?.title?.length > 0 && !askDoubtForm?.title?.trim()}
                    fullWidth
                    // variant="standard"
                    size='small'
                    className='custom-placeholder border !border-[F4F4F4] !shadow-none '
                    sx={inputStyle}
                    type='email'
                    // value={signinForm.email}
                    onChange={(e) => onFormChange('title', e?.target?.value)}

                  />

                </div>

                <div className='w-full flex flex-col items-start gap-3 lg:gap-2'>

                  <h6 style={{ color: theme.palette.inputText.main }} className='font-normal lg:font-medium tracking-wider text-sm lg:text-xs'>Description</h6>
                  <CustomTextarea
                    className='rounded-sm'
                    minRows={4}
                    // maxRows={10}
                    sx={{
                      color: theme.palette.inputText.main
                    }}
                    value={askDoubtForm?.description?.message}
                    onChange={(e) => onFormChange('description', e?.target?.value)}
                    placeholder="Type Your Message Here...."
                  />
                </div>

                <div className='flex items-center gap-3'>
                  <div onClick={() => imageRef.current.click()} >
                    <SelectMedia color={theme.palette.askDoubt.main} />
                  </div>
                  <input
                    ref={imageRef}
                    accept="image/*"
                    type="file"
                    name="myImage"
                    onChange={(event) => {
                      let temp = true;
                      let newImages = [];
                      console.log(event);
                      for (let index = 0; index < event?.target?.files?.length; index++) {
                        const selectedFile = event?.target?.files[index];
                        console.log(selectedFile);

                        // Check if the file type is an image
                        if (selectedFile?.type?.split('/')[0] === 'image') {
                          // Check if the image is not already in the state
                          console.log(selectedFile.name);
                          console.log(image);
                          if (!image.find((img) => img.name === selectedFile.name)) {
                            console.log('not matched');
                            newImages.push(selectedFile);
                          } else {
                            console.log('Image already exists:', selectedFile.name);
                          }
                        } else {
                          temp = false;
                          // console.log('Wrong data');
                        }
                      }

                      if (temp) {
                        setImage((prevImages) => [...prevImages, ...newImages]);
                      } else {
                        // console.log('Wrong data!');
                      }
                    }
                    }
                    multiple
                    className="!hidden"
                  />

                  <div onClick={() => docsRef.current.click()} >
                    <AttachmentIcon color={theme.palette.askDoubt.main} />
                  </div>
                  <input
                    ref={docsRef}
                    type="file"
                    name="myImage"
                    onChange={(event) => {
                      const newDocs = Array.from(event.target.files);
                      setDocs((prevDocs) => {
                        // Filter out files that are already present in the state
                        const uniqueDocs = newDocs.filter((newDoc) => {
                          return !prevDocs.some((prevDoc) => prevDoc.name === newDoc.name);
                        });

                        return [...prevDocs, ...uniqueDocs];
                      });

                    }}
                    multiple
                    className="!hidden"
                  />
                  {/* <AudioRecoderComponent audio={audio} setaudio={setaudio} /> */}
                  <div className={`px-2  ${isRecord || audio ? 'w-full' : 'w-fit'}  `}>
                    <AudioRecoderComponent
                      audio={audio}
                      image={props?.image}
                      setaudio={setaudio}
                      setimage={props?.setImage}
                      isRecord={isRecord}
                      setIsRecord={setIsRecord}
                      onAudioSend={props.onSendImages}
                      mediaType={props?.mediaType}
                      setMediaType={props?.setMediaType}
                      isAsk={true}
                    />
                  </div>
                </div>
                <div className='w-full flex items-center justify-between lg:justify-start gap-1 lg:gap-5 flex-wrap'>
                  {image?.map((file, idx) => (
                    <div key={idx} className='w-[48%] lg:w-[30%] relative h-24 rounded'>
                      <img className='w-full rounded h-full object-contain' src={URL.createObjectURL(file)} alt={file.name} />
                      <div onClick={() => removeImageAtIndex(idx, 'image')} className='absolute  top-[-6%] right-[-5%] z-20'>
                        <CrossIcon />
                      </div>
                    </div>
                  ))}
                </div>

                <div className='w-full flex flex-wrap items-start justify-between lg:justify-start gap-5'>
                  {docs?.map((file, idx) => (
                    <div className='w-[47%] lg:w-[30%] relative flex h-12 items-center rounded bg-[#F1F1F1]'>
                      <div className='bg-[#98D2C5] flex items-center justify-center rounded-s w-[40%] h-full'>
                        <img src={FileImg} alt="FileImg" />
                      </div>
                      <div className='h-full w-[70%] flex items-center justify-center'>
                        <h6 className='text-sm lg:text-xs whitespace-nowrap'>{processFileName(file?.name, 3)}</h6>
                      </div>
                      <div onClick={() => removeImageAtIndex(idx, 'docs')} className='absolute top-[-10%] right-[-4%]'>
                        <CrossIcon />
                      </div>
                    </div>
                  ))}

                </div>

                <div className='w-full flex items-end justify-end py-4 '>
                  <LoadingButton
                    loading={isLoading}
                    sx={loadingButtonStyle}
                    onClick={askdoubt}
                    fullWidth
                    disabled={
                      !(askDoubtForm?.courseId && askDoubtForm?.title && askDoubtForm?.subjectId && askDoubtForm?.studentId && askDoubtForm?.facultyId && askDoubtForm?.description?.message)
                    }
                    variant="contained"
                    size="small"
                  >
                    {/* <h6 style={{ color: theme.palette.headings.main }} >Submit</h6> */}
                    Submit
                  </LoadingButton>
                </div>

              </div>
            </div>
          </DialogContent>
        </div>
      </div>

    </Dialog>
  )
}