import { socket } from './Index';

export const socketEvents = ({ setValue }) => {

    socket.on('message recieved', (_messages) => {
        setValue(state => { return { ...state, _messages } })
    })


    socket.on('doubts received', (_messages) => {
        setValue(state => { return { ...state, _messages } })
    })


    socket.on('statusChanged', (_doubts) => {
        // console.log(_doubts)
        setValue(state => { return { ...state, _doubts } })
    })

};