import React from 'react'
import HomePageHeader from './HomePageHeader'
import { useTheme } from '@mui/system';
import { post } from '../../Services/api-services';
import moment from 'moment/moment';
import { DisLikeIcon, LikeIcon } from './Icons';
import { adminAuthState, currentRole, facultyAuthState, studentAuthState } from '../../Services/RecoilService';
import { useRecoilState } from 'recoil';

const NodoubtImg = require('../../Assets/Images/NoDoubtLight.png')
const NoDoubtDarkImg = require('../../Assets/Images/NodoubtMobile.png')

// const showIndiaTime = (time) => {
//   return new Date(time).getTime() + 19800000
// }

const timeFormat = (value) => {
  return moment(value).format('DD/MM/YYYY hh:mm A ');
}


const ChatList = (props) => {
  const theme = useTheme();
  const [role, setRole] = useRecoilState(currentRole)
  const [authState, setAuthState] = useRecoilState(studentAuthState)
  const [_facultyAuthState, set_FacultyAuthState] = useRecoilState(facultyAuthState)
  const [_adminAuthState, set_AdminAuthState] = useRecoilState(adminAuthState)
  const headRef = React.useRef(null);
  const divRef = React.useRef(null);
  const [chatListHeight, setChatListHeight] = React.useState(null)


  const truncateTitle = (title) => {
    if (title && title.length > 20) {
      return title.slice(0, 24) + '...';
    }
    return title;
  };

  React.useEffect(() => {
    setChatListHeight(divRef?.current?.offsetHeight - headRef?.current?.offsetHeight - 70)
  }, [headRef?.current]);

  return (
    <div
      ref={divRef}
      style={{ backgroundColor: theme.palette.bg.secondary }} className='w-full h-full flex flex-col gap-4'>
      <div
        ref={headRef}
      >

        {(props?.role === 'faculty' || props?.role === 'student') && props?.userStatus === false && (
          <div className='w-full h-14 bg-[#FFE9E9] border-b border-[#FF3333] flex items-center justify-center'>
            <h6 className='text-[#FF3333] text-sm text-center'>Admin has disabled you to use this panel, please ask admin to provide you  panel access!</h6>
          </div>
        )}

        <HomePageHeader
          onAskDoubt={props.onchange}
          userDets={props.userDets}
          onFilterOpen={props?.onFilter}
          role={props?.role}
          isMedia={props?.isMedia}
          setIsMedia={props?.setIsMedia}
          onSearch={props?.getDoubtDetails}
          onUploadProfile={props?.uploadImage}
          picname={props.picname}
          // onSearchDoubts={(data) => searchDoubts(data)}
          searchText={props?.searchText}
          setSearchText={props?.setSearchText}
          filterData={props?.filterData}
          setFilterData={props?.setFilterData}
          length={props?.doubts?.length}
          isFilter={props?.isFilter}
        />

      </div>

      <div className='w-full flex-1 '>
        <div
          style={{
            maxHeight: chatListHeight
          }}
          className=' overflow-y-auto w-full flex-1  flex flex-col items-start  px-3 py-5'>

          {props?.doubts?.length > 0 && (
            props?.doubts?.map((file, idx) => {
              return (
                <div className='w-full' key={idx}>
                  {props?.doubts?.length === 0 ? (
                    <>
                      <div className='flex flex-col items-center justify-center gap-2'>
                        <div>
                          {props.ctheme === 'dark' ? (
                            <img src={NoDoubtDarkImg} alt="NoDoubtDarkImg" />
                          ) : (
                            <img src={NodoubtImg} alt="NodoubtImg" />
                          )}
                        </div>
                        <h1 className='text-sm lg:text-xs text-[#B1B1B1]'>No doubts found !</h1>
                      </div>
                    </>
                  ) : (
                    <>
                      {props?.doubtId === file?._id && props?.screenWidth > 1024 ? (
                        <div onClick={() => props.onSelect(file?._id)} style={{ backgroundColor: theme.palette.bg.card, border: `1px solid ${theme.palette.inputBorder.card}` }} className='w-full  shrink-0 h-fit shadow  overflow-hidden   border-opacity-20 flex items-start gap-2 pr-1'>
                          <div className='h-32 w-1.5 bg-[#98D2C5] border border-[#E1E1E1]'></div>
                          <div className='px-1 py-3 w-full flex flex-col items-start gap-3'>
                            <div className='w-full flex items-center justify-between'>
                              <h6 style={{ color: theme.palette.headings.secondary }} className='text-sm whitespace-nowrap font-normal'>
                                {truncateTitle(file?.title)}
                              </h6>
                              <div className='flex items-center gap-2'>
                                <p style={{ color: theme.palette.Text.secondary }} className='text-xs whitespace-nowrap font-medium uppercase'>{timeFormat(file?.createdAt)}</p>
                              </div>
                            </div >
                            <div className='w-full'>
                              {file?.messages[0]?.type === 'image' ? (
                                <p style={{ color: theme.palette.Text.third }} className='text-sm font-normal lg:text-xs'>Image</p>
                              ) : file?.messages[0]?.type === 'document' ? (
                                <p style={{ color: theme.palette.Text.third }} className='text-sm font-normal lg:text-xs'>Document</p>
                              ) : file?.messages[0]?.type === 'audio' ? (
                                <p style={{ color: theme.palette.Text.third }} className='text-sm font-normal lg:text-xs'>Audio</p>
                              ) : (
                                <div className='max-w-[20vw]  h-6 text-ellipsis'>
                                  <p style={{ color: theme.palette.Text.third }} className='text-sm w-full whitespace-nowrap text-ellipsis overflow-hidden font-normal lg:text-xs'>{file?.messages[0]?.message}</p>
                                </div>
                              )}
                            </div>
                            <div className='flex w-full items-center justify-between'>
                              <div className='flex items-center gap-2'>
                                <small className='text-[#98D2C5] text-sm lg:text-xs'>{file?.subjects?.name}</small>
                                {props?.role === 'student' ? (
                                  <div style={{ backgroundColor: theme.palette.bg.button }} className='px-3 py-2 rounded' >
                                    <p style={{ color: theme.palette.Text.fourth }} className='text-xs'>{file?.faculty?.name}</p>
                                  </div>
                                ) : props?.role === 'faculty' ? (
                                  <div style={{ backgroundColor: '#98D2C563' }} className='px-3 py-2 rounded' >
                                    <p style={{ color: theme.palette.Text.fourth }} className='text-xs'>{file?.cases?.name}</p>
                                  </div>
                                ) : (
                                  <div className='flex items-center gap-2'>
                                    <div style={{ backgroundColor: '#98D2C563' }} className='px-3 py-2 rounded' >
                                      <p style={{ color: theme.palette.Text.fourth }} className='text-xs'>{file?.cases?.name}</p>
                                    </div>
                                    <div style={{ backgroundColor: theme.palette.bg.button }} className='px-3 py-2 rounded' >
                                      <p style={{ color: theme.palette.Text.fourth }} className='text-xs'>{file?.faculty?.name}</p>
                                    </div>
                                  </div>
                                )}

                              </div>

                              <div className='flex items-center gap-2'>
                                <div>
                                  {file?.unread != 0 && (
                                    <div className='w-5 h-5 rounded-full bg-[#98D2C5] border border-[#CAD7FD]  flex items-center justify-center'>
                                      <h6 className='text-sm text-white lg:text-xs'>{file?.unread}</h6>
                                    </div>
                                  )}
                                </div>

                                {props?.role === 'student' ? (
                                  <div>{file?.status === 'closed' && (file?.tempLike === 'liked' || file?.tempLike === 'disliked' || file?.tempLike === '') ? <b className='text-[#FF3333] text-sm lg:text-xs font-medium'>Closed</b> : file?.status === 'reopened' && <b className='text-[#00B05B] text-sm lg:text-xs font-medium'>Reopened</b>}</div>

                                ) : (
                                  <div>{file?.status === 'closed' ? <b className='text-[#FF3333] text-sm lg:text-xs font-medium'>Closed</b> : file?.status === 'reopened' && <b className='text-[#00B05B] text-sm lg:text-xs font-medium'>Reopened</b>}</div>
                                )}
                                {/* <div>
                                  {file?.status === 'reopened' && (
                                    <b className='text-[#00B05B]'>Reopened</b>)
                                  }
                                </div> */}
                                <div>
                                  {props?.role === 'student' || props?.role === 'admin' ? (

                                    file?.tempLike === 'liked' ? (
                                      <LikeIcon color='#00B05B' />
                                    ) : file?.tempLike === 'disliked' ? (
                                      <DisLikeIcon color='#FF3333' />
                                    ) : (
                                      <h1></h1>
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div >
                        </div >
                      ) : (
                        <div onClick={() => props.onSelect(file?._id)} style={{ borderBottom: `1px solid ${theme.palette.inputBorder.card}` }} className='w-full cursor-pointer shrink-0 h-fit  overflow-hidden border-opacity-20 flex items-start gap-2 pr-1'>
                          <div className='h-32 opacity-0 w-1 bg-[#98D2C5] border border-[#E1E1E1]'></div>
                          <div className='px-1 py-3 w-full flex flex-col items-start gap-2'>
                            <div className='w-full flex items-center justify-between'>
                              <h6 style={{ color: theme.palette.headings.secondary }} className='text-sm whitespace-nowrap font-normal lg:text-sm'>
                                {truncateTitle(file?.title)}
                              </h6>
                              <div className='flex items-center gap-2'>
                                <p style={{ color: theme.palette.Text.secondary }} className='text-xs whitespace-nowrap font-medium uppercase'>{timeFormat(file?.createdAt)}</p>
                              </div>
                            </div>
                            <div className='w-full'>
                              {file?.messages[0]?.type === 'image' ? (
                                <p style={{ color: theme.palette.Text.third }} className='text-sm font-normal lg:text-xs'>Image</p>
                              ) : file?.messages[0]?.type === 'document' ? (
                                <p style={{ color: theme.palette.Text.third }} className='text-sm font-normal lg:text-xs'>Document</p>
                              ) : file?.messages[0]?.type === 'audio' ? (
                                <p style={{ color: theme.palette.Text.third }} className='text-sm font-normal lg:text-xs'>Audio</p>
                              ) : (
                                <div className='max-w-[20vw]  h-6 text-ellipsis'>
                                  <p style={{ color: theme.palette.Text.third }} className='text-sm w-full whitespace-nowrap text-ellipsis overflow-hidden font-normal lg:text-xs'>{file?.messages[0]?.message}</p>
                                </div>
                              )}
                            </div>
                            <div className='flex w-full items-center justify-between'>
                              <div className='flex items-center gap-2'>
                                <small className='text-[#98D2C5] text-sm lg:text-xs'>{file?.subjects?.name}</small>
                                {props?.role === 'student' ? (
                                  <div style={{ backgroundColor: theme.palette.bg.button }} className='px-3 py-2 rounded' >
                                    <p style={{ color: theme.palette.Text.fourth }} className='text-xs'>{file?.faculty?.name}</p>
                                  </div>
                                ) : props?.role === 'faculty' ? (
                                  <div style={{ backgroundColor: '#98D2C563' }} className='px-3 py-2 rounded' >
                                    <p style={{ color: theme.palette.Text.fourth }} className='text-xs'>{file?.cases?.name}</p>
                                  </div>
                                ) : (
                                  <div className='flex items-center gap-2'>
                                    <div style={{ backgroundColor: '#98D2C563' }} className='px-3 py-2 rounded' >
                                      <p style={{ color: theme.palette.Text.fourth }} className='text-xs'>{file?.cases?.name}</p>
                                    </div>
                                    <div style={{ backgroundColor: theme.palette.bg.button }} className='px-3 py-2 rounded' >
                                      <p style={{ color: theme.palette.Text.fourth }} className='text-xs'>{file?.faculty?.name}</p>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className='flex items-center gap-2'>
                                {
                                  file?.unread > 0 && (
                                    <div className='w-5 h-5 rounded-full bg-[#98D2C5] border border-[#CAD7FD]  flex items-center justify-center'>
                                      <h6 className='text-sm text-white lg:text-xs'>{file?.unread}</h6>
                                    </div>
                                  )
                                }

                                {props?.role === 'student' ? (
                                  <div>{file?.status === 'closed' && (file?.tempLike === 'liked' || file?.tempLike === 'disliked' || file?.tempLike === '') ? <b className='text-[#FF3333] text-sm lg:text-xs font-medium'>Closed</b> : file?.status === 'reopened' && <b className='text-[#00B05B] text-sm lg:text-xs font-medium'>Reopened</b>}</div>

                                ) : (
                                  <div>{file?.status === 'closed' ? <b className='text-[#FF3333] text-sm lg:text-xs font-medium'>Closed</b> : file?.status === 'reopened' && <b className='text-[#00B05B] text-sm lg:text-xs font-medium'>Reopened</b>}</div>
                                )}

                                <div>
                                  {props?.role === 'student' || props?.role === 'admin' ? (

                                    file?.tempLike === 'liked' ? (
                                      <LikeIcon color='#00B05B' />
                                    ) : file?.tempLike === 'disliked' ? (
                                      <DisLikeIcon color='#FF3333' />
                                    ) : (
                                      <h1></h1>
                                    )

                                  ) : (
                                    <></>
                                  )}

                                </div>
                              </div>

                            </div >
                          </div >
                        </div >
                      )}
                    </>
                  )}

                </div>
              );
            })
          )}

        </div >
      </div >
    </div >
  )
}

export default ChatList
