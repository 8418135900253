import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../Student/StudentScreens/Login";
import MainScreen from "../Student/StudentScreens/MainScreen";
import Profile from "../Student/StudentScreens/Profile";
import ChatArea from "../Student/StudentScreens/ChatArea";
import SocketProvider from "../Student/StudentComponents/socketContext/Index";
import FacultyLogin from "../Student/StudentScreens/FacultyLogin";
import Snackbar from '@mui/material/Snackbar';
import { useDispatch, useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';
import MuiAlert from '@mui/material/Alert';
import { asyngetStatus, showToast } from "../store/Actions/Action";
import Auth from "../Student/StudentComponents/Auth";



const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



const StudentRoutes = () => {
  const dispatch = useDispatch();
  const { snackbar } = useSelector((state) => state.homeOption)

  const handleClose = (event, reason) => {
    dispatch(asyngetStatus({ open: false, message: '', duration: 2000, type: '' }))
  };


  return (
    <>
      <SocketProvider>
        <Routes>
          <Route path="/">
            <Route path="" element={<Login />} />
            <Route path="login" element={<Login />} />
            <Route path="faculty" element={<FacultyLogin />} />
          </Route>

          <Route path="/student" element={<Auth />}>
            <Route path="" element={<MainScreen />} />
            <Route path="home" element={<MainScreen />} />
            <Route path="chat" element={<ChatArea />} />
            <Route path="profile" element={<Profile />} />
          </Route>

          <Route path="/faculty" element={<Auth />}>
            <Route path="home" element={<MainScreen />} />
            <Route path="chat" element={<ChatArea />} />
            <Route path="profile" element={<Profile />} />
          </Route>


          {/* Define other routes outside of the admin section */}
        </Routes >
      </SocketProvider>

      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar open={snackbar.open} autoHideDuration={snackbar.duration} onClose={handleClose}>
          <Alert onClose={handleClose} severity={snackbar.type} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default StudentRoutes;
