import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import map from '../Assets/Images/map.png'
import HeatMap from '../Components/Heatmap'
import { useTheme } from '@mui/material/styles';
import Heatmap from '../Components/Heatmap';
import { post } from '../Services/api-services';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Box, ThemeProvider } from '@mui/system';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
        backgroundColor: theme.palette.bg.secondary,
        color: theme.palette.headings.main,
        borderBottom: `1px solid ${theme.palette.searchBorder.main}`,
    },
    '& .css-tlc64q-MuiPaper-root-MuiDialog-paper': {
        // backgroundColor: theme.palette.bg.secondary,
    },
    '& .css-mbdu2s': {
        backgroundColor: theme.palette.bg.secondary,
    }
}));


export default function FacultyMap() {

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const [closedData, setClosedData] = React.useState({})



    const heatMapDatabyFacultyApi = async () => {
        const res = await post('/doubts/heatmapData', { type: "closed" })
        if (res?.statusCode === 200) {
            setClosedData(res?.data)
        }
    }

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    React.useEffect(() => {

        heatMapDatabyFacultyApi()
    }, [])
    return (
        <React.Fragment>
            {/* <Button variant="outlined" onClick={handleClickOpen}>
            Open dialog
         </Button>
            <div className='bg-[#E2E2E2]  cursor-pointer '>
                     <FilterAltIcon onClick={handleClickOpen} sx={{color:'#98D2C5', width:35, marginTop:1}} />
                </div> */}
            <div className='flex gap-2 items-center cursor-pointer bg-[#EAF2F0] border border-[#E2E2E2] p-1 px-3 text-[#FFFFFF]' onClick={handleClickOpen}>
                <img src={map} alt='map' width={35} />

            </div>

            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth='sm'
                fullWidth
                sx={{
                    overflow: 'hidden'
                }}
                aria-labelledby="responsive-dialog-title"
                BackdropProps={{ invisible: true }}
            // sx={{
            //     '& .css-tlc64q-MuiPaper-root-MuiDialog-paper': {
            //         backgroundColor: theme.palette.bg.secondary,
            //     }
            // }}
            >
                <div style={{ backgroundColor: theme.palette.bg.dialog }} className='w-full  '>

                    <DialogTitle
                        sx={{
                            color: theme.palette.headings.main,
                            borderBottom: `1px solid ${theme.palette.searchBorder.main}`,
                        }}
                        id="customized-dialog-title">
                        Time Slab-Most Answered Doubts
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent
                        dividers className='justify-center flex flex-col gap-16 mx-16'>
                        <div className='  flex  gap-4 justify-center'>
                            <div >
                                <Typography style={{ color: theme.palette.headings.main, }} className=' font-semibold' sx={{ fontSize: 12 }}>Most doubt asked</Typography>
                                <Box className='bg-[#7BB5A8] rounded' width={28} height={11}></Box>
                            </div>
                            <div>
                                <Typography style={{ color: theme.palette.headings.main, }} className=' font-semibold' sx={{ fontSize: 12 }}>Less doubt asked</Typography>
                                <Box className='bg-[#7BB5A88A] rounded' width={28} height={11}></Box>
                            </div>
                            <div>
                                <Typography style={{ color: theme.palette.headings.main, }} className=' font-semibold' sx={{ fontSize: 12 }}>Very-Less doubt asked</Typography>
                                <Box className='bg-[#7BB5A824] rounded' width={28} height={11}></Box>
                            </div>
                        </div>
                        <Heatmap

                            orientation="vertical"

                            data={closedData.hourlyDoubtOfEveryDay}
                            xAxisLabels={closedData.dateArray}
                            yAxisLabels={closedData.hoursArray}
                        />

                    </DialogContent>
                </div>
            </Dialog>
        </React.Fragment>
    );
}