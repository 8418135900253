import React, { useState, useEffect } from "react";
import SocketContext from "./Context";
import { initSockets } from "../../../Socket/Index";
// import { initSockets } from "../../Sockets/Index";
//       ^ initSockets is shown later on

const SocketProvider = (props) => {
  const [value, setValue] = useState({
    doubt: {},
    message: {}
  });
  
useEffect(() => initSockets({ setValue }), [initSockets]);
// Note, we are passing setValue ^ to initSockets
return(
    <SocketContext.Provider value={ value }>
      { props.children }
    </SocketContext.Provider>
  )
};
export default SocketProvider;