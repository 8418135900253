import { useRef } from "react";
import "./styles.css";
import { currentTheme } from '../Services/RecoilService';
import { useRecoilState } from "recoil";
import { useTheme } from '@mui/material/styles';



const Heatmap = ({
  data = [],
  xAxisLabels = [],
  yAxisLabels = [],
  orientation = "vertical",
}) => {
  const [Theme, setTheme] = useRecoilState(currentTheme)
  const theme = useTheme();

  console.log();
  const generateBackgroundColor = (count) => {
    // return `hsl(166.552,39%, 71%${count > 0 ? 95 - count * 5 : 95}%)`;
    // if (Theme === 'dark') {
    //   return `hsl(0 1% ${count > 0 ? 95 - count * 5 : 95}%)`;
    // } else {
    return `hsl(166.552 36% ${count > 0 ? 95 - count * 5 : 95}%)`;
    // }
  };

  const minMaxCount = useRef([]);
  const formattedData = data;
  let i = 0;
  const gridCells = xAxisLabels.reduce((days, dayLabel) => {
    let j = 0;
    let newData = formattedData[i];
    const dayAndHour = yAxisLabels.reduce((hours, hourLabel) => {
      let count = newData[j];
      j++;
      minMaxCount.current = [...minMaxCount.current, count];

      return [
        ...hours,
        {
          dayHour: `${dayLabel} ${hourLabel}`,
          count,
        },
      ];
    }, []);
    i++;
    return {
      ...days,
      [dayLabel]: {
        hours: dayAndHour,
      },
    };
  }, {});

  return (
    <div className="container">
      <div className={`heatmap ${orientation}`}>
        <div className="col">
          {yAxisLabels.map((label, index) => (
            // Only render every other label text
            <span style={{ color: theme.palette.headings.main, }} key={label} className="label  whitespace-nowrap">
              {/* {index % 2 === 0 ? label : null} */}
              {label}
            </span>
          ))}
        </div>

        {Object.keys(gridCells).map((day) => (
          <div key={day} className="cells col">
            {gridCells[day].hours.map(({ dayHour, count }) => (
              <div
                key={dayHour}
                className="cell"
                style={{ backgroundColor: generateBackgroundColor(count) }}
              >
                <div className="tooltip" role="tooltip"   >
                  <span className="count">{count} Doubts</span>
                </div>
              </div>
            ))}
            <span style={{ color: theme.palette.headings.main, }} className="label">{day}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Heatmap;