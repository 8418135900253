import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { baseUrl } from '../../Services/api-services';

export default function ResponsiveDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    props?.setOpenMedia(true);
  };

  const handleClose = () => {
    props?.setOpenMedia(false);
  };



  return (
    <React.Fragment>
      <Dialog
        fullScreen={true}
        // open={props?.openMedia}
        open='true'
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"

      >
        <DialogTitle
          sx={{
            backgroundColor: '#171717'
          }} id="responsive-dialog-title">
          <div className='w-full flex items-center justify-between'>
            <Button onClick={handleClose}
              sx={{
                position: 'absolute',
                top: '3%',
                right: '0%'
              }}
            >
              <CloseIcon sx={{
                color: '#98D2C5'
              }} />
            </Button>
          </div>
        </DialogTitle>
        <DialogContent
          sx={{
            backgroundColor: '#171717',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div className='w-full flex flex-col items-center justify-center gap-4'>
            <div className='w-full flex items-center justify-center'>
              <div className='w-11/12 lg:w-[45%] h-[60vh] rounded overflow-hidden bg-white'>
                <img className='w-full h-full object-contain rounded' src={`${baseUrl}uploads/images/download/${props?.currentMedia?.message}`} alt="#" />
              </div>
            </div>
            <div className='flex max-w-[70%] overflow-x-auto items-center  py-4 gap-2'>

              {props?.allMedia && props.allMedia.map((message, index) => {
                return (
                  <>
                    {message?.type === 'image' ? (
                      message?.message === props?.currentMedia?.message ? (
                        <div
                          onClick={() => {

                            props?.setCurrentMedia(message)

                          }}
                          className='w-20 h-20 shrink-0 border border-white rounded p-1'
                        >
                          <img className='w-full h-full object-contain rounded' src={`${baseUrl}uploads/images/download/${message?.message}`} alt="#" />
                        </div>
                      ) : (
                        <div
                          onClick={() => {

                            props?.setCurrentMedia(message)

                          }}
                          className='w-20 h-20 shrink-0 rounded p-1'
                        >
                          <img className='w-full h-full object-contain rounded' src={`${baseUrl}uploads/images/download/${message?.message}`} alt="#" />
                        </div>
                      )

                    ) : (
                      // <h1>it might be text</h1>
                      <></>
                    )}
                  </>
                );
              })}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
