import React from 'react'
import { styled } from '@mui/material/styles';
import { Switch, } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Typography, Avatar } from '@mui/material';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import studentImg from '../../Assets/Images/faculty.png'
import { baseUrl, get, post } from '../../Services/api-services'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';


import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataGrid } from '@mui/x-data-grid';

import { useLocation, useNavigate } from 'react-router-dom';
import { DateArrow, RightArrow } from '../../Student/StudentComponents/Icons';
import moment from 'moment/moment';


const showIndiaTime = (time) => {
  return new Date(time).getTime() + 19800000
}

const timeFormat = (value) => {
  return moment(showIndiaTime(value)).format(' HH:mm a');
}

const dateFormat = (value) => {
  return moment(showIndiaTime(value)).format('DD/MM/YYYY ');
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};



export default function StudentDetail() {
  const theme = useTheme();
  const location = useLocation();
  const [loading, setLoading] = React.useState(true);
  const studentId = location.state.data
  const [value, setValue] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openDoubt, setOpenDoubt] = React.useState(true)
  const [closeDoubt, setCloseDoubt] = React.useState(false)
  const [studentDetail, setStudentDetail] = React.useState(null)
  const [doubt, setDoubt] = React.useState([]);
  const [doubtReq, setDoubtReq] = React.useState({
    selectClose: false, selectOpen: true, selectedStudents: [studentId], role: 'student'
  });

  const [courses, setCouses] = React.useState(null)
  const [subjects, setSubjects] = React.useState(null)

  const navigate = useNavigate()
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const getStudentData = async () => {
    const res = await get(`/cases/${studentId}/studentDetails`)
    console.log(res);
    if (res?.statusCode === 200) {
      setLoading(true)

      setStudentDetail(res?.data?.response)
      setCouses(res?.data?.response?.subscribedCourses)
      setSubjects(res?.data?.response?.subscribedCourses?.map((data) => data?.subjects))
    }
    setLoading(false)
  }

  const getDoubt = async () => {
    const res = await post('/doubts/getDoubtList', doubtReq)
    console.log(res);
    if (res?.statusCode === 200) {
      setDoubt(res?.data)
    }
    else {
      console.log(res)
    }
  }


  React.useEffect(() => {
    getStudentData()
    getDoubt()
  }, [doubtReq?.selectOpen, doubtReq?.selectClose])



  const columns = [

    {
      field: 'createdAt',
      headerName: 'Date',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        return (
          <div className='w-full items-center justify-center'>
            <h6 className='text-sm font-semibold'>{dateFormat(params?.row?.createdAt)}</h6>
            <h6 className='text-sm font-normal'>{timeFormat(params?.row?.createdAt)}</h6>

          </div>
        )
      }

    },

    {
      field: 'course',
      headerName: 'Course',
      minWidth: 150,
      // align:'center',
      flex: 1,
      renderCell: (params) => {

        const course = params?.row?.subjects?.courses?.name
        return <div className='flex items-center font-bold'>
          <p className=''>{course}</p>
        </div>
      },

    },

    {
      field: 'subject',
      headerName: 'Subject',
      minWidth: 150,
      // align:'right',
      renderCell: (params) => {

        const subject = params?.row?.subjects?.name
        return <div className='flex items-center font-bold'>
          <p className=''>{subject}</p>
        </div>
      },

    },

    {
      field: 'faculty',
      headerName: 'Faculty',
      minWidth: 250,
      // align:'right',
      renderCell: (params) => {

        const faculty = params?.row?.faculty
        return (
          <div className='flex gap-10 items-center font-bold'>
            <div className='flex items-center gap-2'>
              <Avatar
                src={`${baseUrl}uploads/staff/download/${faculty?.image}`}
              />
              <p className=''>{faculty?.name}</p>
            </div>
            <DateArrow Color='#7BB5A8' />
          </div>
        )

      },

    },

  ];


  const clickRow = (params) => {
    navigate(`/admin/home/doubts#${params?.row?.id}`)
  }

  const datagridStylesCss = {

    '& .css-rtrcn9-MuiTablePagination-root': {
      color: theme.palette.headings.main
    },
    '& .css-zylse7-MuiButtonBase-root-MuiIconButton-root.Mui-disabled': {
      color: theme.palette.disabled.main
    },
    '& .css-8nphli': {
      color: theme.palette.headings.main
    },
    '& .css-1deacqj.Mui-disabled': {
      color: theme.palette.disabled.main
    },
    '& .css-1utq5rl': {
      color: theme.palette.headings.main
    },
    '& .css-1j7qk7u': {
      color: theme.palette.headings.table,
    },
    border: 0,
    // backgroundColor: '#F9FAFF',
    backgroundColor: 'theme.palette.bg.adminApp',
    flex: 1,
    border: `1px solid ${theme.palette.searchBorder.main}`,
    flex: 1,
    '&>.css-wop1k0-MuiDataGrid-footerContainer': {
      '& .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar': {
        color: theme.palette.headings.table,
      },

      '& .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar': {
        color: theme.palette.headings.table,
      },
      '& .css-rtrcn9-MuiTablePagination-root .MuiTablePagination-selectLabel ': {
        color: theme.palette.headings.table,
      },
      '& .css-rtrcn9-MuiTablePagination-root .MuiTablePagination-input': {
        color: theme.palette.headings.table,
      },
      '& .css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon': {
        color: theme.palette.headings.table,
      },
      '& .css-i4bv87-MuiSvgIcon-root': {
        color: theme.palette.headings.table,
      },
      '& .css-levciy-MuiTablePagination-displayedRows': {
        color: theme.palette.headings.table,
      },
      // display: '',
      // justifyContent: 'flex-start',
      borderTop: `1px solid  transparent`,
      color: theme.palette.headings.table,
    },
    '& .css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root': {
      color: theme.palette.headings.table,
    },
    '&>.MuiDataGrid-main': {
      '&>.MuiDataGrid-columnHeaders': {

        // backgroundColor: '#55555512',
        // backgroundColor: theme.palette.bg.tableHeader,
        color: theme.palette.headings.table,
        fontWeight: '600',
        fontSize: "14px",
        borderBottom: `1px solid transparent`,
        justifyContent: 'space-between',
        borderRadius: '0px'
      },
      '&>.MuiDataGrid-columnHeaderTitleContainer': {
        border: 'none',
      },



      '& div div div div >.MuiDataGrid-cell': {
        border: 'none',
        fontSize: 14,
        // flexGrow: 1,
      },
      '& .MuiDataGrid-row': {
        cursor: 'pointer',
        marginTop: 1,
        color: theme.palette.headings.table
      },

    }

  }


  return (
    <div style={{ backgroundColor: theme.palette.bg.adminApp }} className='flex-1 p-4 flex flex-col '>
      <div className='flex gap-1'>
        <ArrowBackIcon sx={{ color: theme.palette.headings.mode, cursor: 'pointer' }} onClick={() => { navigate(-1) }} />
        <h4
          style={{ color: theme.palette.headings.mode }}
          className='text-lg capitalize lg:text-lg font-semibold tracking-wider'
        >
          {studentDetail?.name}
        </h4>
      </div>
      <div className=' flex-1 flex  mt-6 flex-col gap-4 xl:flex-row'>
        <div className='flex-1 flex flex-col gap-2'>
          <div className='flex-1 flex gap-4 flex-col'>


            <div className='flex   flex-col gap-1 lg:flex-row   '>
              <div
                style={{
                  backgroundColor: theme.palette.bg.adminApp,
                  border: `1px solid ${theme.palette.searchBorder.main}`
                }}
                className='flex gap-4 bg-[#FFFFFF] p-2'
              >
                <div className='w-20 flex items-center justify-center h-full'>
                  <Avatar
                    alt="Remy Sharp"
                    src={`${baseUrl}uploads/staff/download/${studentDetail?.image}`}
                    sx={{
                      borderRadius: 0,
                      width: '100%',
                      height: '100%'
                    }}
                  />
                </div>
                <div className='flex flex-col justify-between gap-1'>

                  <div >
                    <Typography sx={{ color: theme.palette.headings.main }}>Basic info</Typography>
                  </div>


                  <div className='text-sm'>
                    <p style={{ color: theme.palette.headings.main }}>Mobile no. : {studentDetail?.mobile}</p>
                    <p style={{ color: theme.palette.headings.main }}>Email Id :  {studentDetail?.email}</p>
                  </div>
                </div>
              </div>
              <div

                className='flex  gap-1'
              >
                <div
                  style={{
                    backgroundColor: theme.palette.bg.adminApp,
                    border: `1px solid ${theme.palette.searchBorder.main}`
                  }}
                  className='p-3'>
                  <Typography sx={{ color: theme.palette.headings.main }} >Total open doubts</Typography>
                  <Typography sx={{ color: '#FF3333', fontWeight: 700, marginTop: 2, }}>{studentDetail?.openDoubts}</Typography>
                </div>
                <div
                  style={{
                    backgroundColor: theme.palette.bg.adminApp,
                    border: `1px solid ${theme.palette.searchBorder.main}`
                  }}
                  className=' p-3 '>
                  <Typography sx={{ color: theme.palette.headings.main }}>Total closed doubts</Typography>
                  <Typography sx={{ color: '#00B05B', fontWeight: 700, marginTop: 2 }}>{studentDetail?.closedDoubts}</Typography></div>
              </div>
            </div>

            <Box className='flex-1 max-w-[90vw] flex flex-col gap-8'  >
              <Tabs
                value={value}
                onChange={handleChange}

                TabIndicatorProps={{
                  style: { backgroundColor: "#67BBA2", height: "3px", },
                }}

                sx={{
                  color: theme.palette.headings.mode,
                }}

              >

                <Tab label="Open doubts"
                  onClick={() => {
                    setDoubtReq(prevState => ({
                      ...prevState,
                      selectClose: false,
                      selectOpen: true,
                    }));
                  }}

                  sx={{
                    color: theme.palette.headings.selected,
                    fontSize: 14,
                    '&.Mui-selected': {
                      color: theme.palette.headings.mode,
                    },
                  }}

                />

                <Tab label="Closed doubts "
                  onClick={() => {
                    setDoubtReq(prevState => ({
                      ...prevState,
                      selectClose: true,
                      selectOpen: false,
                    }));
                  }}

                  sx={{
                    color: theme.palette.headings.selected,
                    fontSize: 14,
                    '&.Mui-selected': {
                      color: theme.palette.headings.mode,
                    },
                  }}

                />

              </Tabs>


              <DataGrid

                loading={loading}
                autoHeight={true}
                rows={doubt || []}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                onRowClick={(params) => clickRow(params)}

                disableColumnMenu

                disableColumnSelector
                disableColumnResize='true'
                sortable
                hideFooterPagination
                pageSizeOptions={[5, 10, 15, 20, 30, 50, 75, 100]}
                sx={datagridStylesCss}
                slots={{
                  footer: () => (
                    <>
                      <Typography variant="text" className='underline text-[#7BB5A8] font-semibold cursor-pointer flex justify-end pr-4 pb-2' onClick={() => { navigate("/admin/home/doubts") }}>View all</Typography>
                    </>
                  ),
                }}

              />

            </Box>

          </div>
        </div>

        <div
          style={{
            backgroundColor: theme.palette.bg.adminApp,
            border: `1px solid ${theme.palette.searchBorder.main}`
          }}
          className='flex-1  p-3'
        >
          <Typography
            sx={{
              color: theme.palette.headings.main,
              margin: "10px 0",
              fontWeight: '700',
            }}
          >
            Courses
          </Typography>
          {
            courses?.map((data, index) => {
              return (
                <div key={data?._id}>
                  <Accordion style={{ boxShadow: "none", backgroundColor: '#D9D9D93B', marginBottom: 5 }}
                    defaultExpanded={index === 0 ? true : false}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon
                        sx={{
                          color: theme.palette.headings.main
                        }}
                      />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{ paddingBottom: 0 }}
                    >

                      <Typography
                        sx={{
                          color: theme.palette.headings.main
                        }}
                      >
                        {data?.course} ({data?.country?.currencyName})
                      </Typography>



                    </AccordionSummary>
                    <AccordionDetails>
                      {
                        data?.subjects?.map((subject) => {

                          return (

                            <div key={subject?._id} className='flex items-center mb-5 justify-between'>
                              <Typography
                                sx={{
                                  color: theme.palette.headings.main
                                }}
                              >
                                {subject?.name}
                              </Typography>
                              <Typography><span className='text-[#00B05B] font-semibold'>{subject?.closedDoubts}|</span><span className='text-[#FF3333] font-semibold'>{subject?.openDoubts}</span></Typography>
                            </div>

                          )

                        })
                      }
                    </AccordionDetails>
                  </Accordion>
                </div>)

            })
          }

        </div>
      </div>

    </div>
  )
}
