import React, { useEffect } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTheme } from '@mui/system';
import Avatar from '@mui/material/Avatar';
import { CameraIcon, LightModeIcon, LogOutDarkIcon, LogOutIcon, PhoneIcon } from '../StudentComponents/Icons';
import Badge from '@mui/material/Badge';
import SwitchButton from '../StudentComponents/SwitchButton';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { currentRole, currentTheme, facultyAuthState, studentAuthState } from '../../Services/RecoilService';
import { useRecoilState } from 'recoil';
import { baseUrl, get, patch, post } from '../../Services/api-services';

const profileImg = require('../../Assets/Images/snsu.png')

const Profile = () => {
    const [ctheme, setCTheme] = useRecoilState(currentTheme)
    const [authState, setAuthState] = useRecoilState(studentAuthState)
    const [_facultyAuthState, set_FacultyAuthState] = useRecoilState(facultyAuthState)
    const [userDets, setUserDets] = React.useState('')
    const [role, setRole] = useRecoilState(currentRole)
    const [picname, setPicName] = React.useState('')
    const navigate = useNavigate();
    const theme = useTheme();
    const profilePic = React.useRef(null);


    useEffect(() => {
        GetUserDetails();
    }, [])


    const GetUserDetails = async () => {

        try {
            if (role === 'student') {
                const res = await get(`cases/${authState?.userId}`);
                console.log(res);
                if (authState?.userId) {
                    setUserDets(res.data);
                } else {
                    navigate('/')
                }
            } else if (role === 'faculty') {
                const res = await get(`faculties/${_facultyAuthState?.userId}/facultyDetails`);
                if (_facultyAuthState?.userId) {
                    setUserDets(res.data?.response);
                } else {
                    navigate('/faculty');
                }
            } else {
                // Handle other roles or navigate as needed
            }
        } catch (e) {
            console.log(`ERROR WHILE FETCHING USER DETAILS --- ${e}`)
            // navigate('/faculty');
        }
    }



    const uploadImage = async (e) => {
        let formData = new FormData()
        formData.append('file', e.target.files[0])
        const res = await post(`uploads/staff/upload`, formData)
        console.log(res);
        console.log(res?.data?.result?.files?.file[0].name);
        if (res?.statusCode === 200) {
            if (role === 'student') {
                const ress = await patch(`cases/${authState?.userId}`, { image: res?.data?.result?.files?.file[0].name })
            }
            else if (role === 'faculty') {
                const ress = await patch(`faculties/${_facultyAuthState?.userId}`, { image: res?.data?.result?.files?.file[0].name })
            }
            GetUserDetails();
        }

    }


    const Logout = async () => {
        try {
            const res = await post('cases/logout')
            localStorage.clear();
            window.location.reload();

            // if (res?.data?.status === 200) {
            //     setIsOtp(true);
            //     const _snackbar = {
            //         message: res?.data?.message,
            //         open: true,
            //         duration: 3000,
            //         type: 'success'
            //     };

            //     dispatch(showToast(_snackbar))
            //     setAuthState(res?.data?.data)
            //     setRole('student');
            //     navigate('/student/home')


            // } else {
            //     const _snackbar = {
            //         message: res?.data?.error,
            //         open: true,
            //         duration: 3000,
            //         type: 'error'
            //     };

            //     dispatch(showToast(_snackbar))
            // }
        } catch (e) {
            console.log(`ERROR WHILE FETCHING USER DETAILS --- ${e}`)
        }
    }

    return (
        <>
            <div style={{ backgroundColor: theme.palette.bg.main }} className='w-full h-screen'>
                <div className='w-full flex items-center px-3 py-5 gap-2'>

                    <div onClick={() => navigate(`/${role}/home`)} className='cursor-pointer'>
                        <ArrowBackIcon style={{ color: theme.palette.Text.main }} />
                    </div>
                    <h6 style={{ color: theme.palette.headings.main }} className='flex text-xl items-center justify-between'>Profile</h6>
                </div>
                <div className='flex flex-col items-center gap-5'>
                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        onClick={() => profilePic.current.click()} className='cursor-pointer'
                        badgeContent={
                            <CameraIcon />
                        }
                    >
                        <input
                            type="file"
                            className="hidden"

                            // onChange={(e) => {
                            //   // setStudentProfile(e.target.files[0]);
                            //   uploadImage(e)
                            // }}
                            onChange={uploadImage}
                            ref={profilePic}
                        />
                        <Avatar sx={{ width: 100, height: 100 }} alt={userDets?.name} src={`${baseUrl}uploads/staff/download/${userDets?.image}`} />
                    </Badge>
                    <div className='flex flex-col items-center gap-2'>

                        <h3 className='text-lg capitalize font-medium text-[#98D2C5] tracking-wide'>{userDets?.name}</h3>
                        <div v className='flex flex-col text-sm items-center gap-1'>
                            <div className='flex text-sm items-center gap-2'>
                                <PhoneIcon color={theme.palette.Text.main} />
                                <h6 style={{ color: theme.palette.Text.main }} className='font-normal text-md'>{userDets?.mobile}</h6>
                            </div>
                            <div className='flex items-center gap-2'>
                                <LightModeIcon />
                                <small style={{ color: theme.palette.Text.main }} className='font-normal text-md'>Light mode</small>
                                <SwitchButton />
                            </div>
                            <div onClick={() => Logout()} className='py-3 cursor-pointer'>
                                {ctheme === 'dark' ? (
                                    <LogOutDarkIcon />
                                ) : (
                                    <LogOutIcon />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Profile
