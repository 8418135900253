import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import { get, post } from '../Services/api-services'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showToast } from '../store/Actions/Action';
import { useDispatch } from 'react-redux';
import { StringWithSpace, emailRegex, phoneNumberRegex, } from '../Redux/Redux'
import { useTheme } from '@mui/material/styles';
import { themeColor, textColor, lightGreen, buttonTextColor } from "../Themes/Colors";
import MuiAlert from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, InputAdornment, ListItemText, OutlinedInput, } from "@mui/material";
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            overflowX:'auto'
        },
    },
};

export default function AddFaculty(props) {
    const dispatch = useDispatch();
    const [openAlert, setOpenAlert] = React.useState(false);
    const notify = () => toast("Wow so easy!");
    const [selectedCoutry, setselectedCoutry] = React.useState(null)
    const [facultyData, setFacultyData] = React.useState({

        email: "",
        name: "",
        mobile: "",
        courses: [],
        subjects: [],
        password: 'Eulogik#123',

    })
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const [mobileExistErr, setMobileExistErr] = React.useState(false)
    const [emailExistErr, setEmailExistErr] = React.useState(false)

    const [subjectData, setSubjectData] = React.useState([]);
    const [courseData, setCourseData] = React.useState([]);
    const [selectedCourseIds, setSelectedCourseIds] = React.useState([]);
    const [selectedCoursesName, setSelectedCoursesName] = React.useState([])
    const [selectedSubjectIds, setSelectedSubjectIds] = React.useState([]);
    const [selectedSubjectsName, setSelectedSubjectsName] = React.useState([])
    const [countryCode, setcountryCode] = React.useState(null)
    const [phoneCode, setPhoneCode] = React.useState('');
    const [countryPhoneLength, setCountryPhoneLength] = React.useState('');
    const [isValidMobile, setIsValidMobile] = React.useState(false);

    const clearData = () => {
        setFacultyData({
            email: "",
            name: "",
            mobile: "",
            courses: [],
            subjects: [],
            password: 'apt#123'
        })
        setSelectedCourseIds([])
        setSelectedCoursesName([])
        setSelectedSubjectIds([])
        setSelectedSubjectsName([])
        setMobileExistErr(false)
        setEmailExistErr(false)
    }


    // GET ALL  COURSES DATA

    const getcourse = async () => {
        const res = await get('courses?filter={"where": {"deleted":false},"include":"country"}')

        if (res?.statusCode === 200) {
            setCourseData(res?.data)
            setOpen(false);

        }

        else {
            console.log(res?.data?.message)
        }
    }


    // GET ALL COUTNRIES 

    const getCountreis = async () => {
        try {
            const res = await post('countries/getCountry');
            setcountryCode(res?.data?.data);
            // Find the country with code '91'
            const indiaCountry = res?.data?.data.find(country => country?.phoneCode === "91");
            setPhoneCode(indiaCountry?.phoneCode)
            setselectedCoutry(indiaCountry)
            setCountryPhoneLength(indiaCountry?.phoneLength)

            // If '91' country is found, store its details in a separate state
            if (indiaCountry) {
                // setIndiaCountry(indiaCountry);
            }
        } catch (e) {
            console.log(e);
        }
    };

    React.useEffect(() => {
        getcourse();
        getCountreis();
    }, []);

    //  ADD FACULTY 

    const submitFacultty = async () => {

        const apiPayload = {
            email: facultyData.email,
            name: facultyData.name,
            mobile: facultyData.mobile,
            courses: facultyData.courses,
            subjects: facultyData.subjects,
            password: facultyData.password,
            country: selectedCoutry?.id,
        };

        const res = await post('faculties/addFaculty', apiPayload)
        if (res?.statusCode === 200) {

            setOpen(false);
            clearData()

            const _snackbar = {
                message: 'Faculty added successfully!',
                open: true,
                duration: 3000,
                type: 'success'
            };

            dispatch(showToast(_snackbar))
            props.getFaculty();
        }

        else {
            const _snackbar = {
                message: 'Faculty  Already Exist',
                open: true,
                duration: 3000,
                type: 'error'
            };

            dispatch(showToast(_snackbar))

            setFacultyData({
                email: "",
                name: "",
                mobile: "",
                courses: [],
                subjects: [],
                password: '',
            })
            setSelectedCourseIds([])
            setSelectedCoursesName([])
            setSelectedSubjectIds([])
            setSelectedSubjectsName([])



        }
    }

    // OPEN ADD FACULTY DIALOGUE 

    const handleClickOpen = () => {
        setOpen(true);
    };

    // CLOSE ADD FACULTY DIALOGUE 

    const handleClose = () => {
        clearData()
        setOpen(false);
    };

    // HANDLE COURSE CHANGE FUNCTION 

    const handleChangeCourse = async (value) => {


        const __index = selectedCoursesName?.indexOf(value.name);
        const __indexG = selectedCourseIds?.indexOf(value.id);

        if (__indexG >= 0) {
            let array = [...selectedCoursesName];
            array.splice(__index, 1)
            setSelectedCoursesName(array);
            let arrays = [...selectedCourseIds];
            arrays.splice(__indexG, 1)
            setSelectedCourseIds(arrays);
            setFacultyData({ ...facultyData, courses: [...arrays] })
            const res = await post('subjects/getSubjectByCourse', { courses: arrays })

            if (res?.statusCode === 200) {
                setSubjectData(res?.data.subject)

            } else {

            }
        } else {
            const array = [...selectedCoursesName, value.name];
            setSelectedCoursesName(array);
            const arrays = [...selectedCourseIds, value.id];
            setSelectedCourseIds(arrays);
            setFacultyData({ ...facultyData, courses: [...arrays] })
            const res = await post('subjects/getSubjectByCourse', { courses: arrays })

            if (res?.statusCode === 200) {
                setSubjectData(res?.data.subject)

            } else {

            }
        }
        setSelectedSubjectIds([]);
        setSelectedSubjectsName([]);

    }

    // HANDLE SUBJECT CHANGE Function 

    const handleChangeSubject = async (value) => {


        const __index = selectedSubjectsName?.indexOf(value.name);
        const __indexG = selectedSubjectIds?.indexOf(value._id);

        if (__indexG >= 0) {
            let array = [...selectedSubjectsName];
            array.splice(__index, 1)
            setSelectedSubjectsName(array);
            let arrays = [...selectedSubjectIds];
            arrays.splice(__indexG, 1)
            setSelectedSubjectIds(arrays);
            setFacultyData({ ...facultyData, subjects: [...arrays] })


        } else {
            const array = [...selectedSubjectsName, value.name];
            setSelectedSubjectsName(array);
            const arrays = [...selectedSubjectIds, value._id];
            setSelectedSubjectIds(arrays);

            setFacultyData({ ...facultyData, subjects: [...arrays] })



        }
    }

    // VALIDATE EMAIL 

    const emailAlreadyExist = async () => {
        const res = await get(`faculties/count?where={"email":"${facultyData.email}"}`)

        if (res?.statusCode === 200) {
            if (res?.data?.count === 1) {
                setEmailExistErr(true)
            }
            else {

                setEmailExistErr(false)
            }
        }
    }

    // VALIDATE MOBILE 

    const mobileAlreadyExist = async () => {
        const res = await get(`faculties/count?where={"mobile":${facultyData.mobile}}`)
        if (res?.statusCode === 200) {
            if (res?.data?.count === 1) {
                setMobileExistErr(true)
            }
            else {

                setMobileExistErr(false)
            }
        }
    }


    // TEXTFIELD STYLE 

    const textFieldStyle = {

        '& label.Mui-focused': {
            color: theme.palette.headings.main,
        },
        '& label': {
            color: theme.palette.headings.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: theme.palette.headings.main,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#EAEAEA',
            },
            '&:hover fieldset': {
                borderColor: '#EAEAEA',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#EAEAEA',
            },
            color: theme.palette.inputText.main
        },
        // sx={{
        //     fontSize: { lg: '.9vmax' },
        // border: `1px solid ${theme.palette.askDoubt.main}`,
        color: theme.palette.inputText.main,
        '& .MuiSelect-icon': {
            color: theme.palette.inputText.main
        },
        '& .MuiSelect-select': {
            color: theme.palette.inputText.main
        },
        '.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper': {
            overflowX: 'auto',
            // overflowY: 'hidden', // Set overflowY to 'hidden' to disable vertical overflow (optional)
        },
        '& .css-6hp17o-MuiList-root-MuiMenu-list': {
            overflowX: 'auto',
        }

        //   }}
    }

    // HANDLE COUTRY CHANGE 

    const handleChange = (event) => {

        const selectedPhoneCode = event.target.value;
        const selectedCountry = countryCode.find(country => country.phoneCode === selectedPhoneCode);
        setselectedCoutry(selectedCountry)
        // Update the state for phone code
        setPhoneCode(selectedPhoneCode);

        // Update the state for PhoneLenmgth
        setCountryPhoneLength(selectedCountry?.phoneLength);

        if (facultyData.mobile?.length === selectedCountry?.phoneLength) {
            setIsValidMobile(true)
        } else {
            setIsValidMobile(false)
        }

    };


    return (
        <React.Fragment>

            <div className='flex gap-2 items-center cursor-pointer bg-[#98D2C5] border border-[#E2E2E2] p-1 px-2 text-[#FFFFFF]' onClick={handleClickOpen}>
                <AddIcon />
            </div>

            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth='sm'
                fullWidth
                sx={{
                    overflow: 'hidden'
                }}
                aria-labelledby="responsive-dialog-title"
                BackdropProps={{ invisible: true }}
            >
                <div style={{ backgroundColor: theme.palette.bg.dialog }} className='w-full  '>
                    <DialogTitle
                        sx={{
                            m: 0, p: 2,
                            backgroundColor: theme.palette.bg.secondary,
                            color: theme.palette.headings.main,
                            borderBottom: `1px solid ${theme.palette.searchBorder.main}`
                        }}
                        id="customized-dialog-title">
                        ADD FACULTY MEMBER
                    </DialogTitle>
                    <IconButton
                        aria-label="close"

                        onClick={(e) => { e.stopPropagation(); handleClose(); }}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent
                        sx={{
                            backgroundColor: theme.palette.bg.secondary
                        }}
                        dividers>
                        <div className="flex pt-8 pb-2 flex-col gap-10 lg:w-full  ">
                            <div className='flex flex-col gap-10 lg:flex-row'>
                                <FormControl className='text-xs w-full lg:w-1/2'>
                                    <TextField
                                        className=" text-xs"
                                        sx={textFieldStyle}

                                        size="small"
                                        type="text"
                                        error={
                                            facultyData?.name?.length > 0 &&
                                            !StringWithSpace?.test(facultyData.name)
                                        }
                                        helperText={
                                            facultyData?.name?.length > 0 &&
                                                !StringWithSpace?.test(facultyData.name)
                                                ? "enter valid name"
                                                : ""
                                        }
                                        value={facultyData.name}
                                        label="Name"
                                        required
                                        onChange={(e) =>
                                            setFacultyData((prevState) => ({
                                                ...prevState,
                                                name: e.target.value,
                                            }))
                                        }
                                    />
                                </FormControl>
                                <FormControl className='text-xs w-full lg:w-1/2'>
                                    <TextField
                                        className=" text-xs"
                                        size="small"
                                        type="text"
                                        sx={textFieldStyle}
                                        onBlur={emailAlreadyExist}
                                        onChange={(e) =>
                                            setFacultyData((prevState) => ({
                                                ...prevState,
                                                email: e.target.value,
                                            }))
                                        }
                                        error={
                                            facultyData?.email?.length > 0 &&
                                            !emailRegex?.test(facultyData.email)
                                        }

                                        helperText={
                                            facultyData?.email?.length > 0 &&
                                                !emailRegex?.test(facultyData.email)
                                                ? "enter valid email id"
                                                : ""
                                        }
                                        value={facultyData.email}
                                        label="Email"
                                        required

                                    />
                                    {emailExistErr && <FormHelperText sx={{ color: 'red' }}>
                                        Email already exist.
                                    </FormHelperText>}
                                </FormControl>
                            </div>
                            <div className='flex flex-col gap-10 lg:flex-row'>
                                <FormControl className='text-xs w-full lg:w-1/2' required size='small' sx={textFieldStyle}>
                                    <InputLabel id="demo-multiple-checkbox-label">Country </InputLabel>
                                    <Select

                                        labelId="demo-multiple-checkbox-label"
                                        displayEmpty
                                        value={phoneCode}
                                        onChange={handleChange}
                                        // input={<OutlinedInput />}
                                        // MenuProps={MenuProps}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem disabled value={phoneCode}>
                                            <em>
                                                <h6 className='text-lg'>+{phoneCode}</h6>
                                            </em>
                                        </MenuItem>
                                        {countryCode?.map((country, index) => (
                                            <MenuItem
                                                key={index}
                                                value={country?.phoneCode}
                                            // style={getStyles(name, personName, theme)}
                                            >
                                                <h6 className='text-lg'>+{country?.phoneCode}</h6>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl className='text-xs w-full lg:w-1/2'>

                                    <TextField
                                        className=" text-xs"
                                        size="small"
                                        type="number"
                                        value={facultyData.mobile}
                                        label="Mobile No."
                                        required
                                        onBlur={mobileAlreadyExist}
                                        onChange={(e) => {
                                            setFacultyData((prevState) => ({
                                                ...prevState,
                                                mobile: e.target.value,
                                            }));
                                            setIsValidMobile(() =>
                                                e?.target?.value?.length === countryPhoneLength
                                                    ? true
                                                    : false
                                            );
                                        }
                                        }
                                        sx={textFieldStyle}

                                        helperText={
                                            facultyData.mobile?.length > 0 && !isValidMobile
                                                // !mobileRegex?.test(mobile)
                                                ? `mobile no. should contains ${countryPhoneLength} Digit`
                                                : ""
                                        }
                                        error={
                                            facultyData.mobile?.length > 0 && !isValidMobile
                                                // !mobileRegex?.test(mobile))
                                                ? `mobile no. should contains ${countryPhoneLength} Digit`
                                                : ""
                                        }

                                    />
                                    {mobileExistErr && <FormHelperText sx={{ color: 'red' }}>
                                        Mobile no. already exist.
                                    </FormHelperText>}

                                </FormControl>
                            </div>
                            <div className='flex flex-col gap-10 lg:flex-row'>
                                <FormControl className='text-xs w-full lg:w-1/2' required size='small' sx={textFieldStyle}>
                                    <InputLabel id="demo-multiple-checkbox-label">Course</InputLabel>
                                    <Select

                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={selectedCoursesName}
                                        input={<OutlinedInput label="Course" />}
                                        renderValue={(selected) => selected.join(', ')}
                                        MenuProps={MenuProps}
                                    >
                                        {courseData?.map((item) => (

                                                    
                                                    <MenuItem key={item.name} value={item} onClick={() => handleChangeCourse(item)} >
                                                <Checkbox style={{
                                                    color: "#98D2C5",
                                                }} checked={selectedCourseIds?.indexOf(item?.id) > -1} />

                                                <h1>{item?.name} ({item?.country?.currencyName})</h1>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl className='text-xs w-full lg:w-1/2' required size='small' sx={textFieldStyle}>
                                    <InputLabel id="demo-multiple-checkbox-label">Subject</InputLabel>
                                    <Select

                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={selectedSubjectsName}
                                        input={<OutlinedInput label="Course" />}
                                        renderValue={(selected) => selected.join(', ')}
                                        MenuProps={MenuProps}
                                    >
                                        {subjectData?.map((item) => (

                                            <MenuItem key={item.name} value={item} onClick={() => handleChangeSubject(item)}>
                                                <Checkbox style={{
                                                    color: "#98D2C5",
                                                }} checked={selectedSubjectIds?.indexOf(item?._id) > -1} />

                                                <ListItemText primary={`${item.name} (${item.courses?.name})`} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                    </DialogContent>
                    <DialogActions
                        sx={{
                            backgroundColor: theme.palette.bg.secondary,
                            borderTop: `1px solid ${theme.palette.searchBorder.main}`
                        }}
                        className='text-[#E4E4E433 pr-5'>
                        {/* <Button autoFocus onClick={handleClose}>
                        Save changes
                    </Button> */}
                        <ToastContainer />
                        <button
                            disabled={facultyData.name && facultyData.email && facultyData.mobile && selectedCourseIds.length >= 1 && selectedSubjectIds.length >= 1 &&
                                StringWithSpace?.test(facultyData.name) && emailRegex?.test(facultyData.email) && isValidMobile && !mobileExistErr && !emailExistErr ? false : true
                            }
                            className='flex gap-2 items-center cursor-pointer bg-[#98D2C5] p-3 px-5 text-[#FFFFFF] disabled:opacity-50' onClick={submitFacultty}>
                            <AddIcon />
                            <Typography>ADD</Typography>
                        </button>
                    </DialogActions>
                </div>
            </Dialog>
        </React.Fragment>
    );
}