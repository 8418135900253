import React, { useContext } from 'react'
import { useTheme } from '@mui/system';
import { CrossIcon, LogOutIcon, MP3Icon, RightArrow } from './Icons';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import { baseUrl, get, processFileName } from '../../Services/api-services';
import { currentDoubtId, currentScreen } from '../../Services/RecoilService';
import { useRecoilState } from 'recoil';
import SocketContext from './socketContext/Context';

const fileIcon = require('../../Assets/Images/FileImg.png')
const Document = require('../../Assets/Images/Document.png')
const Link = require('../../Assets/Images/Link.png')

const UserMedia = (props) => {
    const theme = useTheme();
    const messages = props?.doubtDets?.messages || [];
    const [imagesArray, setImagesArray] = React.useState([])
    const [documentsArray, setDocumentsArray] = React.useState([])
    const [mediaImages, setMediaImages] = React.useState([])
    const [mediaAudios, setMediaAudios] = React.useState([])
    const [mediaDocuments, setMediaDocuments] = React.useState([])
    const [allImages, setallImages] = React.useState(false)
    const headRef = React.useRef(null);
    const divRef = React.useRef(null);
    const [mediaHeight, setMediaHEightt] = React.useState(null)

    // const [props?.doubtId, setprops?.doubtId] = useRecoilState(currentDoubtId)

    // React.useEffect(() => {

    //     // Iterate through messages and categorize them
    //     const updatedImagesArray = [];
    //     const updatedDocumentsArray = [];


    //     messages.forEach((message, index) => {
    //         if (message?.type === 'image' || message?.type === 'audio') {
    //             updatedImagesArray.push(message);
    //         } else if (message?.type === 'document') {
    //             updatedDocumentsArray.push(message);
    //         } else if (message?.type === 'audio') {
    //             audioArray.push(message);
    //         }
    //     });
    //     console.log(messages);
    //     setImagesArray(updatedImagesArray);
    //     setDocumentsArray(updatedDocumentsArray);

    // }, [messages])

    const { _messages, _doubts } = useContext(SocketContext);


    React.useEffect(() => {
        getMediaImages();
        getMediaAudios();
        getMediaDocuments();
    }, [props?.doubtId, _messages])


    const getMediaImages = async () => {
        try {

            const response = await get(`doubts/${props?.doubtId}/messages?filter={"where": {"type": "image"}, "order":"createdAt desc", "limit": 4}`)

            if (response?.statusCode === 200) {
                setMediaImages(response?.data)
            }
        } catch (e) {
            console.log(e);
        }
    }

    const getAllMediaImages = async () => {
        try {

            const response = await get(`doubts/${props?.doubtId}/messages?filter={"where": {"type": "image"}, "order":"createdAt desc"}`)
            if (response?.statusCode === 200) {
                setMediaImages(response?.data)
            }
        } catch (e) {
            console.log(e);
        }
    }


    const getMediaAudios = async () => {
        try {
            const response = await get(`doubts/${props?.doubtId}/messages?filter={"where": {"type": "audio"}, "order":"createdAt desc", "limit": 4}`)
            if (response?.statusCode === 200) {
                setMediaAudios(response?.data)
            }
        } catch (e) {
            console.log(e);
        }
    }

    const getAllMediaAudios = async () => {
        try {
            const response = await get(`doubts/${props?.doubtId}/messages?filter={"where": {"type": "audio"}, "order":"createdAt desc", }`)
            console.log(response?.data);
            if (response?.statusCode === 200) {
                setMediaAudios(response?.data)
            }
        } catch (e) {
            console.log(e);
        }
    }


    const getMediaDocuments = async () => {
        try {
            const response = await get(`doubts/${props?.doubtId}/messages?filter={"where": {"type": "document"}, "order":"createdAt desc", "limit": 4}`)
            if (response?.statusCode === 200) {
                setMediaDocuments(response?.data)
            }
        } catch (e) {
            console.log(e);
        }
    }

    const getAllMediaDocuments = async () => {
        try {
            const response = await get(`doubts/${props?.doubtId}/messages?filter={"where": {"type": "document"}, "order":"createdAt desc"}`)
            console.log(response?.data);
            if (response?.statusCode === 200) {
                setMediaDocuments(response?.data)
            }
        } catch (e) {
            console.log(e);
        }
    }

    React.useEffect(() => {
        setMediaHEightt(divRef?.current?.offsetHeight - headRef?.current?.offsetHeight - 30)
    }, [headRef?.current]);


    return (
        <div
            ref={divRef}
            style={{ backgroundColor: theme.palette.bg.secondary }} className=' w-full pt-0 flex flex-col lg:pt-20 h-screen overflow-y-auto'>
            <div ref={headRef} className='w-full block lg:hidden'>
                <div

                    className='w-full py-3 px-4 pt-7 border-b border-[#3636361F] block lg:hidden'
                >
                    {props?.role === 'student' ? (
                        <h4 style={{ color: theme.palette.headings.main }} className='text-sm lg:text-sm'> {props?.doubtDets?.faculty?.name}</h4>
                    ) : props?.role === 'faculty' ? (
                        <h4 style={{ color: theme.palette.headings.main }} className='text-sm lg:text-sm'>{props?.doubtDets?.student?.name}</h4>
                    ) : (
                        <h4>admin</h4>
                    )}


                    <h6 className='text-sm lg:text-sm text-[#E54C4C]'>{props?.doubtDets?.subject?.course?.name}</h6>
                    <p style={{ color: theme.palette.headings.main }} className='text-sm lg:text-xs'>{props?.doubtDets?.subject?.name}</p>
                </div>
                <div style={{ backgroundColor: theme.palette.bg.chatHeadbg }} className='px-4 flex items-center gap-3 py-3 my-1'>
                    <div
                        onClick={props?.backToChat}
                        className='block lg:hidden'
                    >
                        <RightArrow />
                    </div>
                    <div className='w-full  '>
                        <h6
                            style={{
                                color: theme.palette.headings.secondary,
                                wordBreak: 'break-word',
                            }}
                            className='text-sm lg:text-xs font-semibold'
                        >
                            {props?.doubtDets?.title}
                        </h6>
                    </div>
                </div>
            </div>

            <div
                style={{
                    maxHeight: mediaHeight,
                    overflowY: 'auto'
                }}
            >
                {mediaImages?.length === 0 && mediaDocuments?.length === 0 && mediaAudios?.length === 0 ? (
                    <div className='w-full flex-1 flex items-center justify-center'>
                        <h1 style={{ color: theme.palette.headings.secondary }}>No Media Found</h1>
                    </div>
                ) : (
                    <>
                        {mediaImages?.length > 0 ? (
                            <div className='w-full '>
                                <div className='w-full flex py-2 items-center justify-between px-4'>
                                    <h6 style={{ color: theme.palette.headings.secondary }} className='capitalize'>Shared images</h6>
                                    {mediaImages?.length < 4 ? (
                                        <p className='text-[#98D2C5] text-sm lg:text-xs'></p>
                                    ) : mediaImages?.length > 4 ? (
                                        <p onClick={getMediaImages} className='text-[#98D2C5] text-sm lg:text-xs'>Close</p>
                                    ) : (
                                        <p onClick={getAllMediaImages} className='text-[#98D2C5] text-sm lg:text-xs'>View all</p>
                                    )}
                                </div>
                                <div className='w-full flex items-center justify-center  py-3 gap-3 flex-wrap'>
                                    <div className='w-11/12 flex items-center justify-between py-3 gap-3 flex-wrap'>
                                        {mediaImages?.map((message, index) => (
                                            <div onClick={() => props?.onPreview(message)} key={index} className='w-[48%] relative h-32 rounded'>
                                                <img className='w-full rounded h-full object-contain' src={`${baseUrl}uploads/images/download/${message?.message}`} alt='Media' />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}

                        {mediaAudios?.length > 0 ? (
                            <div className='w-full '>
                                <div className='w-full flex py-2 items-center justify-between px-4'>
                                    <h6 style={{ color: theme.palette.headings.secondary }} className='capitalize'>Shared audio</h6>
                                    {mediaAudios?.length < 4 ? (
                                        <p className='text-[#98D2C5] text-sm lg:text-xs'></p>
                                    ) : mediaAudios?.length > 4 ? (
                                        <p onClick={getMediaAudios} className='text-[#98D2C5] text-sm lg:text-xs'>Close</p>
                                    ) : (
                                        <p onClick={getAllMediaAudios} className='text-[#98D2C5] text-sm lg:text-xs'>View all</p>
                                    )}
                                </div>
                                <div className='w-full flex items-center justify-center  py-3 gap-3 flex-wrap'>
                                    <div className='w-11/12 flex items-center justify-between py-3 gap-3 flex-wrap'>
                                        {mediaAudios?.map((message, index) => (
                                            <div key={index} className='w-[48%] bg-[#F9F9F9] border border-[#E7E4E4] flex items-center justify-center relative h-32 rounded'>
                                                <MP3Icon />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}

                        {mediaDocuments?.length > 0 ? (
                            <div className='w-full '>
                                <div className='w-full flex py-2 items-center justify-between px-4'>
                                    <h6 style={{ color: theme.palette.headings.secondary }} className='capitalize'>Shared documents</h6>
                                    {mediaDocuments?.length < 4 ? (
                                        <></>
                                    ) : mediaDocuments?.length > 4 ? (
                                        <p onClick={getMediaDocuments} className='text-[#98D2C5] text-sm lg:text-xs'>Close</p>
                                    ) : (
                                        <p onClick={getAllMediaDocuments} className='text-[#98D2C5] text-sm lg:text-xs'>View all</p>
                                    )}
                                </div>
                                <div className='w-full flex items-center justify-center  py-3 gap-3 flex-wrap'>
                                    <div className='w-11/12 flex items-center justify-between py-3 gap-3 flex-wrap'>
                                        {mediaDocuments?.map((message, index) => (
                                            <div key={index} className='w-full  overflow-hidden flex items-center justify-between  relative h-14 rounded'>
                                                <div
                                                    onClick={() => {
                                                        window.open(`${baseUrl}uploads/documents/download/${message.message}`)
                                                    }}
                                                    className='flex h-full pr-1 ri  items-start gap-3'>
                                                    <div className='w-fit flex px-4 items-center justify-center h-full bg-[#98D2C5] rounded p-2'>
                                                        <img className='w-full rounded h-[60%] object-contain' src={fileIcon} alt='fileIcon' />
                                                    </div>
                                                    <div className='flex flex-col rounded-xl  px-1 py-1 items-start '>
                                                        <h6 style={{ color: theme.palette.Text.fileHeading }} className='text-sm lg:text-sm'>{processFileName(message.message, 10)}</h6>
                                                        <p className='text-sm lg:text-xs text-[#AAAAAA]'>{new Date(message?.updatedAt).toLocaleString('en-US')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                    </>
                )}
            </div>

            {/* not useful now  */}

            <div className='w-full hidden'>
                <div className='w-full flex items-center justify-between px-4 py-2'>
                    <h6 style={{ color: theme.palette.headings.secondary }} className='capitalize'>Shared files</h6>
                    <p className='text-[#98D2C5] text-sm lg:text-xs'>View all</p>
                </div>
                <div className='w-full flex items-center justify-center py-3 gap-3 flex-wrap px-4'>
                    {documentsArray?.map((data, index) => (
                        <div key={index}
                            onClick={() => {
                                alert('heyuyy')
                                window.open(`${baseUrl}uploads/documents/download/${data.message}`)
                            }}
                            className='w-full flex items-center justify-between  relative h-14 rounded'>
                            <div className='flex  items-start gap-3'>
                                <div className='w-fit bg-[#98D2C5] rounded p-2'>
                                    <img className='w-full rounded h-full object-contain' src={fileIcon} alt='fileIcon' />
                                </div>
                                <div className='flex flex-col items-start '>
                                    <h6 style={{ color: theme.palette.Text.fileHeading }} className='text-sm lg:text-sm'>{data.message}</h6>
                                    <p className='text-sm lg:text-xs text-[#AAAAAA]'>{new Date(data?.updatedAt).toLocaleString('en-US')}</p>
                                </div>
                            </div>
                            <div>
                                <h6 style={{ color: theme.palette.Text.fileHeading }} className='text-sm lg:text-sm'>5 MB</h6>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className='w-full hidden'>
                <div className='w-full flex items-center justify-between px-4 py-2'>
                    <h6 style={{ color: theme.palette.headings.secondary }} className='capitalize'>Shared links</h6>
                    <p className='text-[#98D2C5] text-sm lg:text-xs'>View all</p>
                </div>
                <div className='w-full flex items-center justify-center py-3 gap-3 flex-wrap px-4'>
                    <div className='w-full flex items-center justify-between  relative h-14 rounded'>
                        <div className='flex  items-start gap-4'>
                            <div className='w-fit h-10'>
                                <img src={Link} alt="Link" />
                            </div>
                            <div className='flex flex-col items-start '>
                                <h6 style={{ color: theme.palette.Text.fileHeading }} className='text-sm font-normal lg:text-xs'>How to check  balance sheet when debt</h6>
                                <p className='text-sm lg:text-xs font-normal text-[#AAAAAA]'>Lorem ipsum dolor sit amet. Non fugit nobis id </p>
                            </div>
                        </div>
                    </div>
                    <div className='w-full flex items-center justify-between  relative h-14 rounded'>
                        <div className='flex  items-start gap-4'>
                            <div className='w-fit h-10'>
                                <img src={Link} alt="Link" />
                            </div>
                            <div className='flex flex-col items-start '>
                                <h6 style={{ color: theme.palette.Text.fileHeading }} className='text-sm font-normal lg:text-xs'>How to check  balance sheet when debt</h6>
                                <p className='text-sm lg:text-xs font-normal text-[#AAAAAA]'>Lorem ipsum dolor sit amet. Non fugit nobis id </p>
                            </div>
                        </div>
                    </div>
                    <div className='w-full flex items-center justify-between  relative h-14 rounded'>
                        <div className='flex  items-start gap-4'>
                            <div className='w-fit h-10'>
                                <img src={Link} alt="Link" />
                            </div>
                            <div className='flex flex-col items-start '>
                                <h6 style={{ color: theme.palette.Text.fileHeading }} className='text-sm font-normal lg:text-xs'>How to check  balance sheet when debt</h6>
                                <p className='text-sm lg:text-xs font-normal text-[#AAAAAA]'>Lorem ipsum dolor sit amet. Non fugit nobis id </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserMedia
