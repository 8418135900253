import React from 'react'
import AddIcon from '@mui/icons-material/Add';
import { CrossIcon, DeleteIcon, SendFileIcon, SendIcon } from './Icons';
import { useTheme } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const FileImg = require('../../Assets/Images/FileImg.png')


const SelectImages = (props) => {
    console.log(props?.isUploading);
    const navigate = useNavigate();
    const theme = useTheme();
    const [currentImage, setCurrentImage] = React.useState(0)

    return (
        <>

            {props?.mediaType === 'document' ? (
                <div style={{ backgroundColor: theme.palette.bg.chatInput, }} className='w-full   lg:w-[35%] flex flex-col  py-3 items-start justify-between h-[45vh] fixed bottom-16 rounded left-0 lg:left-[27%]'>
                    {/* <iframe className='!overflow-hidden' scrolling="no" src="https://www.africau.edu/images/default/sample.pdf#page=1" frameborder="0"></iframe> */}
                    <div onClick={props.closeImages} className='absolute cursor-pointer top-3 right-4'>
                        <CrossIcon />
                    </div>
                    <div className='w-full flex flc items-center justify-center'>
                        <div className='w-40 h-48 flex flex-col gap-2 items-center justify-center cursor-pointer relative rounded  shrink-0'>
                            <div
                                onClick={() => {
                                    props.onRemove(currentImage)
                                    setCurrentImage(0)
                                }}
                                className='absolute top-[10%] right-[4%] bg-[#424242] py-2 px-2 rounded'
                            >
                                <DeleteIcon />
                            </div>
                            <div className='w-28 flex items-center justify-center h-28 rounded bg-[#98D2C5]'>
                                <img className='w-full rounded h-1/2 object-contain' src={FileImg} alt="FileImg" />
                            </div>
                            {console.log(props?.images[currentImage])}
                            <h6 className='text-sm whitespace-nowrap lg:text-sm font-medium tracking-wider'>{props?.images[currentImage]?.name}</h6>
                        </div>
                    </div>
                    <div className='w-full px-6'>
                        <div className='w-full flex items-center px-3 py-2 gap-3 overflow-x-auto'>
                            <div className='max-w-4/5 flex items-center px-3 py-2 gap-3 overflow-x-auto'>
                                {props.images?.map((image, index) => (
                                    <div
                                        onClick={() => setCurrentImage(index)}
                                        key={index}
                                        className={`w-20 cursor-pointer p-1 h-20 rounded shrink-0 overflow-hidden${index === currentImage ? ' border border-[#FFFFFF]' : ''}`}
                                    >
                                        <div className='w-full flex items-center justify-center h-full rounded bg-[#98D2C5]'>
                                            <img className='w-full rounded h-1/2 object-contain' src={FileImg} alt="FileImg" />
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div style={{ borderColor: theme.palette.headings.secondary }} onClick={() => props.onadd('documents')} className='p-2 border cursor-pointer rounded'>
                                <AddIcon sx={{ color: theme.palette.headings.secondary }} />
                            </div>

                            {props?.isUploading ?
                                <div className='cursor-pointer  absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                                    <Box sx={{ display: 'flex' }}>
                                        <CircularProgress sx={{ color: theme.palette.headings.main }} />
                                    </Box>
                                </div>
                                :
                                <div onClick={() => props.onImageSend()} className='cursor-pointer'>

                                    <SendFileIcon color={theme.palette.headings.secondary} />
                                </div>
                            }

                        </div>
                    </div>
                </div>
            ) : props?.mediaType === 'audio' ? (
                <h1>audions</h1>
            ) : (
                props?.imageType === 'camera' ? (
                    <div style={{ backgroundColor: theme.palette.bg.chatInput }} className='w-full lg:w-[35%]  flex flex-col  py-5 items-start justify-between h-[50vh] fixed bottom-16 rounded left-0  lg:left-[27%]'>
                        <div onClick={props.closeImages} className='absolute cursor-pointer top-3 right-4'>
                            <CrossIcon />
                        </div>
                        <div className='w-full flex items-center justify-center'>
                            <div className='w-40 h-48  cursor-pointer relative rounded  shrink-0'>
                                <div
                                    onClick={() => {
                                        props.onRemove(currentImage)
                                        setCurrentImage(0)
                                    }}
                                    className='absolute top-[-3%] right-[-3%] bg-[#424242] py-2 px-2 rounded'
                                >
                                    <DeleteIcon />
                                </div>
                                <img className='w-full rounded h-full object-cover' src={URL.createObjectURL(props?.images[currentImage])} alt="#" />
                            </div>
                        </div>
                        <div className='w-full px-6 py-4'>
                            <div className='w-full flex items-center px-3 py-2 gap-3 overflow-x-auto'>
                                <div className='max-w-4/5 flex items-center px-3 py-2 gap-3 overflow-x-auto'>
                                    {props.images?.map((image, index) => (
                                        <div
                                            key={index}
                                            onClick={() => setCurrentImage(index)}
                                            className={`w-24 cursor-pointer h-24 rounded shrink-0 p-1 overflow-hidden${index === currentImage ? ' border border-[#ffff]' : ''}`}
                                        >
                                            <img className='w-full h-full object-cover' src={URL.createObjectURL(image)} alt={`Image ${index}`} />
                                        </div>
                                    ))}
                                </div>

                                <div style={{ borderColor: theme.palette.headings.secondary }} onClick={() => props.onOpenCamera()} className='p-2 border cursor-pointer rounded'>
                                    <AddIcon sx={{ color: theme.palette.headings.secondary }} />
                                </div>

                                {props?.isUploading ?
                                    <div className='cursor-pointer  absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                                        <Box sx={{ display: 'flex' }}>
                                            <CircularProgress sx={{ color: theme.palette.headings.main }} />
                                        </Box>
                                    </div>
                                    :
                                    <div onClick={() => props.onImageSend()} className='cursor-pointer'>

                                        <SendFileIcon color={theme.palette.headings.secondary} />
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                ) : (
                    <div style={{ backgroundColor: theme.palette.bg.chatInput, }} className='w-full lg:w-[35%] flex flex-col  py-3 items-start justify-between h-[45vh] fixed bottom-16 rounded left-0 lg:left-[27%]'>
                        {/* <iframe className='!overflow-hidden' scrolling="no" src="https://www.africau.edu/images/default/sample.pdf#page=1" frameborder="0"></iframe> */}

                        <div onClick={props.closeImages} className='absolute cursor-pointer top-3 right-4'>
                            <CrossIcon />
                        </div>
                        <div className='w-full flex items-center justify-center'>
                            <div className='w-40 h-48 cursor-pointer relative rounded  shrink-0'>
                                <div
                                    onClick={() => {
                                        props.onRemove(currentImage)
                                        setCurrentImage(0)
                                    }}
                                    className='absolute top-[-3%] right-[-3%] bg-[#424242] py-2 px-2 rounded'
                                >
                                    <DeleteIcon />
                                </div>
                                <img className='w-full rounded h-full object-cover' src={URL.createObjectURL(props?.images[currentImage])} alt="#" />
                            </div>
                        </div>
                        <div className='w-full px-6'>
                            <div className='w-full flex items-center px-3 py-2 gap-3 '>

                                <div className='max-w-4/5 flex items-center px-3 py-2 gap-3 overflow-x-auto'>
                                    {props.images?.map((image, index) => (
                                        <div
                                            onClick={() => setCurrentImage(index)}
                                            key={index}
                                            className={`w-24 cursor-pointer p-1 h-24 rounded shrink-0 overflow-hidden${index === currentImage ? ' border border-[#FFFFFF]' : ''}`}
                                        >
                                            <img className='w-full h-full object-cover' src={URL.createObjectURL(image)} alt={`Image ${index}`} />
                                        </div>
                                    ))}
                                </div>
                                <div style={{ borderColor: theme.palette.headings.secondary }} onClick={() => props.onadd()} className='p-2 border cursor-pointer rounded'>
                                    <AddIcon sx={{ color: theme.palette.headings.secondary }} />
                                </div>
                                {props?.isUploading ?
                                    <div className='cursor-pointer  absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                                        <Box sx={{ display: 'flex' }}>
                                            <CircularProgress sx={{ color: theme.palette.headings.main }} />
                                        </Box>
                                    </div>
                                    :
                                    <div onClick={() => props.onImageSend()} className='cursor-pointer'>

                                        <SendFileIcon color={theme.palette.headings.secondary} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                )
            )}

        </>
    )
}

export default SelectImages
