import { Button, Typography } from '@mui/material'
import React from 'react'
import Search from '../../Components/Search'
import FilterDrawer from '../../Components/Filterdrawer'
import { CheckBox } from '@mui/icons-material'
import file from '../../Assets/Images/file.png'
import videothumbnail from '../../Assets/Images/Link.png'
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { get, post } from '../../Services/api-services'
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import empty from '../../Assets/Images/emptychat.png'
import DoubtFilter from './DoubtFilter'
import DoubtList from '../../Components/DoubtList'
import DoubtChat from '../../Components/DoubtChat'
import MainScreen from '../../Student/StudentScreens/MainScreen'

export default function Doubt() {
 
 
  return (
    <div className='flex-1  flex overflow-hidden gap-2 flex-col   lg:flex-row  ' >
        <MainScreen />
    </div>
  )
}
