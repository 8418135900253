import { combineReducers, configureStore } from '@reduxjs/toolkit'
import reducer from './Reducers/Reducer'

// Store.js
const rootReducer = combineReducers({
    homeOption: reducer,
})

export const store = configureStore({
    reducer: rootReducer,
})
