
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { RecoilRoot, useRecoilState } from 'recoil';
import MainRoutes from './Routes/MainRoutes';
import { useSelector } from 'react-redux';
import { material } from './Themes/Theme';
import { ThemeProvider } from '@emotion/react';
import { lightTheme, darkTheme } from './Themes/Theme';
import StudentRoutes from './Routes/StudentRoutes';
import { currentTheme } from '../src/Services/RecoilService'
import ThemeComponent from './Student/StudentComponents/Theme';
import { createContext, useContext, useState } from 'react';
// import SocketProvider from './Student/StudentComponents/socketContext/Index';

export const CurrentDoubtIdContext = createContext();

function App() {
  const [currentDoubtId, setCurrectDoubtId] = useState('')
  // const { currentTheme } = useSelector((state) => state.homeOption)

  return (
    <>
      <CurrentDoubtIdContext.Provider value={{ currentDoubtId, setCurrectDoubtId }}>
        <RecoilRoot >
          {/* <SocketProvider> */}
          <ThemeComponent />
          {/* </SocketProvider> */}
        </RecoilRoot>
      </CurrentDoubtIdContext.Provider>

    </>

  );
}

export default App;
